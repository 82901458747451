import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageDialog from './PageDialog';
import ObjectRequestForm from '../Forms/ObjectRequestForm';

import {
  setSiteIndexObjectRequest,
  setPageDialogOpenObjectRequest,
  setResetFieldsObjectRequest
} from '../../reducers/objectRequest';
import { setResetFormValidator } from '../../reducers/formValidators';

import {
  OBJECTREQUESTFORM_SITEINDEX_START,
  OBJECTREQUESTFORM_SITEINDEX_WIDERRUF,
  OBJECTREQUESTFORM_SITEINDEX_SUCCESS
} from '../../constants';

function PageDialogObjectRequestForm() {
  const objectRequest = useSelector((state) => state.objectRequest);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleCloseObjectRequestForm = () => {
    dispatch(setPageDialogOpenObjectRequest(false));
    dispatch(setResetFieldsObjectRequest());
    dispatch(setResetFormValidator());
  };

  const handleBackObjectRequestForm = () => {
    dispatch(setSiteIndexObjectRequest(OBJECTREQUESTFORM_SITEINDEX_START));
  };

  useEffect(() => {
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash === OBJECTREQUESTFORM_SITEINDEX_START) {
          dispatch(setSiteIndexObjectRequest(OBJECTREQUESTFORM_SITEINDEX_START));
          dispatch(setPageDialogOpenObjectRequest(true));
        }
      }
    }
  }, []);

  return (
    <PageDialog
      open={objectRequest.pageDialogOpen}
      handleClose={handleCloseObjectRequestForm}
      handleBack={handleBackObjectRequestForm}
      headline={objectRequest.headline}
      showBackArrow={
        objectRequest.siteIndex === OBJECTREQUESTFORM_SITEINDEX_WIDERRUF ? true : false
      }
    >
      <ObjectRequestForm handleClose={handleCloseObjectRequestForm} />
    </PageDialog>
  );
}

export default PageDialogObjectRequestForm;
