import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import marktBerichtImage from '../../assets/images/Mockup-Marktbericht-HQ.png';
import Card from './Card';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import '../../styles/wohnwert.scss';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';
import { useState } from 'react';
import MarktBerichtForm from '../Forms/MarktberichtForm';
import { getMarketReportList } from '../../api/JustImmo';
import moment from 'moment';
import { downloadDocument, downloadMarktberichtUrl } from '../../api/Documents';
import { setPreviousMarktBericht } from '../../reducers/marktBericht';

function MarktberichtCard({ cssClasses }) {
  const dispatch = useDispatch();
  let marktberichtStore = useSelector((state) => state.marktBericht);
  let user = useSelector((state) => state.user);
  const { isMobile } = useHandleScreenResize();
  let [lastMarktbericht, setLastMarktBericht] = useState(null);

  if (!cssClasses) cssClasses = 'marktbericht-card';
  else cssClasses += ' marktbericht-card';

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  useEffect(() => {
    if (marktberichtStore.previousMarktBericht) {
      setLastMarktBericht(marktberichtStore.previousMarktBericht);
    }
  }, [marktberichtStore.previousMarktBericht]);

  useEffect(() => {
    if (user.isLoggedIn) {
      getMarketReports();
    }

    async function getMarketReports() {
      const response = await getMarketReportList();
      if (response && response.list?.length > 0) {
        setLastMarktBericht(response.list[0]);
        if (marktberichtStore.previousMarktBericht?.fileId !== response.list[0].fileId) {
          dispatch(setPreviousMarktBericht(response.list[0]));
        }
      }
    }
  }, [user]);

  return (
    <>
      {!user.isLoggedIn ? (
        <Card cssClasses={cssClasses} cardStyle={'customContent'}>
          <div className="card__content text-center">
            <h2 className="mb-100rem text-white">Kostenloser Marktbericht für Ihre Immobilie</h2>
          </div>
          <div className="marktbericht-card__content">
            <div className="marktbericht-card__text">
              <p className="text-white">Erhalten Sie wichtige Infos zur Lage Ihrer Immobilie: Daten zu Infrastruktur, Erreichbarkeit, Lärmbelastung, Bevölkerungsentwicklung und mehr.</p>
              <p className="text-white">Ideal für einen möglichen Verkauf Ihrer Immobilie.</p>
              <ul className="list--withcheckmark mt-100rem text-white">
                <li>Adresse eingeben</li>
                <li>Bericht anfordern</li>
                <li>Direkt im Dashboard erhalten</li>
              </ul>
              <p className="text-bold text-white pt-100rem">Jetzt registrieren und Marktbericht anfordern.</p>
              <ButtonForwardAction buttonText="Jetzt registrieren" forwardAction={handleOpenRegisterForm} buttonStyle="white" />
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </Card>
      ) : (lastMarktbericht && !lastMarktbericht.fileId) || !lastMarktbericht ? (
        <Card cssClasses={cssClasses} cardStyle={'customContent'}>
          <div className="card__content text-center">
            <h2 className="mb-100rem text-white">Marktbericht</h2>
          </div>
          <div className="marktbericht-card__content">
            <div className="marktbericht-card__text">
              <MarktBerichtForm />
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </Card>
      ) : lastMarktbericht && lastMarktbericht.fileId ? (
        <Card cssClasses={cssClasses} cardStyle={'customContent'}>
          <div className="card__content text-center">
            <h2 className="mb-100rem text-white">Marktbericht</h2>
          </div>
          <div className="marktbericht-card__content">
            {/*
              Style tag muss wieder weg, wenn der untere Teil wieder einkommentiert ist.
            */}
            <div className="marktbericht-card__text" style={{ transform: 'translateY(60%)' }}>
              <p className="text-white">
                Ihr Marktbericht für die Adresse {lastMarktbericht.location?.street} {lastMarktbericht.location?.number}, {lastMarktbericht.location?.zip} {lastMarktbericht.location?.city},
                angefordert am {moment(lastMarktbericht.submitted).format('DD.MM.YYYY')}, ist hier abrufbar:
              </p>
              <h4>
                <a className="textlink text-white text-underline" href={downloadMarktberichtUrl(lastMarktbericht.fileId)} target="_blank" rel="noreferrer">
                  Marktbericht herunterladen
                </a>
              </h4>
              {/**
                 * <p className="text-white text-bold">Als Ergänzung zu Ihrem Marktbericht:</p>
              <p className="text-white">
                die <span className="text-bold">gratis Wohnwert Pro Berechnung.</span> Holen Sie sich die professionelle Verkehrswertschätzung zum Wert Ihrer Immobilie - unverbindlich und kostenlos
              </p>
              TODO Button für Wohnwert Pro Berechnung einbinden und Formular abschicken
                 */}
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </Card>
      ) : null}
    </>
  );
}

export default MarktberichtCard;
