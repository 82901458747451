import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parseCurrency, { parseCurrencyWithDecimals } from '../../util/currencyParser';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';

import { parseEBSPK_Institute } from '../../util/csvParser';
import FormInput from '../FormFields/FormInput';
import FormSelect from '../FormFields/FormSelect';

import { setFormIsLoading } from '../../reducers/app';
import '../../styles/finanzrechner.scss';
import { fetchErrorText } from '../FormFields/util/formTexts';
import { validateFields } from '../FormFields/util/validateFields';
import LoadingSpinner from '../LoadingSpinner';

import { FINANZRECHNER_SITEINDEX_FORM, FINANZRECHNER_SITEINDEX_RESULTS } from '../../constants';
import { setResultingRateFinanzForm, setSiteIndexFinanzForm } from '../../reducers/finanzierungRechner';

import { calculateDarlehen } from '../../api/PriceHubble';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function FinanzierungsRechnerForm() {
  let currentFinanzierungsRechner = useSelector((state) => state.finanzierungsRechner);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const [error, setError] = useState('');
  const [calculatedRate, setCalculatedRate] = useState(0);
  const [EBSPK_Institute_Data, setEBSPK_Institute_Data] = useState(null);
  const { isMobile } = useHandleScreenResize();
  const dispatch = useDispatch();

  useEffect(() => {
    const setData = async () => {
      setEBSPK_Institute_Data(await parseEBSPK_Institute(true));
    };
    setData();
  }, []);

  const forwardAction = async () => {
    setError('');

    let validationResult = validateFields(
      {
        federalState: currentFinanzierungsRechner.federalState.id,
        institute: currentFinanzierungsRechner.district.id,
        loanValue: currentFinanzierungsRechner.loanValue,
        loanTime: currentFinanzierungsRechner.loanTime,
      },
      dispatch,
    );

    if (validationResult) {
      dispatch(setFormIsLoading(true));
      try {
        const calculatedDarlehen = await calculateDarlehen(currentFinanzierungsRechner.loanValue, currentFinanzierungsRechner.loanTime);
        dispatch(setFormIsLoading(false));
        const parsed = JSON.parse(calculatedDarlehen);
        setCalculatedRate(parsed.Envelope.Body.CalcDarlehenResponse.CalcDarlehenResult.Rate);
        dispatch(setResultingRateFinanzForm(parsed.Envelope.Body.CalcDarlehenResponse.CalcDarlehenResult.Rate));
        dispatch(setSiteIndexFinanzForm(FINANZRECHNER_SITEINDEX_RESULTS));
      } catch (e) {
        console.error('e', e);
        setError(fetchErrorText);
        dispatch(setFormIsLoading(false));
      }
    }
  };

  const goBackAction = () => {
    dispatch(setSiteIndexFinanzForm(FINANZRECHNER_SITEINDEX_FORM));
  };

  const formType = 'finanzierungsCalc';

  return (
    <>
      <div className="finanzrechner-form">
        {currentFinanzierungsRechner.siteIndex === FINANZRECHNER_SITEINDEX_FORM ? (
          <>
            <div className="finanzrechner-form__content">
              {EBSPK_Institute_Data && (
                <div className="form__row form__row--double form__row--double--mobilefull">
                  <FormSelect
                    label={'Bundesland'}
                    placeholder="Bitte wählen"
                    value={currentFinanzierungsRechner.federalState.id >= 0 ? currentFinanzierungsRechner.federalState : ''}
                    formType={formType}
                    id={'inputFederalState'}
                    error={currentFormValidators.federalStateError}
                    options={Object.entries(EBSPK_Institute_Data).map(([key, value], index) => {
                      return {
                        id: index,
                        text: key,
                        content: value,
                      };
                    })}
                  />
                  <FormSelect
                    label={'Institut'}
                    placeholder={currentFinanzierungsRechner.federalState.id > -1 ? 'Bitte wählen' : 'Bitte erst Bundesland auswählen'}
                    disabled={currentFinanzierungsRechner.federalState.id > -1 ? false : true}
                    value={currentFinanzierungsRechner.district.id > -1 ? currentFinanzierungsRechner.district : ''}
                    formType={formType}
                    id={'inputDistrict'}
                    error={currentFormValidators.districtError}
                    options={
                      currentFinanzierungsRechner.federalState.content
                        ? currentFinanzierungsRechner.federalState.content.map((item, index) => {
                            return {
                              id: index,
                              text: item.institute,
                              url1: item.url1 ? item.url1 : '',
                              url2: item.url2 ? item.url2 : '',
                            };
                          })
                        : []
                    }
                  />
                </div>
              )}
              <FormInput
                hasSlider={true}
                minValue={5000}
                maxValue={980000}
                stepValue={1000}
                error={currentFormValidators.loanValueError}
                value={currentFinanzierungsRechner.inputLoanValue}
                sliderLabels={['5.000 EUR', '980.000 EUR']}
                sliderValue={currentFinanzierungsRechner.loanValue}
                showFurtherInfoText={'currency'}
                formType="finanzierungsRechner"
                type={'text'}
                id="inputLoanValue"
                label={'Finanzierungsbetrag'}
                cssClasses={'mt-100rem'}
              />
              <FormInput
                hasSlider={true}
                minValue={10}
                maxValue={35}
                sliderLabels={['10 Jahre', '35 Jahre']}
                hasMarks={true}
                stepValue={5}
                error={currentFormValidators.loanTimeError}
                showFurtherInfoText={'years'}
                value={currentFinanzierungsRechner.inputLoanTime}
                sliderValue={currentFinanzierungsRechner.loanTime}
                formType="finanzierungsRechner"
                type={'number'}
                id="inputLoanTime"
                label={'Laufzeit'}
              />
              <LoadingSpinner textColor="#1e3163" iconColor="#1e3163" />

              <div className="button-panel">
                <ButtonForwardAction
                  cssClasses={isMobile && 'w-100'}
                  formType="finanzierungsRechner"
                  buttonText="Finanzierung berechnen"
                  forwardAction={forwardAction}
                  buttonStyle={!isMobile ? 'blue' : 'orange'}
                />
              </div>
            </div>

            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : currentFinanzierungsRechner.siteIndex === FINANZRECHNER_SITEINDEX_RESULTS ? (
          <>
            <div className="finanzrechner-form__content mb-100rem">
              <h2 className={`mb-5 ${isMobile ? 'font-130' : 'font-140'}`}>Ihre Berechnungsbasis</h2>
              <span className="textlink text-nodecoration text-blue font-weight-600 hover-underline" onClick={goBackAction}>
                Angaben bearbeiten
              </span>
              <div className="finanzrechner-form__content__results mt-200rem mb-100rem">
                <div className="d-flex">
                  <p className="mb-5 font-weight-600">Monatliche Rate</p>
                  <h2 className="no-break font-140">{currentFinanzierungsRechner.resultingRate ? parseCurrency(currentFinanzierungsRechner.resultingRate) : parseCurrency(calculatedRate)}</h2>
                </div>
                {isMobile ? (
                  <>
                    <p className="mb-5 font-weight-600">Infos zur Finanzierung</p>
                    <p className="font-weight-600 font-130">
                      {parseCurrency(parseFloat(currentFinanzierungsRechner.loanValue))}, {currentFinanzierungsRechner.loanTime} Jahre,{' '}
                      {currentFinanzierungsRechner.federalState.text ? `${currentFinanzierungsRechner.federalState.text}` : ''}
                      {currentFinanzierungsRechner.district.text ? `, ${currentFinanzierungsRechner.district.text}` : ''}
                    </p>
                  </>
                ) : (
                  <div className="w-60">
                    <p className="mb-5 font-weight-600">Infos zur Finanzierung</p>
                    <h2 className="mb-5 font-140">Summe: {parseCurrency(parseFloat(currentFinanzierungsRechner.loanValue))}</h2>
                    <h2 className="mb-5 font-140">Laufzeit: {currentFinanzierungsRechner.loanTime} Jahre</h2>
                    <h2 className="mb-5 font-140">
                      Institut: {currentFinanzierungsRechner.federalState.text ? `${currentFinanzierungsRechner.federalState.text}` : ''}
                      {currentFinanzierungsRechner.district.text ? `, ${currentFinanzierungsRechner.district.text}` : ''}
                    </h2>
                  </div>
                )}
              </div>
            </div>
            <p className={isMobile ? 'mt-300rem' : 'mt-10rem'}>
              Die Finanzierungsexpert:innen der Erste Bank und Sparkassen beraten Sie gern unverbindlich zu einer Finanzierung. Oder Sie holen sich eine Online-Finanzierungszusage.
            </p>
            <div className="button-panel">
              {currentFinanzierungsRechner.district.url1 && currentFinanzierungsRechner.district.url1 !== '' && (
                <a href={currentFinanzierungsRechner.district.url1} className="button button--orange text-nodecoration text-center" target="_blank" rel="noopener noreferrer">
                  Beratungstermin anfragen
                </a>
              )}
              {currentFinanzierungsRechner.district.url2 && currentFinanzierungsRechner.district.url2 !== '' && (
                <a href={currentFinanzierungsRechner.district.url2} className="button button--blue button-desktop--white-outline text-nodecoration" target="_blank" rel="noopener noreferrer">
                  Finanzierungszusage holen
                </a>
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default FinanzierungsRechnerForm;
