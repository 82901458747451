import React from 'react';
import '../styles/infomark.scss';

const InfoMark = ({ handleClick, character, color }) => {
  if (!character) character = '?';
  if (!color) color = '#3679ec'; // erste-blue

  return (
    <div
      className="info-mark"
      style={{ backgroundColor: color }}
      onClick={() => {
        handleClick();
      }}
    >
      <span>{character}</span>
    </div>
  );
};

export default InfoMark;
