import React from 'react';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import '../../styles/iconlist.scss';

function IconListElement({
  cssClasses,
  children,
  icon,
  iconColor,
  whiteCircle,
  blueCircleDesktop,
  blueCircleBoth,
}) {
  cssClasses += ' iconlist-element';
  if (!iconColor) {
    iconColor = '#1E3163'; // $erste-darkblue
  }

  const { isMobile } = useHandleScreenResize();
  
  if (cssClasses.includes('iconlist-element--highlightblue') || blueCircleDesktop || blueCircleBoth) {
    // set icon color to white on mobile
    if (blueCircleBoth) {
      iconColor = '#fff';
    }
    
    if (isMobile || blueCircleDesktop) {
      iconColor = '#fff';
    }
  }

  let clonedIcon = React.cloneElement(icon, { color: iconColor });

  return (
    <div className={cssClasses}>
      {blueCircleDesktop ? (
        <div className="image--round image--blue">{clonedIcon}</div>
      ) : whiteCircle ? (
        <div className="p-10">{clonedIcon}</div>
      ) : blueCircleBoth ? (
        <div className={!isMobile? 'image--round image--blue' : 'image--round image--blue--mobile'}>
          {clonedIcon}
        </div>
      ) : (
        <div className="image--round">{clonedIcon}</div>
      )}

      <div className="iconlist-element__content">{children}</div>
    </div>
  );
}

export default IconListElement;
