import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FormSelect from '../FormFields/FormSelect';
import FormCheckbox from '../FormFields/FormCheckbox';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

import { getKaufpreis, getKaufpreisNum, getOpenSpaceSize } from '../../util/immoObjectParser';
import '../../styles/immosubunits.scss';

function ImmoWohneinheiten({ wohneinheiten, isSearch, immo }) {
  const app = useSelector((state) => state.app);

  const [areaVal, setAreaVal] = useState('');
  const [priceVal, setPriceVal] = useState('');
  const [roomsVal, setRoomsVal] = useState('');
  const [etageVal, setEtageVal] = useState('');

  const [openSpace, setOpenSpace] = useState(false);

  const { isMobile } = useHandleScreenResize();

  const wohnflaecheOptions = [
    { text: 'ab 0m²', id: 0 },
    { text: 'ab 40m²', id: 40 },
    { text: 'ab 50m²', id: 50 },
    { text: 'ab 60m²', id: 60 },
    { text: 'ab 80m²', id: 80 },
    { text: 'ab 100m²', id: 100 },
    { text: 'ab 120m²', id: 120 },
  ];

  const preisOptions = [
    { text: 'bis € 100.000,-', id: 100000 },
    { text: 'bis € 200.000,-', id: 200000 },
    { text: 'bis € 300.000,-', id: 300000 },
    { text: 'bis € 500.000,-', id: 500000 },
    { text: 'bis € 700.000,-', id: 700000 },
    { text: 'bis € 1.000.000,-', id: 1000000 },
    { text: 'bis € 2.000.000,-', id: 2000000 },
  ];

  const zimmerOptions = [
    { text: '1 Zimmer', id: 1 },
    { text: '2 Zimmer', id: 2 },
    { text: '3 Zimmer', id: 3 },
    { text: '4 Zimmer', id: 4 },
    { text: '5 Zimmer', id: 5 },
  ];

  const etageOptions = [
    { text: 'Erdgeschoß', id: 0 },
    { text: 'ab 1. OG', id: 1 },
    { text: 'bis 1. OG', id: -1 },
    { text: 'ab 3. OG', id: 3 },
    { text: 'bis 3. OG', id: -3 },
    { text: 'ab 6. OG', id: 6 },
    { text: 'Dachgeschoß', id: 999 },
  ];

  const filterSubunits = (val, key) => {
    switch (key) {
      case 'area':
        setAreaVal(val);
        break;
      case 'price':
        setPriceVal(val);
        break;
      case 'rooms':
        setRoomsVal(val);
        break;
      case 'etage':
        setEtageVal(val);
        break;
      case 'openSpace':
        setOpenSpace(val);
        break;
      default:
        break;
    }
  };

  function parseEtage(etageString) {
    if (etageString.toLowerCase().includes('dg') || etageString.toLowerCase().includes('dachgeschoß')) {
      return 999; // Dachgeschoß hat den Wert 999
    }
    const match = etageString.match(/(\d+)/);
    return match ? parseInt(match[0]) : 0; // 0 für Erdgeschoß
  }
	
  // Filtered subunits
  const filteredWohneinheiten = useMemo(() => {
    if (!isSearch) {
      return wohneinheiten.filter((immo) => {
        const immoLivingArea = parseInt(immo.areas.living_area);
        const areaMatch = areaVal ? immoLivingArea >= areaVal.id : true;

				const immoPrice = getKaufpreisNum(immo);
        const priceMatch = priceVal ? immoPrice <= priceVal.id : true;

        const roomsMatch = roomsVal ? immo.rooms >= roomsVal.id : true;

        const parsedEtage = parseEtage(immo.address.floor);
        const etageMatch = etageVal
          ? etageVal.id === 0
            ? parsedEtage === 0
            : etageVal.id > 0
            ? parsedEtage >= etageVal.id
            : etageVal.id < 0
            ? parsedEtage <= Math.abs(etageVal.id)
            : etageVal.id === 999
            ? parsedEtage === 999
            : true
          : true;

        // const immoOpenSpace = getWohneinheitOpenSpaceSize(immo);
				const immoOpenSpace = getOpenSpaceSize(immo);
        const openSpaceMatch = openSpace ? immoOpenSpace : true;

        return areaMatch && priceMatch && roomsMatch && etageMatch && openSpaceMatch;
      });
    } else {
      return wohneinheiten;
    }
  }, [wohneinheiten, areaVal, priceVal, roomsVal, etageVal, openSpace]);

  const formType = 'subunit';
  return (
    <div className="subunits" onClick={(e) => e.stopPropagation()}>
      {!isSearch && (
        <div className="subunits-filter">
          <h3>Filter</h3>
          <FormSelect label={''} placeholder={'Wohnfläche ab'} formType={formType} value={areaVal} options={wohnflaecheOptions} callback={(val) => filterSubunits(val, 'area')} />
          <FormSelect label={''} placeholder={'Preis bis'} formType={formType} value={priceVal} options={preisOptions} callback={(val) => filterSubunits(val, 'price')} />
          <FormSelect label={''} placeholder={'Zimmeranzahl'} formType={formType} value={roomsVal} options={zimmerOptions} callback={(val) => filterSubunits(val, 'rooms')} />
          <FormSelect label={''} placeholder={'Etage'} formType={formType} value={etageVal} options={etageOptions} callback={(val) => filterSubunits(val, 'etage')} />
          <FormCheckbox
            label={'Freifläche'}
            id="openSpace"
            formType={formType}
            value={openSpace}
            callback={(val) => filterSubunits(val, 'openSpace')}
            labelFirst={true}
            cssClasses="form__field--checkbox--bordered form__field--checkbox-filter"
          />
        </div>
      )}

      {isMobile ? (
        <table className="subunits__immos">
          <tbody>
            {filteredWohneinheiten.length > 0 ? (
              <>
                {filteredWohneinheiten.map((immo, index) => (
                  <>
                    <tr key={index + '-mobile'}>
                      <td>
                        <div className="subunits__immos-toprow">
													{immo.preview_plan?.storage_key ? (
														<img src={`https://storage.justimmo.at/file/${immo.preview_plan.storage_key}`} alt={`${immo.title}`} />
													) : immo.preview_image?.storage_key ? (
														<img src={`https://storage.justimmo.at/file/${immo.preview_image.storage_key}`} alt={`${immo.title}`} />
													) : null }

                          <p>{immo.address.door && immo.address.floor ? `Top ${immo.address.door} / ${immo.address.floor}` : '-'}</p>
                          <p>
                            {immo.areas?.living_area ? parseInt(immo.areas?.living_area).toLocaleString('de-DE') : '-'} m², Freifläche:
                            {getOpenSpaceSize(immo) > 0 ? `${getOpenSpaceSize(immo).toLocaleString('de-DE')} m²` : '-'}
                          </p>
                        </div>
                        <p>
                          Zimmeranzahl: {immo.rooms > 0 ? immo.rooms?.toString().replace('.', ',') : '-'}, Preis: {getKaufpreis(immo)}
                        </p>
                        <p className="mt-50rem">
                          <Link to={app.root + '/immobilie/' + immo.url} title={immo.title}>
                            Anzeigen
                          </Link>
                        </p>
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ) : (
              <p>Keine Wohneinheiten gefunden.</p>
            )}
          </tbody>
        </table>
      ) : (
        <table className="subunits__immos">
          <thead>
            <tr>
              <td>Plan</td>
              <td>Einheit</td>
              <td className="text-right">Wohnfläche</td>
              <td className="text-right">Freifläche</td>
              <td className="text-right">Preis</td>
              <td className="text-right">Zimmer</td>
              <td></td>
            </tr>
          </thead>

          <tbody>
            {filteredWohneinheiten.length > 0 ? (
              <>
                {filteredWohneinheiten.map((immo, index) => (
                  <tr key={index}>
                    <td>
											{immo.preview_plan?.storage_key ? (
												<img src={`https://storage.justimmo.at/file/${immo.preview_plan.storage_key}`} alt={`${immo.title}`} />
											) : immo.preview_image?.storage_key ? (
												<img src={`https://storage.justimmo.at/file/${immo.preview_image.storage_key}`} alt={`${immo.title}`} />
											) : null }
                    </td>

                    <td>{immo.address.door && immo.address.floor ? `Top ${immo.address.door} / ${immo.address.floor}` : '-'}</td>
                    <td className="text-right">{immo.areas?.living_area ? `${parseInt(immo.areas?.living_area).toLocaleString('de-DE')} m²` : '-'}</td>
                    <td className="text-right"> {getOpenSpaceSize(immo) > 0 ? `${getOpenSpaceSize(immo).toLocaleString('de-DE')} m²` : '-'}</td>
                    <td className="text-right">{getKaufpreis(immo)}</td>
                    <td className="text-right">{immo.rooms > 0 ? immo.rooms?.toString().replace('.', ',') : '-'}</td>
                    <td>
                      <Link to={app.root + '/immobilie/' + immo.url} title={immo.title}>
                        Anzeigen
                      </Link>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td>Keine Wohneinheiten gefunden.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {isSearch && (
        <Link to={`../immobilie/${immo.url}`} title={immo.title}>
          <p className="subunits__link textlink text-center mt-75rem">Alle Einheiten anzeigen</p>
        </Link>
      )}
    </div>
  );
}

export default ImmoWohneinheiten;
