import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, getSentMessages, putReadMessage } from '../../api/Contact';
import { AKTUELLES_FILTER_NACHRICHTEN, PHASE1_VERKAUF, WELCOME_MESSAGE_SUBJECT } from '../../constants';
import { setUnreadMessages } from '../../reducers/notifications';
import { setHasMessages } from '../../reducers/user';

export const useHandleMessages = () => {
  const notifications = useSelector((state) => state.notifications);
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [threads, setThreads] = useState([]);
  const [threadsLoading, setThreadsLoading] = useState(true);


  let welcomeMessageSentAt = new Date().toISOString();
  if(user.userObject?.created) {
	let created_at = user.userObject.created;
	welcomeMessageSentAt = created_at;
  }


  const welcomeMessage = [
    {
      sentAt: welcomeMessageSentAt,
      isRead: true,
      subject: WELCOME_MESSAGE_SUBJECT,
      body: 'Wir begrüßen Sie in Ihrem my-sreal Dashboard. Jetzt direkt loslegen:\n\nSie möchten eine Immobilie verkaufen? Stellen Sie die Terminanfrage über Ihr Verkäuferdashboard und eine s REAL Makler:in wird sich mit Ihnen in Verbindung setzen, um einen Vermittlungsauftrag mit Ihnen zu besprechen. Nach der Beauftragung wird Ihre Immobilie von uns aufbereitet und in Ihrem Dashboard für Sie freigeschaltet. \n\nSie suchen eine Immobilie? Klicken Sie im Header-Bereich auf [KAUFEN] und Sie kommen ins Sucherdashboard. Dort können Sie Ihren Suchwunsch aufgeben. Alternativ können Sie auch direkt in der Immobiliensuche stöbern. Vielleicht ist schon etwas Passendes für Sie dabei? \n\nIhr my-sreal-Team',
      replyToMessageId: null,
      author: 'sreal',
    }
  ];


  const getAllMessages = async () => {
    let messages = [];
    let token;
    let res = await getMessages();
    token = res.token;
    while (token !== null) {
      let newRes = await getMessages(token);
      messages = [...messages, ...newRes.items];
      token = newRes.nextPageToken;
    }
    return messages;
  };

  const getAllSentMessages = async () => {
    let sentMessages = [];
    let token;
    let res = await getSentMessages();
    token = res.token;
    while (token !== null) {
      let newRes = await getSentMessages(token);
      sentMessages = [...sentMessages, ...newRes.items];
      token = newRes.nextPageToken;
    }
    return sentMessages;
  };

  function showNachrichtenInMenu() {
	const linkItemNachrichten = document.querySelectorAll('[data-id="linkitem--nachrichten"]');
	
	if(linkItemNachrichten) {
	  linkItemNachrichten.forEach((item) => item.classList.remove('is-hidden'));
	  dispatch(setHasMessages(true));
	}
  }

  const loadMessages = async () => {
    setThreadsLoading(true);

    const welcomeMessageWithFullChatList = [{ chatList: welcomeMessage, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: welcomeMessage.sentAt }];
	
    if(app.menuId === PHASE1_VERKAUF) {
      	setThreadsLoading(false);

		// dont show welcomeMessage if directRegistrationCompleted
	  	if(user.userObject?.directRegistrationCompleted === null) {
		  setThreads([...welcomeMessageWithFullChatList]);
		  showNachrichtenInMenu();
		}
    }
    else {
   
      try {
        let [messages, sentMessages] = await Promise.all([getAllMessages(), getAllSentMessages()]);

        // double messages = when same message has been sent (sentMessages) and received (messages)
        // check if unread - call putReadMessage() ist isRead is false
        let doubleMessagesUnread = messages.filter((m) => sentMessages.some((sentM) => sentM.id === m.id && !m.isRead)).filter((t) => t !== undefined);
        setMessagesToRead(doubleMessagesUnread);

        // if same message is in getMessages and in SentMessages - user sent himself this message
        // we only want to display it as sent
        // and have to remove from getMessages.
        messages = messages.filter((m) => !sentMessages.some((sentM) => sentM.id === m.id));

        let allSingleMessages = [];
        allSingleMessages.push(messages.map((m) => ({ ...m, author: 'sreal' })));
        allSingleMessages.push(sentMessages.map((m) => ({ ...m, author: '' })));
        allSingleMessages = allSingleMessages.flat().sort(function (a, b) {
          return new Date(a.sentAt) - new Date(b.sentAt);
        });

        // find start threads (without replyToMessageId)
        let threadWithFullChatList = allSingleMessages
          .flat()
          .filter((sm) => sm.replyToMessageId === null)
          .filter((t) => t !== undefined)
          .map((t) => {
            let chatList = [];
            chatList[0] = t;

            // chain messages chronogically
            allSingleMessages.forEach((m) => {
              let lastChatItem = chatList[chatList.length - 1];
              let response = findResponse(lastChatItem.id, allSingleMessages);
              if (response) {
                let isRead = response.isRead;
                // message from the user are automatically read
                if (response.author !== 'sreal') {
                  isRead = true;
                }

                chatList.push({ ...response, isRead: isRead });
              }
            });

            return chatList;
          })
          .sort(function (a, b) {
            let aLastChatItem = a[a.length - 1];
            let bLastChatItem = b[b.length - 1];

            return new Date(bLastChatItem.sentAt) - new Date(aLastChatItem.sentAt);
          });

        // find remaining messages
        let allChatListIds = threadWithFullChatList.flat().map((c) => c.id);
        let remainingMessages = [];
        if (allSingleMessages.length > allChatListIds.length) {
          remainingMessages = allSingleMessages.filter((m) => !allChatListIds.includes(m.id));

          while (remainingMessages.length > 0) {
            // add to correnct threadLists
            [threadWithFullChatList, remainingMessages] = addRemainingMessageToThreadList(remainingMessages, threadWithFullChatList);
          }
        }

		// dont show welcomeMessage if directRegistrationCompleted
		if(user.userObject?.directRegistrationCompleted === null) {
			threadWithFullChatList.push(welcomeMessage);
		}
		  

        threadWithFullChatList = threadWithFullChatList
          .map((t) => {
            let lastChatItem = t[t.length - 1];
            return { chatList: t, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: lastChatItem.sentAt };
          })
          .sort(function (a, b) {
            return new Date(b.dateFrom) - new Date(a.dateFrom);
          });

        setThreads([...threadWithFullChatList]);
        setThreadsLoading(false);

		if(threadWithFullChatList.length > 0) {
			showNachrichtenInMenu();
		}
      } catch (e) {
        // handle get messages error
        console.log(e);
        setThreadsLoading(false);
        setThreads([...welcomeMessageWithFullChatList]);
		showNachrichtenInMenu();
      }
    }
  };

  // find response to message id
  const findResponse = (id, allSingleMessages) => {
    let responseFound = allSingleMessages.filter((sm) => sm.replyToMessageId !== null).find((sm) => sm.replyToMessageId === id);

    return responseFound;
  };

  // add remaining Messages to correct threadList
  const addRemainingMessageToThreadList = (remainingMessages, threadWithFullChatList) => {
    threadWithFullChatList = threadWithFullChatList.map((chatList) => {
      let chatListNew = chatList;
      let chatListIds = chatListNew.map((c) => {
        return c.id;
      });

      remainingMessages = remainingMessages
        .map((r) => {
          if (chatListIds.includes(r.replyToMessageId)) {
            chatListNew.push(r);
          } else return r;
        })
        .filter((t) => t !== undefined);

      chatListNew.sort(function (a, b) {
        return new Date(a.sentAt) - new Date(b.sentAt);
      });

      return chatListNew;
    });

    return [threadWithFullChatList, remainingMessages];
  };

  /**
   * find unread messages
   * and set to isRead = true
   */
  const setMessagesToRead = async (unreadMessages) => {
    if (unreadMessages?.length === 0) return;
    const currentUnreadMessages = notifications.unreadMessages;

    const unreadPromises = unreadMessages.map((item) => {
      return putReadMessage(item.id);
    });

    try {
      await Promise.all(unreadPromises);
      dispatch(setUnreadMessages(currentUnreadMessages - unreadMessages.length));
    } catch (error) {
      console.log('error', error);
    }
  };

  return { setMessagesToRead, loadMessages, threads, threadsLoading, welcomeMessage };
};
