import React from 'react';

function IconGastronomie({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15832" xmlns="http://www.w3.org/2000/svg" width="25.377" height="27.311" viewBox="0 0 25.377 27.311">
      <g data-name="Gruppe 15833">
        <path
          id="Pfad_10327"
          data-name="Pfad 10327"
          d="M25.376,6.828C25.376,3.072,22.816,0,19.743,0S14.11,3.072,14.11,6.828c0,3.357,2.048,6.145,4.666,6.714v12.8a.967.967,0,1,0,1.934,0v-12.8c2.617-.569,4.666-3.357,4.666-6.714m-5.69,4.893c-2.048,0-3.7-2.162-3.7-4.893s1.65-4.893,3.7-4.893,3.7,2.162,3.7,4.893-1.65,4.893-3.7,4.893M10.754,0a1,1,0,0,0-.967.967V5.86a3.858,3.858,0,0,1-2.9,3.755V.967A1.094,1.094,0,0,0,5.86,0a1,1,0,0,0-.967.967V9.616a3.845,3.845,0,0,1-2.9-3.755V.967A1.049,1.049,0,0,0,.967,0,.959.959,0,0,0,0,.967V5.86a5.815,5.815,0,0,0,4.893,5.747V26.343a1,1,0,0,0,.967.967,1,1,0,0,0,.967-.967V11.607A5.815,5.815,0,0,0,11.721,5.86V.967A1,1,0,0,0,10.754,0"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconGastronomie;
