import Card from './Card';
import IconHouse from '../Icons/IconHouse';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function LinkCardToAlleAngebote({ cssClasses }) {
  const app = useSelector((state) => state.app);

  return (
    <Card cardStyle="topImage" cssClasses={`searchprofil-withoutimmos-card immo-card ${cssClasses}`} icon={<IconHouse color="#fff" />}>
      <h4>Sie wollen eine Übersicht über alle Ihre Angebote?</h4>
      <p>Die Suchprofile und Empfehlungen befinden sich auf der Angebotsseite</p>
      <div>
        <Link to={app.root + '/angebote'} className={'textlink ' + cssClasses} title={'Alle Angebote anzeigen'}>
          Alle Angebote anzeigen
        </Link>
      </div>
    </Card>
  );
}

export default LinkCardToAlleAngebote;
