import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconUnchecked from '../Icons/IconUnchecked';
import IconCheck from '../Icons/IconCheck';
import IconIndeterminate from '../Icons/IconIndeterminate';
import { teaserTextParser } from '../../util/teaserTextParser';

import '../../styles/checklisttable.scss';

function ChecklistTable({ parentLabel, childList, handleCheckItem, handleCheckAllInCategory, id }) {
  return (
    <div className="checklisttable">
      <FormControlLabel
        label={parentLabel}
        control={
          <Checkbox
            checkedIcon={<IconCheck color="#3679ec" />}
            icon={<IconUnchecked color="#e4e4e4" />}
            indeterminateIcon={<IconIndeterminate color="#3679ec" />}
            checked={childList.every((item) => item.checked)}
            indeterminate={!childList.every((item) => item.checked) && childList.some((item) => item.checked)}
            onChange={() => {
              handleCheckAllInCategory(parentLabel);
            }}
          />
        }
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }} id={id}>
        {childList.map((item, index) => (
          <FormControlLabel
            key={index}
            label={item.label}
            value={item.checked}
            control={
              <Checkbox
                checkedIcon={<IconCheck color="#3679ec" />}
                icon={<IconUnchecked color="#e4e4e4" />}
                checked={item.checked}
                onChange={() => {
                  handleCheckItem(parentLabel, index);
                }}
              />
            }
          />
        ))}
      </Box>
    </div>
  );
}

export default ChecklistTable;
