import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNewsDetail, getNewsDetailNew } from '../../api/News';
import { setCachedNews } from '../../reducers/cachedNews';
import { getDefaultNewsObject, newsParser } from '../../util/newsParser';

export const useFetchNewsDetails = () => {
  const [currentNews, setCurrentNews] = useState(getDefaultNewsObject());
  const cachedNews = useSelector((state) => state.cachedNews.cachedNews);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);

  // on load
  useEffect(() => {
    // Check if we are on a news detail page
    if (location.pathname.includes('news') && !location.pathname.endsWith('news')) {
      checkForNewsSlug();
    }
  }, []);

  const checkForNewsSlug = () => {
    let lastPartOfUrl = location.pathname.split('/');
    let newsSlug = lastPartOfUrl[lastPartOfUrl.length - 1];

    if (!newsSlug) {
      window.location = app.root + '/404';
    } else {
      getAndSetCurrentNews(newsSlug);
    }
  };

  const getAndSetCurrentNews = async (slug) => {
    const cachedNewsEntry = cachedNews.find((news) => news.slug === slug);
    // news könnten im cache sein, jedoch noch keine details haben
    // wenn bereits details da sind werden diese sofort angezeigt
    if (cachedNewsEntry?.hasOwnProperty('blocks')) {
      setCurrentNews(newsParser(cachedNewsEntry));
    }

    // holt im Hintergrund trotzdem die details und updated den cache und das angezeigte news element
    try {
      const newsDetails = await getNewsDetailNew(slug);
      setCurrentNews(newsParser(newsDetails));
      findAndUpdateCachedNewsElement(newsDetails);
    } catch (e) {
      window.location = app.root + '/404';
    }
  };

  const findAndUpdateCachedNewsElement = async (newsElement) => {
    // wenn news cache noch ganz leer ist wird das news element einfach hinzugefügt
    if (cachedNews.length === 0) {
      dispatch(setCachedNews([newsElement]));
    }
    // wenn news cache nicht leer ist wird das news element gesucht und ggf. ersetzt
    else {
      const updatedCachedNews = cachedNews.map((cachedNewsElement) => {
        if (cachedNewsElement.slug === newsElement.slug) {
          return newsElement;
        }
        return cachedNewsElement;
      });
      dispatch(setCachedNews(updatedCachedNews));
    }
  };
  const navigateBack = () => {
    navigate(-1);
  };

  return { currentNews, navigateBack, findAndUpdateCachedNewsElement };
};
