import SearchprofileForm from '../Forms/SearchprofileForm';
import PageDialog from './PageDialog';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { setResetFormValidator } from '../../reducers/formValidators';
import {
  setFederalStateSPForm,
  setLivingAreaFromSPForm,
  setLivingAreaToSPForm,
  setPageDialogOpenSPForm,
  setPriceFromSPForm,
  setPriceToSPForm,
  setResetFieldsSPForm,
  setSiteIndexSPForm,
} from '../../reducers/searchProfileForm';

import { SEARCHPROFILE_SITEINDEX_START, SEARCHPROFILE_SITEINDEX_USERDATA } from '../../constants';
import statesWithZips from '../FormFields/util/statesWithZips';

function PageDialogSearchProfileForm() {
  let currentSearchForm = useSelector((state) => state.searchForm);
  let currentSearchProfileForm = useSelector((state) => state.searchProfileForm);
  let app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleBack = () => {
    dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_START));
  };

  const handleClose = () => {
    if (app.formIsLoading) {
      return;
    }

    dispatch(setPageDialogOpenSPForm(false));
    dispatch(setResetFieldsSPForm());
    dispatch(setResetFormValidator());
  };

  useEffect(() => {
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash === SEARCHPROFILE_SITEINDEX_START) {
          setTimeout(() => {
            dispatch(setPageDialogOpenSPForm(true));
            dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_START));
          }, 500);
        }
      }
    }
  }, [location.hash]);

  useEffect(() => {
    if (location.pathname.includes('/immobiliensuche') && currentSearchProfileForm.pageDialogOpen) {
      if (currentSearchForm.requestBody.addressObject[0]?.value?.[0] === 'f') {
        const federalState = statesWithZips.filter((state) => state.label === currentSearchForm.requestBody.addressObject[0]?.value.slice(2));

        dispatch(
          setFederalStateSPForm({
            id: federalState[0].id,
            text: federalState[0].label,
          }),
        );
      }

      if (currentSearchForm.requestBody.areaFrom) {
        dispatch(setLivingAreaFromSPForm(currentSearchForm.requestBody.areaFrom));
      }

      if (currentSearchForm.requestBody.areaTo) {
        dispatch(setLivingAreaToSPForm(currentSearchForm.requestBody.areaTo));
      }

      if (currentSearchForm.requestBody.priceFrom) {
        dispatch(setPriceFromSPForm(currentSearchForm.requestBody.priceFrom));
      }

      if (currentSearchForm.requestBody.priceTo) {
        dispatch(setPriceToSPForm(currentSearchForm.requestBody.priceTo));
      }
    }
  }, [currentSearchProfileForm.pageDialogOpen]);

  const pageDialogId = 'PageDialogSearchProfileForm';

  return (
    <PageDialog
      id={pageDialogId}
      open={currentSearchProfileForm.pageDialogOpen}
      handleClose={handleClose}
      handleBack={handleBack}
      headline={currentSearchProfileForm.headline}
      showBackArrow={currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_USERDATA ? true : false}
    >
      <SearchprofileForm id={pageDialogId} />
    </PageDialog>
  );
}

export default PageDialogSearchProfileForm;
