import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PageDialog from './PageDialog';
import WohnwertLightForm from '../Forms/WohnwertLightForm';

import { setSiteIndexWohnwertForm, setPageDialogOpenWohnwertForm, setResetFieldsWohnwertForm } from '../../reducers/wohnwertLight';

import { WOHNWERTLIGHT_SITEINDEX_FORM, WOHNWERTLIGHT_SITEINDEX_RESULT } from '../../constants';

function PageDialogWohnwertLight({ updateWithWohnwertLightResults }) {
  let currentWohnwertLight = useSelector((state) => state.wohnwertLight);
  const dispatch = useDispatch();
  const location = useLocation();

  const handleCloseWohnwertLight = () => {
    dispatch(setPageDialogOpenWohnwertForm(false));
    dispatch(setResetFieldsWohnwertForm());
  };

  const handleBackWohnwertModal = () => {
    dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_FORM));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash === WOHNWERTLIGHT_SITEINDEX_FORM) {
          dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_FORM));
          dispatch(setPageDialogOpenWohnwertForm(true));
        }
      }
    }
  }, []);

  return (
    <PageDialog
      open={currentWohnwertLight.pageDialogOpen}
      handleClose={handleCloseWohnwertLight}
      handleBack={handleBackWohnwertModal}
      headline="Wohnwert Light ermitteln"
      showBackArrow={currentWohnwertLight.siteIndex === WOHNWERTLIGHT_SITEINDEX_RESULT}
    >
      <WohnwertLightForm updateWithWohnwertLightResults={updateWithWohnwertLightResults} />
    </PageDialog>
  );
}

export default PageDialogWohnwertLight;
