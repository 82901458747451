import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cachedNews: [],
};

export const cachedNewsSlice = createSlice({
  name: 'cachedNews',
  initialState,
  reducers: {
    setCachedNews: (state, action) => {
      state.cachedNews = action.payload;
    },
    setResetFieldsCachedNews: (state) => {
      return initialState;
    },
  },
});

export const { setCachedNews, setResetFieldsCachedNews } = cachedNewsSlice.actions;

export default cachedNewsSlice.reducer;
