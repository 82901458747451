function IconStar({ color, cssClasses, filled, handleClick }) {
  let filledColor = 'none';
  if (!!filled) filledColor = color;

  return (
    <svg className={cssClasses} onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 26">
      <g data-name="Gruppe 7528" transform="translate(1 1)">
        <path data-name="Pfad 6661" d="M12.5.5l3.13,8.87H24.5l-7.3,5.739,3.13,9.391L12.5,18.761,4.674,24.5,7.8,15.109.5,9.37H9.37Z" transform="translate(-0.5 -0.5)" fill={filledColor} stroke={color} strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </svg>
  );
}

export default IconStar;
