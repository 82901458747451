import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import '../styles/filtermenu.scss';

function FilterMenu({ categories, activeIndex, handleClick, cssClasses = '', width, cutside = 'none', hasPaddingRight = false }) {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const filtermenuRef = useRef(null);

  const onMouseDown = (e) => {
    // do nothing if the component content is not scrollable (i.e. on larger screens)
    // if (filtermenuRef.current.offsetWidth < filtermenuRef.current.parentNode.parentNode.offsetWidth) return;

    setIsDown(true);

    const filtermenu = document.querySelector('.filtermenu');

    let offsetLeft = filtermenu.offsetLeft;
    setStartX(e.pageX - offsetLeft);
  };

  const onMouseLeave = (e) => {
    setIsDown(false);
  };

  const onMouseUp = (e) => {
    setIsDown(false);
  };

  const onMouseMove = (e) => {
    if (!isDown) return;
    e.preventDefault();

    const filtermenu = document.querySelector('.filtermenu');
    const x = e.pageX - filtermenu.offsetLeft;
    const walk = x - startX;

    // do not update scrollLeft if the content has been unnecessarily scrolled excessively
    if (scrollLeft - walk < 0 || scrollLeft - walk > filtermenuRef.current.offsetWidth) return;

    setScrollLeft(scrollLeft - walk);
  };

  useEffect(() => {
    filtermenuRef.current.scrollLeft = scrollLeft;
  }, [scrollLeft]);

  return (
    <div className={'filtermenu-wrapper-' + cutside}>
      <div
        className={`filtermenu ${cssClasses}`}
        style={{ width: width }}
        onMouseDown={(e) => onMouseDown(e)}
        onMouseLeave={(e) => onMouseLeave(e)}
        onMouseUp={(e) => onMouseUp(e)}
        onMouseMove={(e) => onMouseMove(e)}
        ref={filtermenuRef}
      >
        {categories.map((cat, index) => {
          let key = index;
          if (cat.key) {
            key = cat.key;
          }

          return (
            <div
              className={`${key.toString() === activeIndex.toString() ? 'filtermenu__item active' : 'filtermenu__item'} ${hasPaddingRight && 'hasPadding'}`}
              onClick={handleClick}
              key={key}
              data-key={key}
              data-index={index}
            >
              {cat.label}
              {cat.count > 0 && (
                <div className="dot-unread dot-unread--withNumber" key={index} data-index={index}>
                  <span key={index} data-index={index}>
                    {cat.count}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FilterMenu;
