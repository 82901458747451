import { useState } from 'react';
import headerImageMobile from '../../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0002_s-REAL-Sujets-2023-Webhero-16zu9-1.14.jpg';
import headerImage from '../../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import Card from '../Cards/Card';
import LineChartNew from '../Charts/LineChartNew';
import InfoDialog from '../Dialogs/InfoDialog';
import PageDialogBesichtigungen from '../Dialogs/PageDialogBesichtigungen';
import Header from '../Header/Header';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SimpleSlider from '../Sliders/SimpleSlider';

function DashboardHeaderStats({ inseratKlicksData, verschickteAngeboteData, besichtigungenData, dataError, cssClasses, children }) {
  const [openBesichtigungen, setOpenBesichtigungen] = useState(false);
  const [infoModalQuellenOpen, setInfoModalQuellenOpen] = useState(false);
  const app = useSelector((state) => state.app);
  const navigate = useNavigate();
  const handleInfoModalQuellen = () => {
    setInfoModalQuellenOpen(!infoModalQuellenOpen);
  };
  if (!cssClasses) cssClasses = 'header--slider header--slider--purplebg header--stats header--stats-2 header--purplebg header--fitcontent';
  else cssClasses += ' header--slider header--slider--purplebg header--stats header--purplebg header--fitcontent';

  const { isMobile } = useHandleScreenResize();

  const sliderSettings = {
    appendDots: (dots) => <ul>{dots}</ul>,
    dots: true,
    arrows: false,
    swipe: true,
    infinite: true,
  };

  return (
    <Header cssClasses={cssClasses}>
      <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
      {isMobile ? (
        <>
          <div className="w-desktop--center p-0 d-flex justify-content-between align-items-center pb-0">
            <h1 className="w-desktop--center font-140 p-0 z-index-1 mb-0">Dashboard</h1>
            <h2 className="z-index-1 font-120 mt-100rem text-white">Gesamtlaufzeit</h2>
          </div>
          <div className="header__content-wrapper statsheader-container statsheader-mobile">
            <Card cardStyle="customContent" cssClasses={`statistic-card`}>
              <LineChartNew chartData={inseratKlicksData} dataError={dataError} dashboardVersion={true} />
              <div className="text-center">
                <button className="button button--glass mt-100rem" onClick={() => navigate(app.root + '/statistik')}>
                  <span>Details ansehen</span>
                </button>
              </div>
            </Card>
          </div>
        </>
      ) : (
        <>
          <div className="w-desktop--center d-flex justify-content-between align-items-center pb-0">
            <h1 className="w-desktop--center z-index-1 mb-0">Verkäuferdashboard</h1>
          </div>

          <div className="statsheader-container  position-relative z-index-1 w-desktop--center">
            <Card cardStyle="customContent" cssClasses={`w-75 statistic-card`}>
              <LineChartNew chartData={inseratKlicksData} dataError={dataError} dashboardVersion={true} />
            </Card>

            <div className="chart-wrapper--vertical desktop gap-100rem">
              <Card cardStyle="customContent" cssClasses={`statistic-card w-100`}>
                <div className="d-flex gap-200rem align-items-center justify-content-between">
                  <div>
                    <h3>Besichtigungen</h3>
                    <button className="button button--glass mt-100rem" onClick={() => navigate(app.root + '/statistik')}>
                      <span>Details ansehen</span>
                    </button>
                  </div>
                  <p className="text-center font-350 text-bold">{besichtigungenData && besichtigungenData.length > 0 ? besichtigungenData.reduce((acc, curr) => acc + curr.value, 0) : 0}</p>{' '}
                </div>
              </Card>
              <Card cardStyle="customContent" cssClasses={`statistic-card w-100`}>
                <div className="d-flex gap-200rem align-items-center justify-content-between">
                  <div>
                    <h3>Verschickte Angebote</h3>
                    <button className="button button--glass mt-100rem" onClick={() => navigate(app.root + '/statistik')}>
                      <span>Details ansehen</span>
                    </button>
                  </div>
                  <p className="text-center font-350 text-bold">
                    {verschickteAngeboteData && verschickteAngeboteData.length > 0 ? verschickteAngeboteData.reduce((acc, curr) => acc + curr.value, 0) : 0}
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </>
      )}
      <PageDialogBesichtigungen open={openBesichtigungen} handleClose={() => setOpenBesichtigungen(false)} />
      <InfoDialog handleClose={handleInfoModalQuellen} open={infoModalQuellenOpen} headline={'Liste aller Quellen'}>
        <p>
          Quellen:
          <br />
          Dolor sit met, metra et consetetur sadipscin invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd
          gubergren, no sea takimata sanctus est.
        </p>

        <p className="font-weight-700 mt-200rem">Die Quellen werden laufend erweitert.</p>
      </InfoDialog>

      {children}
    </Header>
  );
}

export default DashboardHeaderStats;
