function EnergieSkala() {
  return (
    <div className="skala skala--legend d-flex">
      <div className="skala__a immodetail__features-wrapper text-white">A</div>
      <div className="skala__b immodetail__features-wrapper text-white">B</div>
      <div className="skala__c immodetail__features-wrapper text-white">C</div>
      <div className="skala__d immodetail__features-wrapper text-white">D</div>
      <div className="skala__e immodetail__features-wrapper text-white">E</div>
      <div className="skala__f immodetail__features-wrapper text-white">F</div>
      <div className="skala__g immodetail__features-wrapper text-white">G</div>
    </div>
  );
}

export default EnergieSkala;
