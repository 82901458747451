import '../../styles/kennzahlen.scss';
import parseCurrency from '../../util/currencyParser';

const labelText = (label) => {
  switch (label) {
    case 'children_0':
      return 'Keine Kinder';
    case 'children_1':
      return '1 Kind';
    case 'children_2':
      return '2 Kinder';
    case 'children_3_or_more':
      return '3 oder mehr Kinder';
    default:
      break;
  }
};

function KennzahlList({ kennzahl, category, hasButton, handleButtonClick }) {
  // Dates sind -3 Jahre, -1 Jahr, heute und +1 Jahr, deswegen Kennzahlen[2] = heute
  // Nur bei Wertentwicklung
  let comparedValue = null;
  if(category === 'Wertentwicklung') {
    comparedValue = kennzahl[2]?.salePrice;
  }

  return (
    <>
      <h1 className="kennzahl__headline mobile-hidden">{category}</h1>
      {!kennzahl || kennzahl.length === 0 ? (
        <p className="pl-100rem">Leider sind keine Daten vorhanden.</p>
      ) : (
        <>
          <div className="kennzahl__list">
            {kennzahl && kennzahl.length > 0 && (
              <>
                {kennzahl.map((k, index) => {
                  // Percent die für die Vermarktungsdauer verwendet wird
                  let percent = (k.relativeValue * 100).toFixed(2);
                  if (index !== 2) {
                    return (
                      <div key={category + '-' + index}>
                        {category === 'Wertentwicklung' ? (
                          <>
                            {[k].map((data) => {
                              let percent = 0;
                              let priceAlteration = 0;
                              // Für vergangene Jahre andere Rechnung als für zukünftige
                              if (index === 0 || index === 1) {
                                percent = (((comparedValue - data.salePrice) / comparedValue) * 100).toFixed(2);
                                priceAlteration = comparedValue - data.salePrice;
                              } else if (index === 3) {
                                percent = (((data.salePrice - comparedValue) / comparedValue) * 100).toFixed(2);
                                priceAlteration = data.salePrice - comparedValue;
                              }
                              return (
                                <div className="kennzahl__list__item" key={category}>
                                  {index === 0 ? (
                                    <span className="kennzahl__list__itemlabel">letzte 3 Jahre</span>
                                  ) : index === 1 ? (
                                    <span className="kennzahl__list__itemlabel">seit letztem Jahr</span>
                                  ) : index === 3 ? (
                                    <span className="kennzahl__list__itemlabel">nächstes Jahr</span>
                                  ) : null}
                                  <span className="kennzahl__list__itemval">
                                    {percent > 0 ? '+' : '-'} {percent}%
                                  </span>
                                  <span className="kennzahl__list__itemsub">
                                    ({priceAlteration > 0 ? '+ ' : '- '} {parseCurrency(priceAlteration)})
                                  </span>
                                </div>
                              );
                            })}
                          </>
                        ) : category === 'Vermarktungsdauer' ? (
                          <>
                            {[k].map((item, index) => {
                              if (item.type === 'percentile') {
                                return (
                                  <div className="kennzahl__list__item" key={k.value + '-' + index}>
                                    <span className="kennzahl__list__itemval">{k.value} Tage</span>
                                    <span className="kennzahl__list__itemsub">Durchschnitt von Gebäuden mit ähnlicher Wohnfläche</span>
                                  </div>
                                );
                              }
                            })}
                          </>
                        ) : (
                          <div className="kennzahl__list__item" key={category}>
                            <span className="kennzahl__list__itemlabel">{labelText(k.subtype)}</span>
                            <span className="kennzahl__list__itemval">{percent} %</span>
                            <span className="kennzahl__list__itemsub">{k.absoluteValue.toLocaleString('de-DE')} Haushalte</span>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
          {hasButton && handleButtonClick && (
            <button className="button button--white-outline" onClick={handleButtonClick}>
              Details ansehen
            </button>
          )}
        </>
      )}
    </>
  );
}

export default KennzahlList;
