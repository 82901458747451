function IconQuestionBubble({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15916" xmlns="http://www.w3.org/2000/svg" width="83.337" height="83.172" viewBox="0 0 83.337 83.172">
      <path
        data-name="Pfad 10348"
        d="M60.047,4.165A42.533,42.533,0,0,0,41.651,0,41.774,41.774,0,0,0,0,41.651,41.336,41.336,0,0,0,6.074,63.344L.174,79.137a3.032,3.032,0,0,0,.521,2.95,2.878,2.878,0,0,0,2.777,1.041L24.3,79.311a40.707,40.707,0,0,0,17.355,3.818,40.3,40.3,0,0,0,13.537-2.256A41.2,41.2,0,0,0,79.137,59.7,41.672,41.672,0,0,0,60.047,4.165M73.757,57.27A35.859,35.859,0,0,1,26.032,73.757a3.269,3.269,0,0,0-1.388-.347h-.521L7.636,76.36l4.686-12.322a3.191,3.191,0,0,0-.347-2.777A34.054,34.054,0,0,1,5.9,41.651,34.873,34.873,0,0,1,16.313,16.487,35.5,35.5,0,0,1,57.1,9.719,35.384,35.384,0,0,1,73.757,57.27"
        transform="translate(0)"
        fill={color}
      />
      <path
        data-name="Pfad 10349"
        d="M29.075,12A12,12,0,0,0,17.1,23.975a2.95,2.95,0,1,0,5.9,0,5.9,5.9,0,1,1,5.9,5.9,3.041,3.041,0,0,0-2.95,2.95v5.9a2.95,2.95,0,1,0,5.9,0v-3.3A11.876,11.876,0,0,0,40.7,23.975,11.494,11.494,0,0,0,29.075,12m0,35.751a4.512,4.512,0,1,0,4.512,4.512,4.481,4.481,0,0,0-4.512-4.512"
        transform="translate(12.576 8.826)"
        fill={color}
      />
    </svg>
  );
}

export default IconQuestionBubble;
