import {
  setGenderError,
  setFirstNameError,
  setLastNameError,
  setEmailError,
  setPhoneError,
  setPrivacyPolicyError,
  setRegisterError,
  setPasswordError,
  setPasswordConfirmError,
  setTokenError,
  setFederalStateError,
  setDistrictError,
  setEstateTypeError,
  setEstateSizeError,
  setEstateConditionError,
  setStreetError,
  setTownError,
  setZipError,
  setSearchProfileNameError,
  setLoanValueError,
  setLoanTimeError,
  setPriceFromError,
  setPriceToError,
  setAreaFromError,
  setAreaToError,
  setReasonNameError,
  setPhoneCheckError,
  setMessageError,
  setStreetOnlyError,
  setHouseNumberOnlyError,
} from '../../../reducers/formValidators';

function validateFields(fields, dispatch) {
  let isValid = true;

  Object.keys(fields).map((field) => {
    const value = fields[field];

    switch (field) {
      case 'gender':
        if (value === '') {
          isValid = false;
          dispatch(setGenderError('Bitte wählen Sie eine Anrede aus.'));
        } else {
          dispatch(setGenderError(''));
        }
        break;
      case 'email':
        if (value === '') {
          isValid = false;
          dispatch(setEmailError('Bitte geben Sie eine E-Mail-Adresse ein.'));
        } else if (
          !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        ) {
          isValid = false;
          dispatch(setEmailError('Die E-Mail-Adresse ist ungültig.'));
        } else {
          dispatch(setEmailError(''));
        }
        break;
      case 'password':
      case 'passwordConfirm':
      case 'passwordLogin':
        const passwordReg = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_]).{8,}');
        if (value === '') {
          isValid = false;
          dispatch(setPasswordError('Bitte geben Sie ein Passwort ein.'));
        } else if (!passwordReg.test(value)) {
          isValid = false;
          if (field === 'passwordLogin') {
            dispatch(setPasswordError('Passwort ungültig. Bitte versuchen Sie es erneut.'));
          } else {
            dispatch(setPasswordConfirmError('Passwort ungültig. Beachten Sie die Anforderungen.'));
          }
        } else {
          dispatch(setPasswordError(''));
        }
        break;
      case 'firstName':
        if (value === '') {
          isValid = false;
          dispatch(setFirstNameError('Bitte geben Sie einen Vornamen ein.'));
        } else {
          dispatch(setFirstNameError(''));
        }
        break;
      case 'lastName':
        if (value === '') {
          isValid = false;
          dispatch(setLastNameError('Bitte geben Sie einen Nachnamen ein.'));
        } else {
          dispatch(setLastNameError(''));
        }
        break;
      case 'phone':
        if (value === '' || value === null) {
          isValid = false;
          dispatch(setPhoneError('Bitte geben Sie eine Mobilnummer ein.'));
        } else {
          const phoneNumber = value.toString().replace(/ \(.*?\)/g, '');
          if (phoneNumber.includes(undefined)) {
            isValid = false;
            dispatch(setPhoneError('Bitte wählen Sie die Vorwahl aus.'));
          } else {
            const phoneNumberWithoutPrefix = phoneNumber.substring(3);
            if (phoneNumber.length > 3 && phoneNumber.substring(0, 3) === '+43') {
              const fullNumberRegex = new RegExp(/^\+43\s?(644|650|660|663|664|665|667|670|676|677|678|680|681|686|688|690|699)\s?\d+/);
              if (!fullNumberRegex.test(phoneNumber)) {
                isValid = false;
                dispatch(setPhoneError('Die eingegebene Nummer scheint keine österreichische Mobilnummer zu sein.'));
              } else if (phoneNumberWithoutPrefix.length < 7) {
                isValid = false;
                dispatch(setPhoneError('Bitte geben Sie eine gültige Mobilnummer ein.'));
              } else {
                dispatch(setPhoneError(''));
              }
            } else {
              if (phoneNumberWithoutPrefix.length < 7) {
                isValid = false;
                dispatch(setPhoneError('Bitte geben Sie eine gültige Mobilnummer ein.'));
              } else {
                dispatch(setPhoneError(''));
              }
            }
          }
        }
        break;
      case 'phoneCheck':
        // Expect array of regular number and number to check it against
        const number = value[0];
        const numberCheck = value[1];

        if (number !== numberCheck) {
          isValid = false;
          dispatch(setPhoneCheckError('Die eingegebene Nummer stimmt nicht mit der Bestätigung überein.'));
        } else {
          dispatch(setPhoneCheckError(''));
        }
        break;
      case 'privacyPolicy':
        if (!value) {
          isValid = false;
          dispatch(setPrivacyPolicyError('Dies ist ein Pflichtfeld, bitte akzeptieren.'));
        } else {
          dispatch(setPrivacyPolicyError(''));
        }
        break;
      case 'register':
        if (!value) {
          isValid = false;
          dispatch(setRegisterError('Bitte akzeptieren.'));
        } else {
          dispatch(setRegisterError(''));
        }
        break;
      case 'token':
        if (value === '') {
          isValid = false;
          dispatch(setTokenError('Bitte geben Sie einen Code ein.'));
        } else {
          dispatch(setTokenError(''));
        }
        break;
      case 'federalState':
        if (value === '' || value === null || value === undefined || value < 0) {
          isValid = false;
          dispatch(setFederalStateError('Bitte wählen Sie ein Bundesland aus.'));
        } else {
          dispatch(setFederalStateError(''));
        }
        break;
      case 'district':
      case 'institute':
        if (value === '' || value === null || value === undefined || value < 0) {
          isValid = false;
          if (field === 'institute') {
            dispatch(setDistrictError('Bitte wählen Sie ein Institut aus.'));
          } else {
            dispatch(setDistrictError('Bitte wählen Sie eine Postleitzahl aus.'));
          }
        } else {
          dispatch(setDistrictError(''));
        }
        break;
      case 'estateType':
        if (value === '' || value === null || value === undefined || value < 0) {
          isValid = false;
          dispatch(setEstateTypeError('Bitte wählen Sie die Immobilienart aus.'));
        } else {
          dispatch(setEstateTypeError(''));
        }
        break;
      case 'estateSize':
        if (value === '' || (value !== '' && value && isNaN(value))) {
          isValid = false;
          dispatch(setEstateSizeError('Bitte geben Sie eine Zahl ein.'));
        } else if (value !== '' && value && value < 0) {
          isValid = false;
          dispatch(setEstateSizeError('Bitte geben Sie eine positive Zahl ein.'));
        } else {
          dispatch(setEstateSizeError(''));
        }
        break;
      case 'estateSizeMarktinfo':
        if (value === '') {
          isValid = false;
          dispatch(setEstateSizeError('Bitte geben Sie die Fläche ein.'));
        } else if (parseInt(value) > 800) {
          isValid = false;
          dispatch(setEstateSizeError('Die Fläche darf nicht größer als 800 sein.'));
        } else {
          dispatch(setEstateSizeError(''));
        }
        break;
      case 'estateSizeWohnwert':
        if (value === '') {
          isValid = false;
          dispatch(setEstateSizeError('Bitte geben Sie die Fläche ein.'));
        } else if (parseInt(value) > 5000) {
          isValid = false;
          dispatch(setEstateSizeError('Die Fläche darf nicht größer als 5000 sein.'));
        } else if (parseInt(value) < 50) {
          isValid = false;
          dispatch(setEstateSizeError('Die Fläche darf nicht kleiner als 50 sein.'));
        } else {
          dispatch(setEstateSizeError(''));
        }
        break;
      case 'estateCondition':
        if (value === '') {
          isValid = false;
          dispatch(setEstateConditionError('Bitte wählen Sie den Zustand der Immobilie aus.'));
        } else {
          dispatch(setEstateConditionError(''));
        }
        break;
      case 'street':
        if (value === '') {
          isValid = false;
          dispatch(setStreetError('Bitte wählen Sie die Straße und Hausnummer der Immobilie aus.'));
        } else if (!hasStreetAndHouseNumber(value)) {
          isValid = false;
          dispatch(setStreetError('Bitte geben Sie sowohl Straße als Hausnummer der Immobilie an.'));
        } else {
          dispatch(setStreetError(''));
        }
        break;
      case 'streetOnly':
        if (value === '' || value === null || value === undefined) {
          isValid = false;
          dispatch(setStreetOnlyError('Bitte geben Sie die Straße der Immobilie an.'));
        } else {
          dispatch(setStreetOnlyError(''));
        }
        break;
      case 'houseNumberOnly':
        if (value === '' || value === null || value === undefined) {
          isValid = false;
          dispatch(setHouseNumberOnlyError('Bitte geben Sie die Hausnummer der Immobilie an.'));
        } else {
          dispatch(setHouseNumberOnlyError(''));
        }
        break;
      case 'town':
        if (value === '' || value === null || value === undefined || value < 0) {
          isValid = false;
          dispatch(setTownError('Bitte wählen Sie den Ort der Immobilie aus.'));
        } else {
          dispatch(setTownError(''));
        }
        break;
      case 'zip':
        if (value === '') {
          isValid = false;
          dispatch(setZipError('Bitte wählen Sie die PLZ der Immobilie aus.'));
        } else if (!/^[0-9]+$/.test(value)) {
          isValid = false;
          dispatch(setZipError('Bitte geben Sie nur Zahlen ein.'));
        } else {
          dispatch(setZipError(''));
        }
        break;
      case 'searchProfileName':
        if (value.trim() === '') {
          isValid = false;
          dispatch(setSearchProfileNameError('Bitte geben Sie einen Namen an.'));
        } else if (value?.length > 25) {
          isValid = false;
          dispatch(setSearchProfileNameError('Bitte geben Sie maximal 25 Zeichen an.'));
        } else {
          dispatch(setSearchProfileNameError(''));
        }
        break;
      case 'loanValue':
        if (value === 0) {
          isValid = false;
          dispatch(setLoanValueError('Bitte geben Sie die gewünschte Darlehenssumme an.'));
        } else if (value < 5000) {
          isValid = false;
          dispatch(setLoanValueError('Der Wert muss mind. 5000 betragen.'));
        } else {
          dispatch(setLoanValueError(''));
        }
        break;
      case 'loanTime':
        if (value === 0) {
          isValid = false;
          dispatch(setLoanTimeError('Bitte geben Sie die Laufzeit an.'));
        } else if (value < 10) {
          isValid = false;
          dispatch(setLoanTimeError('Die Laufzeit muss mind. 10 Jahre betragen.'));
        } else {
          dispatch(setLoanTimeError(''));
        }
        break;
      case 'priceFrom':
        if (value !== '' && value && isNaN(value)) {
          isValid = false;
          dispatch(setPriceFromError('Bitte geben Sie eine Zahl ein.'));
        } else if (value !== '' && value && value < 0) {
          isValid = false;
          dispatch(setPriceFromError('Bitte geben Sie eine positive Zahl ein.'));
        } else {
          dispatch(setPriceFromError(''));
        }
        break;
      case 'priceTo':
        if (value !== '' && value && isNaN(value)) {
          isValid = false;
          dispatch(setPriceToError('Bitte geben Sie eine Zahl ein.'));
        } else if (value !== '' && value && value < 0) {
          isValid = false;
          dispatch(setPriceToError('Bitte geben Sie eine positive Zahl ein.'));
        } else {
          dispatch(setPriceToError(''));
        }
        break;
      case 'areaFrom':
        if (value !== '' && value && isNaN(value)) {
          isValid = false;
          dispatch(setAreaFromError('Bitte geben Sie eine Zahl ein.'));
        } else if (value !== '' && value && value < 0) {
          isValid = false;
          dispatch(setAreaFromError('Bitte geben Sie eine positive Zahl ein.'));
        } else {
          dispatch(setAreaFromError(''));
        }
        break;
      case 'areaTo':
        if (value !== '' && value && isNaN(value)) {
          isValid = false;
          dispatch(setAreaToError('Bitte geben Sie eine Zahl ein.'));
        } else if (value !== '' && value && value < 0) {
          isValid = false;
          dispatch(setAreaToError('Bitte geben Sie eine positive Zahl ein.'));
        } else {
          dispatch(setAreaToError(''));
        }
        break;
      case 'reason':
        if (value === '') {
          isValid = false;
          dispatch(setReasonNameError('Bitte wählen Sie Ihr Anliegen aus.'));
        } else {
          dispatch(setReasonNameError(''));
        }
        break;
      case 'message':
        if (!value) {
          isValid = false;
          dispatch(setMessageError('Bitte geben Sie eine Nachricht ein.'));
        } else {
          dispatch(setMessageError(''));
        }
        break;
      default:
        break;
    }
  });

  if (isValid) {
    dispatch(setGenderError(''));
    dispatch(setFirstNameError(''));
    dispatch(setPrivacyPolicyError(''));
    dispatch(setRegisterError(''));
    dispatch(setLastNameError(''));
    dispatch(setEmailError(''));
    dispatch(setPhoneError(''));
    dispatch(setPhoneCheckError(''));
    dispatch(setPasswordError(''));
    dispatch(setPasswordConfirmError(''));
    dispatch(setTokenError(''));

    dispatch(setFederalStateError(''));
    dispatch(setDistrictError(''));
    dispatch(setEstateTypeError(''));
    dispatch(setEstateSizeError(''));
    dispatch(setEstateConditionError(''));

    dispatch(setStreetError(''));
    dispatch(setTownError(''));
    dispatch(setZipError(''));

    dispatch(setLoanValueError(''));
    dispatch(setLoanTimeError(''));

    dispatch(setSearchProfileNameError(''));
    dispatch(setReasonNameError(''));
  }

  return isValid;
}

function hasStreetAndHouseNumber(str) {
  const regex = /([A-Za-zäöüßÄÖÜ.-\s]+)\s(\d+)/g;
  return regex.test(str);
}

function confirmPassword(password, passwordConfirm, dispatch) {
  if (!password || !passwordConfirm || password === '' || passwordConfirm === '') {
    return false;
  }

  if (password !== passwordConfirm) {
    dispatch(setPasswordConfirmError('Die Passwörter stimmen nicht überein.'));
    return false;
  } else {
    dispatch(setPasswordConfirmError(''));
    return true;
  }
}

function checkTokenToVerificationCode(token, verificationCode, dispatch) {
  if (token === verificationCode) {
    dispatch(setTokenError('Sie haben die Prüfziffer eingegeben. Bitte geben Sie den 6-stelligen SMS-Code ein.'));
    return false;
  } else {
    dispatch(setTokenError(''));
    return true;
  }
}

function validateFieldsMinMax(min, max) {
  let valid = true;
  let minInt = parseInt(min);
  let maxInt = parseInt(max);

  if (minInt > 0 && maxInt > 0) {
    if (minInt > maxInt) {
      valid = false;
    }

    if (maxInt < minInt) {
      valid = false;
    }
  }

  return valid;
}

export { validateFields, confirmPassword, checkTokenToVerificationCode, validateFieldsMinMax };
