function IconChat2({ color, cssClasses }) {
  return (
    <svg className={cssClasses} id="Gruppe_15167" data-name="Gruppe 15167" xmlns="http://www.w3.org/2000/svg" width="25" height="20.51" viewBox="0 0 25 20.51">
      <path
        id="Pfad_8714"
        data-name="Pfad 8714"
        d="M22.389,0H2.662A2.673,2.673,0,0,0,0,2.662V17.849A2.673,2.673,0,0,0,2.662,20.51H22.337A2.673,2.673,0,0,0,25,17.849V2.662A2.6,2.6,0,0,0,22.389,0M2.662,1.774H22.337a.915.915,0,0,1,.887.887v.052L13.1,11.377a.94.94,0,0,1-1.148,0L1.774,2.714V2.662a.915.915,0,0,1,.887-.887M22.389,18.788H2.662a.915.915,0,0,1-.887-.887V5.062L10.8,12.734a2.681,2.681,0,0,0,3.444,0l9.029-7.672V17.9a.915.915,0,0,1-.887.887"
        fill={color}
      />
    </svg>
  );
}

export default IconChat2;
