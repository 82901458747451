import React from 'react';

function IconLocation({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="60.116"
      height="90.174"
      viewBox="0 0 60.116 90.174"
    >
      <g data-name="Gruppe 10167" transform="translate(-152 -146.913)">
        <g data-name="Gruppe 10165">
          <path
            data-name="Pfad 7574"
            d="M182.058 146.913A30.091 30.091 0 0 0 152 176.971c0 15.942 27.323 57.512 28.491 59.27a1.875 1.875 0 0 0 3.134 0c1.164-1.758 28.491-43.328 28.491-59.27a30.091 30.091 0 0 0-30.058-30.058m0 43.208a13.15 13.15 0 1 1 13.15-13.15 13.165 13.165 0 0 1-13.15 13.15"
            fill={color}
          />
        </g>
        <circle
          data-name="Ellipse 1463"
          cx="23"
          cy="23"
          r="23"
          transform="translate(160 154)"
          fill={color}
        />
        <g data-name="Gruppe 10166">
          <g data-name="Gruppe 10131" fill="#fff">
            <path
              data-name="Pfad 7576"
              d="M199.392 189.536h-3.659v-12.439a.733.733 0 0 0-.733-.732h-24.877a.731.731 0 0 0-.732.732v12.439h-3.658a.732.732 0 0 0 0 1.463h33.659a.732.732 0 0 0 0-1.463m-25.61-10.971a.731.731 0 0 1 .732-.732h4.386a.733.733 0 0 1 .732.732v7.317a.733.733 0 0 1-.732.732h-4.387a.731.731 0 0 1-.732-.732Zm7.318 10.971a.733.733 0 0 1-.732.732h-7.318a.731.731 0 0 1-.732-.732v-.732a.731.731 0 0 1 .732-.732h7.317a.733.733 0 0 1 .732.732Zm10.244-5.122a.733.733 0 0 1-.732.732h-7.318a.731.731 0 0 1-.732-.732v-4.39a.731.731 0 0 1 .732-.732h7.317a.733.733 0 0 1 .732.732Z"
            />
            <path
              data-name="Pfad 7577"
              d="M165.733 174.9h33.659a.732.732 0 0 0 .435-1.32l-4.094-3.024v-5.9a.733.733 0 0 0-.733-.729h-4.39a.731.731 0 0 0-.732.732v1.57L183 161.143a.738.738 0 0 0-.872 0L165.3 173.582a.732.732 0 0 0 .433 1.318"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconLocation;
