import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cachedRealtyCount: null,
  cachedRealties: [],
};

export const cachedRealtiesSlice = createSlice({
  name: 'cachedRealties',
  initialState,
  reducers: {
    setCachedRealtyCount: (state, action) => {
      state.cachedRealtyCount = action.payload;
    },
    setCachedRealties: (state, action) => {
      state.cachedRealties = action.payload;
    },
    setResetFieldsCachesRealties: (state) => {
      return initialState;
    },
  },
});

export const { setCachedRealtyCount, setCachedRealties, setResetFieldsCachesRealties } = cachedRealtiesSlice.actions;

export default cachedRealtiesSlice.reducer;
