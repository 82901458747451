import moment from 'moment';
import httpClient from './index';

const END_POINT = '/User';

const getRealEstates = async () => {
  try {
    const resp = await httpClient.get(`${END_POINT}/realestates`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getStats = async (realEstateId) => {
  try {
    let now = new Date();
    const requests = [
      httpClient.get(`${END_POINT}/stats/${realEstateId}?from=${moment(now).startOf('isoweek').format('YYYY-MM-DD')}&to=${moment(now).endOf('isoweek').format('YYYY-MM-DD')}`),
      httpClient.get(`${END_POINT}/stats/${realEstateId}`),
      httpClient.get(`${END_POINT}/stats/${realEstateId}?from=${moment(now).subtract(3, 'isoweek').format('YYYY-MM-DD')}&to=${moment(now).endOf('isoweek').format('YYYY-MM-DD')}`),
    ];
    const [week, all, last3Weeks] = await Promise.all(requests);

    return {
      'Aktuelle Woche': week.data,
      Gesamtlaufzeit: all.data,
      'Letzte 3 Wochen': last3Weeks.data,
    };
  } catch (e) {
    throw e;
  }
};

const userReadActivities = async (activities) => {
  if (!activities) return false;

  try {
    const resp = await httpClient.put(`${END_POINT}/activities?activities=` + activities);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const updateEmailPreferences = async (emailPreferences) => {
  try {
    const queryParams = new URLSearchParams({
      documents: emailPreferences.documents,
      activities: emailPreferences.activities,
      viewings: emailPreferences.viewings,
      hour: emailPreferences.hour,
      intervalDays: emailPreferences.intervalDays,
    });

    const url = `${END_POINT}/email?${queryParams.toString()}`;

    const resp = await httpClient.put(url);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export { getRealEstates, getStats, userReadActivities, updateEmailPreferences };
