function IconArrowRight2({ color, cssClasses, width, height }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14.826"
    >
      <g data-name="Gruppe 6424" transform="translate(-444.627 -967.28)">
        <line
          data-name="Linie 906"
          x1="12"
          transform="translate(445.628 974.693)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          data-name="Pfad 1121"
          d="M456.128,980.693l6.5-6-6.5-6"
          transform="translate(-4.999)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default IconArrowRight2;
