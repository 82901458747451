import React from 'react';
import InfoDialog from './InfoDialog';

function InfoDialogBundesland({ open, handleClose }) {
  return (
    <InfoDialog open={open} handleClose={handleClose} headline="Welches Bundesland wird benötigt?">
      <p className="mb-200rem">
        Um Sie mit der richtigen Ansprechpartner:in zu vernetzen, brauchen wir bitte die Angabe
        eines Bundeslandes. Bitte wählen sie dieses je nach Zweck aus:
      </p>
      <p className="mb-200rem">
        <span className="font-weight-600">Sie schicken eine allgemeine Anfrage? </span>
        <br />
        Wählen Sie bitte das Bundesland Ihres Wohnortes.
      </p>
      <p className="mb-200rem">
        <span className="font-weight-600">Sie sind auf der Suche nach einer Immobilie? </span>
        <br />
        Wählen Sie Ihr Wunschbundesland.
      </p>
      <p className="mb-200rem">
        <span className="font-weight-600">Sie wollen eine Immobilie verkaufen? </span>
        <br />
        Wählen Sie bitte das Bundesland Ihrer Immobilie.
      </p>
    </InfoDialog>
  );
}

export default InfoDialogBundesland;
