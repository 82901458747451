import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Kennzahlen from '../Kennzahlen/Kennzahlen';

// ICONS
import IconCheckmark from '../Icons/IconCheckmark';
import IconCheckmarkRound from '../Icons/IconCheckmarkRound';
import IconMarktinfoPro from '../Icons/IconMarkinfoPro';
import IconMarktreport from '../Icons/IconMarktreport';
import IconHakerl from '../Icons/IconHakerl';

// STORE
import { setPageDialogOpenLoginForm } from '../../reducers/loginForm';
import { setPageDialogOpenMarktinfoForm, setResetFieldsMarktinfoLightForm } from '../../reducers/marktinfoLight';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';

import '../../styles/marktinfo.scss';

import { useHandleMarktinfo } from '../../hooks/user/useHandleMarktinfo';
import { downloadDoc } from '../../util/generalUtils';
import FormSelectMarktinfoLastResults from '../FormFields/customFields/FormSelectMarktinfoLastResults';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function MarktinfoLightResult() {
  let user = useSelector((state) => state.user);
  const { isMobile } = useHandleScreenResize();

  const dispatch = useDispatch();
  const { getMarktbericht, getKennzahlenData } = useHandleMarktinfo();

  const [kennzahlenMenu, setKennzahlenMenu] = useState([]);
  const [kennzahlen, setKennzahlen] = useState([]);
  const [marktbericht, setMarktbericht] = useState([]);

  useEffect(() => {
    setMarktbericht(getMarktbericht());
    setKennzahlenData();
  }, []);

  useEffect(() => {
    setKennzahlenData();
  }, [user.marktinfoResultActive, user.marktinfoLastResults]);

  const setKennzahlenData = () => {
    let key = user.marktinfoResultActive.id;
    if (key === -1) {
      key = 0;
    }

    let marktinfoResultShow = user.marktinfoLastResults[key];
    if (!user.isLoggedIn) {
      marktinfoResultShow = [user.marktinfoLastResults];
    }

    if (marktinfoResultShow && marktinfoResultShow.length > 0) {
      let [categories, values] = getKennzahlenData(marktinfoResultShow);

      setKennzahlenMenu(categories);
      setKennzahlen(values);
    }
  };

  const handleOpenRegisterForm = () => {
    dispatch(setPageDialogOpenMarktinfoForm(false));
    dispatch(setResetFieldsMarktinfoLightForm());

    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const handleOpenLoginForm = () => {
    dispatch(setPageDialogOpenMarktinfoForm(false));
    dispatch(setResetFieldsMarktinfoLightForm());

    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  const handleOpenMarktinfoForm = () => {
    dispatch(setPageDialogOpenMarktinfoForm(true));
  };

  return (
    <div className={user.isLoggedIn ? 'marktinfolight__result' : 'marktinfolight__result marktinfolight__result--withSavedInfo'}>
      {user.isLoggedIn && Object.keys(user.marktinfoLastResults).length > 0 && (
        <div className="marktinfolight__result--prev">
          <FormSelectMarktinfoLastResults label={'Ihre Marktanalysen'} />

          <p className="desktop-hidden">Greifen Sie bequem mit einem Klick auf Ihre gespeicherten Analysen zu.</p>
        </div>
      )}

      <div className="marktinfolight__result__content">
        <h3>Ihr Ergebnis</h3>
        <Kennzahlen filtermenu={kennzahlenMenu} kennzahlen={kennzahlen} hasButton={true} handleButtonClick={handleOpenMarktinfoForm} cutside={'left'} />
      </div>

      {!user.isLoggedIn ? (
        <div className="marktinfolight__result__weitere pt-0 pl-200rem pr-200rem">
          <h4 className="mt-0">Sie wollen weitere Abfragen durchführen und speichern?</h4>
          <p className="font-90 lh-150 mt-100rem mb-200rem">Mit Ihrer Registrierung bei my-sreal.at können Sie bis zu drei verschiedene Abfragen mit MARKTINFOᴸᴵᴳᴴᵀ durchführen und diese speichern.</p>

          <div className="button-panel">
            <button className="button button--orange" onClick={handleOpenRegisterForm}>
              Jetzt registrieren
            </button>
            <button className="button button--gray-outline" onClick={handleOpenLoginForm}>
              Anmelden
            </button>
          </div>
        </div>
      ) : marktbericht > 0 ? (
        <>
          <div className="p-30 pt-0">
            <IconMarktinfoPro color="#1e3163" />
            <h4>Hier finden Sie Ihren umfassenden Marktreport</h4>

            <p className="font-90 pt-100rem">Hier finden Sie den individuellen Marktbericht zu Ihrer Immobilie. Informieren Sie sich zu Wertentwicklung, Lagebewertung und demografischer Umgebung.</p>

            <div className="marktinfopro__content mt-200rem">
              <div className="marktinfopro__content__item" onClick={() => downloadDoc(marktbericht.url, marktbericht.headline)} title="Marktbericht runterladen">
                <IconMarktreport color="#1e3163" />
                <span className="textlink">Marktbericht ansehen</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="marktinfolight__result__savedInfo">
          <div className="p-30 pt-0 d-flex flex-row">
            <div>
              <h4 className="mt-0 pl-5">
                <IconCheckmarkRound color="#0eb43f" cssClasses="scale-1-5 mr-75rem" />
                Marktanalyse gespeichert.{' '}
                <span>
                  Sie können noch <span className="font-weight-600">{10 - Object.keys(user.marktinfoLastResults).length}</span> weitere Marktanalysen durchführen.
                </span>
              </h4>
              <p>Als Nutzer:in von my-sreal.at genießen Sie viele Vorteile. Für mehr Bequemlichkeit speichern wir Ihre bisherigen Marktanlysen für Sie ab.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MarktinfoLightResult;
