import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, deDE, DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/de';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import { Clock } from '@mui/x-date-pickers/internals/components/icons';
import IconCalendar from '../Icons/IconCalendar';
import IconClock from '../Icons/IconClock';

import { setAppointmentDateContactForm, setAppointmentTimeContactForm, setAppointment2DateContactForm, setAppointment2TimeContactForm } from '../../reducers/contactInformation';

dayjs.extend(customParseFormat);
const theme = createTheme(
  {
    palette: {
      primary: { main: '#3679ec' }, //$erste-blue color
    },
  },
  deDE, // x-date-pickers translations
);

function DateTimePicker({ id, dateValue, timeValue, formType }) {
  const dispatch = useDispatch();

  const [dateError, setDateError] = useState('');
  const [timeError, setTimeError] = useState('');

  const holidays = ['1 0', '6 0', '1 4', '15 7', '26 9', '1 10', '8 11', '25 11', '26 11'];
  const movingHolidays = ['10 3 2023', '18 4 2023', '29 4 2023', '8 5 2023', '1 3 2024', '9 4 2024', '20 4 2024', '30 4 2024', '21 3 2025', '29 4 2025', '9 5 2025', '19 5 2025'];

  // IS IT WEEKEND?
  const isWeekend = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };
  // IS IT HOLIDAY?
  const isHoliday = (date) => {
    const day = `${date.getDate()} ${date.getMonth()}`;
    const year = moment(date).format('YYYY');
    return holidays.includes(day) || movingHolidays.includes(`${day} ${year}`);
  };

  const handleDateSelection = (newDateValue) => {
    setTimeError('');
    setDateError('');

    let val = newDateValue.valueOf();
    let localTime = moment.utc(val).toDate();
    let todayDate = Date.now();
    let isPast = dayjs(val).isBefore(todayDate);

    // rest date selection in reducer
    if (id === 'inputAppointment') {
      dispatch(setAppointmentDateContactForm(''));
    } else if (id === 'inputAppointment2') {
      dispatch(setAppointment2DateContactForm(''));
    }

    if (isPast) {
      setDateError('Dieses Datum liegt in der Vergangenheit. Bitte wählen Sie ein anderes aus.');
    } else if (isWeekend(localTime)) {
      setDateError('Dieses Datum fällt auf ein Wochenende. Bitte wählen Sie ein anderes aus.');
    } else if (isHoliday(localTime)) {
      setDateError('Dieses Datum fällt auf einen Feiertag. Bitte wählen Sie ein anderes aus.');
    } else {
      if (id === 'inputAppointment') {
        dispatch(setAppointmentDateContactForm(val));
      } else if (id === 'inputAppointment2') {
        dispatch(setAppointment2DateContactForm(val));
      }
    }
  };

  const handleTimeSelection = (newTimeValue) => {
    let hours = dayjs(newTimeValue).hour();
    let mins = dayjs(newTimeValue).minute();

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }

    let newTime = hours + ':' + mins + ':00';

    setTimeError('');
    if (id === 'inputAppointment') {
      if (!dateValue) {
        setTimeError('Bitte wählen Sie zuerst das Datum aus.');
      } else {
        dispatch(setAppointmentTimeContactForm(newTime));
      }
    } else if (id === 'inputAppointment2') {
      if (!dateValue) {
        setTimeError('Bitte wählen Sie zuerst das Datum aus.');
      } else {
        dispatch(setAppointment2TimeContactForm(newTime));
      }
    }
  };

  // DISABLE CALENDER ON WEEKEND DAYS & HOLIDAYS
  function disableWeekendsAndHolidays(date) {
    let val = date.valueOf();
    let localTime = moment.utc(val).toDate();
    return isWeekend(localTime) || isHoliday(localTime);
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <div className="form__field">
          <label htmlFor={`${id}dateInput`}>Datum</label>
          <br />

          <DesktopDatePicker
            className="mui-input"
            id={`${id}dateInput`}
            inputFormat="DD/MM/YYYY"
            shouldDisableDate={disableWeekendsAndHolidays}
            value={dateValue !== null ? dateValue : ''}
            onChange={(newDateValue) => {
              handleDateSelection(newDateValue);
            }}
            components={{
              OpenPickerIcon: IconCalendar,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-formtype': formType,
                  placeholder: 'TT/MM/JJ',
                }}
              />
            )}
          />
        </div>

        <div className="form__field">
          <label htmlFor={`${id}timeInput`}>Uhrzeit</label>
          <br />
          <TimePicker
            className="mui-input"
            components={{
              OpenPickerIcon: IconClock,
            }}
            id={`${id}timeInput`}
            value={timeValue !== null ? dayjs(timeValue, 'HH:mm:ss', 'de') : ''}
            onChange={(newTimeValue) => {
              handleTimeSelection(newTimeValue);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Clock />
                </InputAdornment>
              ),
            }}
            ampmInClock={false}
            ampm={false}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  'data-formtype': formType,
                  placeholder: 'HH:MM',
                }}
              />
            )}
            minTime={dayjs(`${moment(dateValue).format('YYYY-MM-DD')}T07:00`)}
            maxTime={dayjs(`${moment(dateValue).format('YYYY-MM-DD')}T18:00`)}
          />
        </div>

        {timeError && <p className="form__fielderror">{timeError}</p>}
        {dateError && <p className="form__fielderror">{dateError}</p>}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default DateTimePicker;
