import React, { useState } from 'react';
import IconArrowUp2 from './IconArrowUp2';

function IconShare({ color, cssClasses, handleClick, urlToSend }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isHidingPopup, setIsHidingPopup] = useState(false);
  const [copyInfoVisible, setCopyInfoVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const togglePopup = () => {
    if (isPopupOpen) {
      setIsHidingPopup(true);
      setTimeout(() => {
        setIsPopupOpen(false);
        setIsHidingPopup(false);
      }, 300);
    } else {
      setIsPopupOpen(true);
    }
  };

  const handleIconCloseClick = (e) => {
    e.stopPropagation();
    togglePopup();
  };

  const handleShareByEmail = () => {
    const subject = encodeURIComponent('Weiterempfehlung einer Immobilie von my-sreal.at');
    const body = encodeURIComponent(`Ich möchte dir gerne eine Immobilie von my-sreal.at zeigen:\n\n${urlToSend}\n\n`);
    const recipient = '';

    const mailtoUrl = `mailto:${recipient}?subject=${subject}&body=${body}`;

    togglePopup();
    window.open(mailtoUrl, '_blank');
  };

  const handleShareByWhatsApp = () => {
    const text = 'Ich möchte dir gerne eine Immobilie von my-sreal.at zeigen: \n\n';
    const waUrl = `https://wa.me/?text=${encodeURIComponent(text + urlToSend)}`;

    togglePopup();
    window.open(waUrl, '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(urlToSend)
      .then(() => {
        setCopyInfoVisible(true);
        setTimeout(() => {
          setCopyInfoVisible(false);
        }, 1000);
      })
      .catch((err) => {
        console.error('Fehler beim Kopieren:', err);
      });
    togglePopup();
  };

  return (
    <div className={"popup--share-wrapper " + cssClasses}>
      <svg onClick={() => {
          togglePopup();
          if (handleClick) handleClick();
        }}
        xmlns="http://www.w3.org/2000/svg" width="27.941" height="24" viewBox="0 0 27.941 24">
        <g data-name="Gruppe 15087" transform="translate(0 0)">
          <g data-name="Gruppe 15099" transform="translate(0 0)">
            <path data-name="Pfad 8760" d="M27.657,10.31,17.7.29A1.088,1.088,0,0,0,16.588.057a1.1,1.1,0,0,0-.64.932V6h-.99C5.228,6,2.49,14.912.626,20.738c-.233.641-.408,1.282-.583,1.922a1.051,1.051,0,0,0,.408,1.165A.984.984,0,0,0,1.034,24a.876.876,0,0,0,.641-.233c.757-.641,1.456-1.223,2.039-1.806,4.136-3.612,5.65-4.952,10.253-4.952h1.981v5.01a1,1,0,0,0,.64.932.979.979,0,0,0,1.107-.233l9.961-11.011a1,1,0,0,0,0-1.4M17.987,19.4V15.961a1.021,1.021,0,0,0-.99-.99h-3.03c-5.068,0-7.107,1.515-10.952,4.894C4.82,14.213,7.384,7.98,14.957,7.98h1.98a1.021,1.021,0,0,0,.991-.99V3.436l7.632,7.632Z" transform="translate(0 0)" fill={color} />
          </g>
        </g>
      </svg>
      {isPopupOpen && (
        <div className={`popup--share ${isHidingPopup ? 'animate--slideup' : ''}`}>
          <ul>
            <li onClick={handleShareByEmail} 
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave} >
              E-Mail
              <div onClick={handleIconCloseClick}>
                <IconArrowUp2 color={isHovered ? '#fff' : '#1e3163'} />
              </div>
            </li>
            <li onClick={handleShareByWhatsApp}>WhatsApp</li>
            <li onClick={handleCopyLink}>Link kopieren</li>
          </ul>
        </div>
      )}
      <div className={`popupmini--info ${copyInfoVisible ? 'is-visible' : ''}`}>Link kopiert!</div>{' '}
    </div>
  );
}

export default IconShare;
