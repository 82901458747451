import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from './Card';

// ICONS
import IconHeart from '../Icons/IconHeart';
import IconHouse from '../Icons/IconHouse';

// STORE
import InfoDialogUnfavourite from '../Dialogs/InfoDialogUnfavourite';
import { MENU_KAUF, PHASE1_KAUF } from '../../constants';
import { getKaufpreis, getMainArea } from '../../util/immoObjectParser';

// HOOKS
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';

function LinkCardToAlleAngeboteSmall({ retailObject, cssClasses, isAbgeber, status, wohneinheiten }) {
  const { addToFavourites } = useHandleFavorite(retailObject);
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const [kaufpreis, setKaufpreis] = useState('');
  const [area, setArea] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  if (wohneinheiten) cssClasses += ' immo-card--residential';
  const [openRemoveFavDialog, setOpenRemoveFavDialog] = useState(false);

  const handleLink = (e) => {};

  return (
    <>
      <div onClick={(e) => handleLink(e)}>
        <Card
          cardStyle={'teaserMiniImage'}
          linkTo={`${app.root}/angebote`}
          cssClasses={'linkOnFullCard with-shadow bg-blue'}
          imagePath={coverImage}
          icon={<IconHouse cssClasses={'scale-1-5'} color="#fff" />}
        >
          <div className="immo-card__image--container">
            <div className="immo-card__image bg-white">
              <IconHouse color={'#2970ed'} cssClasses={'scale-2'} />
            </div>
          </div>
          <div className="immo-card__content--container-small font-80 text-white">
            <span className="mb-50rem">Weitere Empfehlungen?</span>
            <span className="text-bold">Alle Anzeigen</span>
          </div>
        </Card>
      </div>
    </>
  );
}

export default LinkCardToAlleAngeboteSmall;
