import { useDispatch, useSelector } from 'react-redux';
import { setPageDialogOpenSPEdit, setResetFieldsSPForm } from '../../reducers/searchProfileForm';
import { setResetFormValidator } from '../../reducers/formValidators';
import SearchData from '../Forms/SearchProfile/SearchData';
import PageDialog from './PageDialog';

function PageDialogSuchprofilEdit({ suchprofilId }) {
  const dispatch = useDispatch();

  const currentSearchProfileForm = useSelector((state) => state.searchProfileForm);

  const handleClose = () => {
    dispatch(setPageDialogOpenSPEdit(false));
    dispatch(setResetFieldsSPForm());
    dispatch(setResetFormValidator());
  };

  const formType = 'searchProfileForm';
  const pageDialogId = 'PageDialogSearchprofilesEdit';

  return (
    <PageDialog
      id={pageDialogId}
      open={currentSearchProfileForm.pageDialogOpenSPEdit}
      handleClose={handleClose}
      headline={currentSearchProfileForm.headline}
      showBackArrow={false}
    >
      <div className="searchProfileForm p-100rem">
        <SearchData
          formType={formType}
          id={pageDialogId}
          searchProfileToEditId={suchprofilId}
        />
      </div>
    </PageDialog>
  );
}

export default PageDialogSuchprofilEdit;
