import InfoDialog from './InfoDialog';
function InfoDialog2FAuth({ open, handleClose }) {
  return (
    <InfoDialog
      open={open}
      handleClose={handleClose}
      headline="Wozu dient die 2-Faktor-Authentifizierung?"
    >
      <p className="mb-200rem">
        Mit der 2-Faktor-Authentifizierung erfolgt der Zugang zu Ihrem Konto über zwei Stufen. Neben
        dem Passwort wird ein zweiter, maximal drei Minuten gültiger Code generiert, den Sie für den
        Login benötigen. Da dieser Code via SMS verschickt wird, benötigen wir dafür Ihre
        Mobilnummer.
      </p>
      <p>
        Dadurch erhöhen wir Ihre Sicherheit und verringern das Risiko, dass sich Dritte Zugang zu
        Ihrem Konto verschaffen können. Die Zwei-Faktor-Authentifizierung ist einer der effektivsten
        Methoden, um Ihren Account vor Unbefugten zu schützen.
      </p>
    </InfoDialog>
  );
}

export default InfoDialog2FAuth;
