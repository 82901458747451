import React from 'react';

function IconBildung({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15838" xmlns="http://www.w3.org/2000/svg" width="29.102" height="27.311" viewBox="0 0 29.102 27.311">
      <g data-name="Gruppe 15839" transform="translate(0 0)">
        <path
          data-name="Pfad 10329"
          d="M28.766,12.458a.99.99,0,0,0-1.455,0,29.13,29.13,0,0,1-4.789,3.819c-.061-.061-.061-.121-.121-.182L18.46,12.155c-.061-.061-.061-.061-.121-.061a26.983,26.983,0,0,1,3.819-4.85A1.029,1.029,0,0,0,20.7,5.789a32.089,32.089,0,0,0-4.668,6.123c-2.607.121-5.82.424-6,.485a1.093,1.093,0,0,0-.909.849C8.881,14.337,7,23.794,7.608,26.4a1.044,1.044,0,0,0,.788.788,8.513,8.513,0,0,0,1.394.121c3.637,0,10.73-1.455,11.7-1.637a1.179,1.179,0,0,0,.849-.909c0-.182.3-3.395.424-6a30.393,30.393,0,0,0,6.123-4.729,1.262,1.262,0,0,0-.121-1.576M20.218,23.673c-2.789.546-6.729,1.212-9.215,1.455l3.274-3.274A1.029,1.029,0,0,0,12.822,20.4L9.609,23.613A93.19,93.19,0,0,1,11,14.337c1.212-.121,3.637-.364,5.517-.424h.788l3.274,3.334c0,1.94-.242,4.971-.364,6.426M5.85,10.7C7.426,5.91,5.244,3.122,3.668,1.06A4.247,4.247,0,0,0,3.183.454,1,1,0,0,0,1.728.212a1.049,1.049,0,0,0-.182,1.455l.546.727c1.516,1.94,3.031,3.941,1.819,7.639a27.416,27.416,0,0,1-1.758,3.88c-2.061,4-4.426,8.609,2.243,12.307a1.142,1.142,0,0,0,.485.121A1,1,0,0,0,5.79,25.8.968.968,0,0,0,5.365,24.4c-4.729-2.607-3.7-5.032-1.394-9.518A25.929,25.929,0,0,0,5.85,10.7"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconBildung;
