import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../styles/quicklinks.scss';
import IconArrowUp from './Icons/IconArrowUp3';

function Quicklinks({ list }) {
  const [activeLink, setActiveLink] = useState('');
  const [initialOffsetTop, setInitialOffsetTop] = useState(null);
  const quicklinksRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      list.forEach((item) => {
        const element = document.getElementById(item.id);
        if (element) {
          const elementTop = element.offsetTop;
          const elementHeight = element.offsetHeight;

          if (scrollPosition >= elementTop - 90 && scrollPosition < elementTop + elementHeight) {
            setActiveLink(item.id);
          }
        }
      });

      if (quicklinksRef.current) {
        if (initialOffsetTop === null) {
          setInitialOffsetTop(quicklinksRef.current.offsetTop);
        }
        if (scrollPosition >= initialOffsetTop) {
          quicklinksRef.current.classList.add('sticky');
          document.body.style.paddingTop = `${quicklinksRef.current.offsetHeight}px`;
        } else {
          quicklinksRef.current.classList.remove('sticky');
          document.body.style.paddingTop = 0;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.style.paddingTop = 0;
      document.querySelectorAll('.quicklinks').forEach((el) => {
        el.classList.remove('sticky');
      });
    };
  }, [list, initialOffsetTop]);

  useEffect(() => {
    if (quicklinksRef.current && initialOffsetTop === null) {
      setInitialOffsetTop(quicklinksRef.current.offsetTop);
    }
  }, [initialOffsetTop]);

  return (
    <div className="quicklinks" ref={quicklinksRef}>
      {list.map((item, index) => (
        <div key={index} className="quicklinks__item">
          <a
            className={`quicklinks__link ${activeLink === item.id ? 'active' : ''}`}
            href={`#${item.id}`}
            onClick={(e) => {
              e.preventDefault();
              const element = document.getElementById(item.id);
              if (element) {
                const offset = 90;
                const elementPosition = element.offsetTop - offset;
                window.scrollTo({
                  top: elementPosition,
                  behavior: 'smooth',
                });
              }
            }}
          >
            {item.label}
          </a>
          <span className={`arrow ${activeLink === item.id ? 'active' : ''}`}>
            <IconArrowUp />
          </span>
        </div>
      ))}
    </div>
  );
}
export default Quicklinks;
