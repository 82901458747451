function IconCheckmarkRound({ color, cssClasses, inverted, scale }) {
  return (
    <svg className={cssClasses} style={{ transform: `scale(${scale})` }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="Gruppe_15423" data-name="Gruppe 15423" transform="translate(0 -3)">
        <circle id="Ellipse_13" data-name="Ellipse 13" cx="9" cy="9" r="9" transform="translate(0 3)" fill={!inverted ? '#2970ed' : '#fff'} />
        <path
          id="Pfad_8507"
          data-name="Pfad 8507"
          d="M.927,4.085a.889.889,0,0,1,1.258,0L4.3,6.2,8.732.78a.889.889,0,1,1,1.376,1.126L5.053,8.085a.889.889,0,0,1-1.317.066L.927,5.343A.889.889,0,0,1,.927,4.085Z"
          transform="translate(3.333 7.546)"
          fill={!inverted ? '#fff' : '#2970ed'}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default IconCheckmarkRound;
