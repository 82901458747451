import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../../styles/fullwidthcontent.scss';

function FullWidthContent({ cssClasses = '', children, imagePath }) {
  const app = useSelector((state) => state.app);
  return (
    <div className={'fullWidthContent text-center ' + cssClasses}>
      {children}
      <br />
      <img src={imagePath}></img>
    </div>
  );
}

export default FullWidthContent;
