function IconDocumentDone({ color, cssClasses, scale }) {
  return (
    <svg id="Gruppe_16193" data-name="Gruppe 16193" xmlns="http://www.w3.org/2000/svg" width="41.126" height="47.9" viewBox="0 0 41.126 47.9">
      <g id="Gruppe_16195" data-name="Gruppe 16195">
        <path
          id="Pfad_10431"
          data-name="Pfad 10431"
          d="M40.7,17.6,23.5.5A1.656,1.656,0,0,0,22.3,0H5.2A5.143,5.143,0,0,0,0,5.1V42.8a5.121,5.121,0,0,0,5.1,5.1H36a5.121,5.121,0,0,0,5.1-5.1v-24a1.469,1.469,0,0,0-.4-1.2m-3,25.3A1.752,1.752,0,0,1,36,44.6H5.2a1.752,1.752,0,0,1-1.7-1.7V5.1A1.752,1.752,0,0,1,5.2,3.4H21.6L37.7,19.5Z"
          fill={color ? color : '#2870ed'}
        />
        <path
          id="Pfad_10432"
          data-name="Pfad 10432"
          d="M13,27.7a1.733,1.733,0,0,0-1.9,2.9L16.2,34a1.689,1.689,0,0,0,1,.3h.4a1.779,1.779,0,0,0,1.1-.8l8.6-13.7a1.733,1.733,0,0,0-2.9-1.9L16.8,30.1Z"
          fill={color ? color : '#2870ed'}
        />
      </g>
    </svg>
  );
}

export default IconDocumentDone;
