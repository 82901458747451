function IconArrowRight({ color, cssClasses, width, height, handleClick }) {
  return (
    <svg
      className={cssClasses}
      onClick={handleClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9.392 16.632"
    >
      <g transform="translate(1.414 1.414)">
        <path
          d="M0,13.8,6.978,6.9,0,0"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default IconArrowRight;
