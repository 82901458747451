import httpClient from './index';

const END_POINT = '/Document';

const downloadDocument = async (fileId) => {
  try {
    const resp = await httpClient.get(`${END_POINT}/download_full/${fileId}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const downloadMarktberichtUrl = (fileId) => {
  return `${httpClient.defaults.baseURL}${END_POINT}/download_full/${fileId}`;
};

export { downloadDocument, downloadMarktberichtUrl };
