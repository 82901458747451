function IconMailOutlined({ cssClasses, color }) {
  return (
    <svg className={cssClasses} id="Gruppe_15979" data-name="Gruppe 15979" xmlns="http://www.w3.org/2000/svg" width="39.968" height="32.79" viewBox="0 0 39.968 32.79">
      <g id="Gruppe_15979-2" data-name="Gruppe 15979">
        <path
          id="Pfad_10365"
          data-name="Pfad 10365"
          d="M35.793,0H4.255A4.273,4.273,0,0,0,0,4.255V28.534A4.273,4.273,0,0,0,4.255,32.79H35.71a4.273,4.273,0,0,0,4.255-4.255V4.255A4.15,4.15,0,0,0,35.793,0M4.255,2.837H35.71a1.462,1.462,0,0,1,1.418,1.418v.083L20.942,18.189a1.5,1.5,0,0,1-1.836,0L2.837,4.339V4.255A1.462,1.462,0,0,1,4.255,2.837m31.538,27.2H4.255a1.462,1.462,0,0,1-1.418-1.418V8.093L17.271,20.358a4.286,4.286,0,0,0,5.507,0L37.212,8.093V28.618a1.462,1.462,0,0,1-1.418,1.418"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconMailOutlined;
