function IconInformation({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15693" xmlns="http://www.w3.org/2000/svg" width="19.811" height="19.811" viewBox="0 0 19.811 19.811">
      <g data-name="Gruppe 15694">
        <path data-name="Pfad 8975" d="M9.905,0a9.905,9.905,0,1,0,9.905,9.905A9.934,9.934,0,0,0,9.905,0m0,18.407a8.5,8.5,0,1,1,8.5-8.5,8.543,8.543,0,0,1-8.5,8.5" fill={color} />
        <path
          data-name="Pfad 8976"
          d="M22.111,19.5h-1.4V15.253a.723.723,0,0,0-.7-.7H18.6a.7.7,0,0,0,0,1.4h.7V19.5H17.9a.7.7,0,1,0,0,1.4h4.251a.723.723,0,0,0,.7-.7.732.732,0,0,0-.743-.7m-2.1-6.4a1.4,1.4,0,1,0-1.4-1.4,1.392,1.392,0,0,0,1.4,1.4"
          transform="translate(-10.101 -6.049)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconInformation;
