function IconLocationWithPin({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="42"
      height="43"
      viewBox="0 0 42 43"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_3039"
            data-name="Rechteck 3039"
            width="42"
            height="43"
            transform="translate(0.395 0.458)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g data-name="Gruppe 6441" transform="translate(-0.395 -0.458)" clipPath="url(#clip-path)">
        <path
          data-name="Pfad 6364"
          d="M17.149,22.727a2.745,2.745,0,0,1-5.489,0V12.537L8,13.353v22L22.638,32.1v-22l-5.489,1.22Z"
          transform="translate(6.076 8.943)"
          fill={color}
        />
        <path
          data-name="Pfad 6365"
          d="M29.144,13.592,17,10.123V32.154L28.643,35.48a.916.916,0,0,0,1.166-.88V14.472a.917.917,0,0,0-.664-.88"
          transform="translate(13.544 8.963)"
          fill={color}
        />
        <path
          data-name="Pfad 6366"
          d="M.362,10.187A.909.909,0,0,0,0,10.915V31.043a.915.915,0,0,0,.662.88l12.146,3.469V13.361L1.164,10.035a.914.914,0,0,0-.8.152"
          transform="translate(-0.563 8.861)"
          fill={color}
        />
        <path
          data-name="Pfad 6367"
          d="M13.489,12.735V31.106a.915.915,0,1,0,1.83,0V12.735a6.4,6.4,0,1,0-1.83,0M14.4,3.66A2.747,2.747,0,0,1,17.149,6.4a.915.915,0,1,1-1.83,0,.915.915,0,0,0-.915-.915.915.915,0,1,1,0-1.83"
          transform="translate(6.076 0.563)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconLocationWithPin;
