import InfoDialog from './InfoDialog';
function InfoDialogHotlineKontakt({ open, handleClose }) {
  return (
    <InfoDialog open={open} handleClose={handleClose} headline="Hotline & Kontakt">
      <p>
        Wir beraten Sie gern individuell zu Ihrem Anliegen. Sie erreichen uns direkt vor Ort, über
        unsere Hotline oder via Mail:
      </p>
      <h4 className="mt-200rem">s REAL Immobilienvermittlung GmbH</h4>
      <span className="lh-160">
        Landstraßer Hauptstraße 60, 1030 Wien
        <br />
        Tel.:{' '}
        <a
          className="textlink"
          href="tel:05010026200"
          title="s REAL anrufen"
          target="_blank"
          rel="noreferrer"
        >
          05 0100 - 26200
        </a>
        <br />
        Mail:{' '}
        <a
          className="textlink"
          href="mailto:kundenbetreuung@sreal.at"
          title="E-Mail an s REAL"
          target="_blank"
          rel="noreferrer"
        >
          kundenbetreuung@sreal.at
        </a>
      </span>
    </InfoDialog>
  );
}

export default InfoDialogHotlineKontakt;
