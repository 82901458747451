import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setFormIsLoading } from '../../reducers/app';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import PageDialog from '../Dialogs/PageDialog';
import FormCheckbox from '../FormFields/FormCheckbox';
import FormInput from '../FormFields/FormInput';
import LoadingSpinner from '../LoadingSpinner';
import { ROLE_ABGEBER_NAME } from '../../constants';
import { getJustImmoContactsWithCategory } from '../../util/generalUtils';
import { createSurveyEmpfehlung } from '../../api/Contact';
import { handleScrollToErrorPageDialog } from '../../util/generalUtils';

function PageDialogEmpfehlung({ open, handleClose }) {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const isAbgeber = getJustImmoContactsWithCategory(user.userObject, ROLE_ABGEBER_NAME);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const [sreal, setSreal] = useState('');
  const [suchmaschine, setSuchmaschine] = useState('');
  const [bekannter, setBekannter] = useState(false);
  const [srealKunde, setSrealKunde] = useState(false);
  const [name, setName] = useState('');
  const [socialMedia, setSocialMedia] = useState(false);
  const [onlineWerbung, setOnlineWerbung] = useState(false);
  const [plakat, setPlakat] = useState(false);
  const [veranstaltung, setVeranstaltung] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [sonstiges, setSonstiges] = useState(false);

  const pageDialogId = 'PageDialogEmpfehlung';
  const formType = 'empfehlung';

  useEffect(() => {
    if (open) {
      // reset data
      setSreal(false);
      setSuchmaschine(false);
      setBekannter(false);
      setSrealKunde(false);
      setSocialMedia(false);
      setOnlineWerbung(false);
      setPlakat(false);
      setVeranstaltung(false);
      setNewsletter(false);
      setSonstiges(false);
      setName('');
      setError('');
    }
  }, [open]);

  const forwardAction = async () => {
    if (app.formIsLoading) {
      return;
    }
    if (
      sreal === false &&
      suchmaschine === false &&
      bekannter === false &&
      srealKunde === false &&
      socialMedia === false &&
      onlineWerbung === false &&
      plakat === false &&
      veranstaltung === false &&
      newsletter === false &&
      sonstiges === false
    ) {
      setError('Bitte etwas auswählen.');
      handleScrollToErrorPageDialog(formType, '#' + pageDialogId);
    } else if (isAbgeber && srealKunde === true && name === '') {
      setError('Bitte Name eingeben.');
      handleScrollToErrorPageDialog(formType, '#' + pageDialogId);
    } else {
      dispatch(setFormIsLoading(true));
      try {
        let requestBody = {
          sreal,
          suchmaschine,
          bekannter,
          srealKunde,
          name,
          socialMedia,
          onlineWerbung,
          plakat,
          veranstaltung,
          newsletter,
          sonstiges,
        };
        await createSurveyEmpfehlung(requestBody);

        localStorage.setItem('mysreal-recommendation-isset', true);
        handleClose();
      } catch (error) {
        console.error(error);
        //handle error
      } finally {
        dispatch(setFormIsLoading(false));
      }
    }
  };

  return (
    <PageDialog id={pageDialogId} open={open} headline={'Haben Sie eine Minute?'} handleClose={handleClose}>
      <div className="p-100rem">
        <h3>Wie wurden Sie auf my-sreal.at aufmerksam?</h3>
        <p>Sie helfen uns mit Ihrer Antwort, unseren Service zu verbessern. Vielen Dank!</p>
      </div>
      <div className="p-100rem">
        <FormCheckbox label={'s REAL/Erste Bank/Sparkassen Mitarbeiter:in'} required={false} formType={formType} value={sreal} callback={(value) => setSreal(value)} />
        <FormCheckbox label={'Suchmaschine'} required={false} formType={formType} value={suchmaschine} callback={(value) => setSuchmaschine(value)} />

        <FormCheckbox label={'Empfehlung einer Bekannten'} required={false} formType={formType} value={bekannter} callback={(value) => setBekannter(value)} />

        {isAbgeber && <FormCheckbox label={'Empfehlung durch eine s REAL Kund:in'} required={false} formType={formType} value={srealKunde} callback={(value) => setSrealKunde(value)} />}
        {isAbgeber && srealKunde === true && (
          <>
            <FormInput label={'Name'} required={true} value={name} callback={(value) => setName(value)} />
            <span className="form--info">
              * Pflichtfeld; Wir belohnen die Empfehlung von Kund:innen mit einer Prämie. Nennen Sie uns den Namen der s REAL Kund:in und wir nehmen mit ihr Kontakt auf für die Prämienauszahlung.
            </span>
          </>
        )}

        <FormCheckbox label={'Social Media'} required={false} formType={formType} value={socialMedia} callback={(value) => setSocialMedia(value)} />

        <FormCheckbox label={'Online-Werbung'} required={false} formType={formType} value={onlineWerbung} callback={(value) => setOnlineWerbung(value)} />

        <FormCheckbox label={'Plakate, Zeitungsanzeigen'} required={false} formType={formType} value={plakat} callback={(value) => setPlakat(value)} />

        <FormCheckbox label={'Veranstaltungen'} required={false} formType={formType} value={veranstaltung} callback={(value) => setVeranstaltung(value)} />

        <FormCheckbox label={'Newsletter'} required={false} formType={formType} value={newsletter} callback={(value) => setNewsletter(value)} />

        <FormCheckbox label={'Sonstiges'} required={false} formType={formType} value={sonstiges} callback={(value) => setSonstiges(value)} />

        <LoadingSpinner />

        <div className="button-panel">
          <ButtonForwardAction formType={formType} buttonText={'Senden'} forwardAction={forwardAction} />
        </div>
        {error && (
          <p id={formType + '-error'} className="form__fielderror">
            {error}
          </p>
        )}
      </div>
    </PageDialog>
  );
}

export default PageDialogEmpfehlung;
