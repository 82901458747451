function IconChat({ color, cssClasses, fillColor }) {
  return (
    <svg className={cssClasses} id="Gruppe_15412" data-name="Gruppe 15412" xmlns="http://www.w3.org/2000/svg" width="48.008" height="47.969" viewBox="0 0 48.008 47.969">
      <g id="Gruppe_15413" data-name="Gruppe 15413">
        <path id="Pfad_8794" data-name="Pfad 8794" d="M45.8,39.969a14.989,14.989,0,0,0,2.2-8.3,16.3,16.3,0,0,0-16.3-16.3,17.148,17.148,0,0,0-7.1,1.6,16.286,16.286,0,0,0,13.8,29.5l7.6,1.5h.3a1.612,1.612,0,0,0,1.3-.6,1.747,1.747,0,0,0,.3-1.7Zm-3.6.4,1.4,3.7-5.2-.9a2.079,2.079,0,0,0-1.1.1,12.836,12.836,0,1,1-11.2-23.1,12.123,12.123,0,0,1,5.6-1.3,12.935,12.935,0,0,1,10.8,20,1.625,1.625,0,0,0-.3,1.5" fill="#fff"/>
        <path id="Pfad_8795" data-name="Pfad 8795" d="M8.7,34.669l-4.3.8,2-5.2a1.839,1.839,0,0,0-.2-1.6,16.107,16.107,0,0,1-2.7-9,16.308,16.308,0,0,1,18.2-16.2,15.888,15.888,0,0,1,10.8,6.1,1.71,1.71,0,1,0,2.7-2.1A19.792,19.792,0,0,0,22,.169a19.549,19.549,0,0,0-22,19.5,20.313,20.313,0,0,0,2.8,10.2l-2.7,7.2a1.747,1.747,0,0,0,.3,1.7,1.612,1.612,0,0,0,1.3.6H2l7.3-1.3a1.769,1.769,0,0,0,1.4-2,1.856,1.856,0,0,0-2-1.4" fill="#fff"/>
      </g>
    </svg>

  );
}

export default IconChat;
