function IconArrowLeft({ color, cssClasses, width, height, handleClick }) {
  return (
    <svg
      className={cssClasses}
      onClick={handleClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13.677 21.94"
    >
      <path
        id="Pfad_1121"
        data-name="Pfad 1121"
        d="M456.128,986.4l10.06-8.853-10.06-8.853"
        transform="translate(467.688 988.516) rotate(180)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
}

export default IconArrowLeft;
