import { useDispatch, useSelector } from 'react-redux';

import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormInput from '../../FormFields/FormInput';
import FormSelect from '../../FormFields/FormSelect';

import { exteriorSurfacesOptionsJustImmo, immobilienartOptionsJustImmo } from '../../FormFields/util/formOptions';

import { validateFields, validateFieldsMinMax } from '../../FormFields/util/validateFields';

// STORE
import { useState } from 'react';
import { renameSearchProfile, updateSearchProfile } from '../../../api/JustImmo';
import { SEARCHPROFILE_SITEINDEX_EDIT, SEARCHPROFILE_SITEINDEX_START, SEARCHPROFILE_SITEINDEX_USERDATA, JUSTIMMO_IMMOBILIENART_WOHNUNG, JUSTIMMO_IMMOBILIENART_HAUS } from '../../../constants';
import { setFormIsLoading } from '../../../reducers/app';
import { setPageDialogOpenSPEdit, setResetFieldsSPForm, setSiteIndexSPForm } from '../../../reducers/searchProfileForm';
import LoadingSpinner from '../../LoadingSpinner';

import { tryUpdateUserResult, updateSearchProfileInUser } from '../../../reducers/user';
import { handleScrollToErrorPageDialog } from '../../../util/generalUtils';
import FormInputMultiselect from '../../FormFields/FormInputMultiselect';
import { fetchErrorText } from '../../FormFields/util/formTexts';
import statesWithZips from '../../FormFields/util/statesWithZips';
import { useFetchSearchprofiles } from '../../../hooks/searchprofiles/useFetchSearchprofiles';
import { setCachedSimilarImmos, setLastCachedDate } from '../../../reducers/cachedSimilarImmos';

function SearchData({ formType, id, searchProfileToEditId = null }) {
  const currentSearchProfileForm = useSelector((state) => state.searchProfileForm);
  const currentFormValidators = useSelector((state) => state.formValidators);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const { getCriteriaFromRequestbody } = useFetchSearchprofiles();

  const isApartmentOrHouse =
    currentSearchProfileForm.requestBody.type_ids[0]?.id === JUSTIMMO_IMMOBILIENART_WOHNUNG || currentSearchProfileForm.requestBody.type_ids[0]?.id === JUSTIMMO_IMMOBILIENART_HAUS;

  document.addEventListener('wheel', function (event) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

  const forwardAction = async () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }

    let federalStateVal = '';
    if (currentSearchProfileForm.requestBody?.location?.location_ids[0]?.id) {
      federalStateVal = currentSearchProfileForm.requestBody.location.location_ids[0].id;
    }

    let estateTypeVal = '';
    if (currentSearchProfileForm.requestBody.type_ids[0]) {
      estateTypeVal = currentSearchProfileForm.requestBody.type_ids[0];
    }

    let validationResult = validateFields(
      {
        searchProfileName: currentSearchProfileForm.name,
        federalState: federalStateVal,
        estateType: estateTypeVal,
        priceFrom: currentSearchProfileForm.requestBody.price.from,
        priceTo: currentSearchProfileForm.requestBody.price.to,
        areaFrom: isApartmentOrHouse ? currentSearchProfileForm.requestBody.living_area.from : currentSearchProfileForm.requestBody.site_area.from,
        areaTo: isApartmentOrHouse ? currentSearchProfileForm.requestBody.living_area.to : currentSearchProfileForm.requestBody.site_area.to,
      },
      dispatch,
    );

    let validatePrice = validateFieldsMinMax(currentSearchProfileForm.requestBody.price.from, currentSearchProfileForm.requestBody.price.to);
    let validateFloorArea = validateFieldsMinMax(currentSearchProfileForm.requestBody.living_area.from, currentSearchProfileForm.requestBody.living_area.to);
    let validateSiteArea = validateFieldsMinMax(currentSearchProfileForm.requestBody.site_area.from, currentSearchProfileForm.requestBody.site_area.to);

    if (validationResult && validatePrice && validateFloorArea && validateSiteArea) {
      if (currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_START) {
        dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_USERDATA));
      }
      if (currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_EDIT) {
        // edit searchprofile
        dispatch(setFormIsLoading(true));

        let request = getCriteriaFromRequestbody(currentSearchProfileForm.requestBody);

        try {
          await renameSearchProfile(searchProfileToEditId, currentSearchProfileForm.name);
          await updateSearchProfile(searchProfileToEditId, request);

          dispatch(updateSearchProfileInUser({ id: searchProfileToEditId, name: currentSearchProfileForm.name, criteria: request }));
          dispatch(tryUpdateUserResult({ id: searchProfileToEditId, name: currentSearchProfileForm.name }));

          // reset cached similar immos date damit neu gefetcht wird
          dispatch(setLastCachedDate(null));
          dispatch(setCachedSimilarImmos([]));

          dispatch(setFormIsLoading(false));
          dispatch(setPageDialogOpenSPEdit(false));
          dispatch(setResetFieldsSPForm());
        } catch (e) {
          console.error('e', e);
          dispatch(setFormIsLoading(false));
          setError(fetchErrorText);
          handleScrollToErrorPageDialog(formType, '#' + id);
        }
      }
    } else {
      setError('Bitte kontrollieren Sie Ihre Eingaben.');
      if (currentSearchProfileForm.name === '') {
        handleScrollToErrorPageDialog(formType, '#' + id, '#' + formType + '-errorname');
      }
    }
  };

  return (
    <>
      {currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_START && (
        <>
          <h2>Suchprofil</h2>
          <p>Erstellen Sie jetzt ein Suchprofil, melden Sie sich für die s REAL Vormerkung an und finden Sie Ihre Wunschimmobilie!</p>
        </>
      )}

      <h3 id={formType + '-errorname'} className="form__title pt-100rem">
        Suchprofil benennen
      </h3>

      <div>
        <FormInput
          label={'Name'}
          required={true}
          id={'inputName'}
          value={currentSearchProfileForm.name}
          formType={formType}
          placeholder={'Suchprofil 1'}
          error={currentFormValidators.searchProfileNameError}
        />

        <p className="font-80 m-0">Maximal 25 Zeichen. Sie können den Namen später unter Einstellungen ändern.</p>
      </div>

      <h3 className="form__title pt-100rem">Suchprofildaten</h3>
      <FormSelect
        label={'Bundesland'}
        placeholder="Bitte wählen"
        value={currentSearchProfileForm.requestBody.location.location_ids[0] || ''}
        formType={formType}
        id={'inputFederalState'}
        options={statesWithZips.map((state) => {
          return {
            id: state.justImmoId,
            text: state.label,
          };
        })}
        required={true}
        error={currentFormValidators.federalStateError}
      />

      <div>
        <FormInputMultiselect
          label="Bezirk/PLZ"
          placeholder={'Bezirk eingeben'}
          formType={formType}
          value={currentSearchProfileForm.displayedDistrictName}
          id="inputDistrict"
          error={currentFormValidators.districtError}
          labelName="text"
          valueName="id"
        />
      </div>

      <FormSelect
        label={'Immobilienart'}
        formType={formType}
        id={'inputEstateType'}
        value={currentSearchProfileForm.requestBody.type_ids[0]}
        required={true}
        error={currentFormValidators.estateTypeError}
        options={immobilienartOptionsJustImmo}
      />

      <FormInput
        id="inputPriceFrom"
        label="Preis von"
        type={'number'}
        minValue={0}
        maxValue={currentSearchProfileForm.requestBody.price.to}
        placeholder="Wert eingeben"
        formType={formType}
        value={currentSearchProfileForm.requestBody.price.from}
        error={currentFormValidators.priceFromError}
      />

      <FormInput
        id="inputPriceTo"
        label="Preis bis"
        type={'number'}
        minValue={currentSearchProfileForm.requestBody.price?.from > 0 ? currentSearchProfileForm.requestBody.price.from : 0}
        placeholder="Wert eingeben"
        formType={formType}
        value={currentSearchProfileForm.requestBody.price.to}
        error={currentFormValidators.priceToError}
      />

      {isApartmentOrHouse ? (
        <>
          <FormInput
            id="inputLivingAreaFrom"
            label="Fläche von (in m²)"
            type={'number'}
            minValue={0}
            maxValue={currentSearchProfileForm.requestBody.living_area.to}
            placeholder="Wert eingeben"
            formType={formType}
            value={currentSearchProfileForm.requestBody.living_area.from}
            error={currentFormValidators.areaFromError}
          />

          <FormInput
            id="inputLivingAreaTo"
            label="Fläche bis (in m²)"
            type={'number'}
            minValue={currentSearchProfileForm.requestBody.living_area?.from > 0 ? currentSearchProfileForm.requestBody.living_area.from : 0}
            placeholder="Wert eingeben"
            formType={formType}
            value={currentSearchProfileForm.requestBody.living_area.to}
            error={currentFormValidators.areaToError}
          />
        </>
      ) : (
        <>
          <FormInput
            id="inputSiteAreaFrom"
            label="Fläche von (in m²)"
            type={'number'}
            minValue={0}
            maxValue={currentSearchProfileForm.requestBody.site_area.to}
            placeholder="Wert eingeben"
            formType={formType}
            value={currentSearchProfileForm.requestBody.site_area.from}
            error={currentFormValidators.areaFromError}
          />

          <FormInput
            id="inputSiteAreaTo"
            label="Fläche bis (in m²)"
            type={'number'}
            minValue={currentSearchProfileForm.requestBody.site_area?.from > 0 ? currentSearchProfileForm.requestBody.site_area.from : 0}
            placeholder="Wert eingeben"
            formType={formType}
            value={currentSearchProfileForm.requestBody.site_area.to}
            error={currentFormValidators.areaToError}
          />
        </>
      )}

      {isApartmentOrHouse && (
        <>
          <FormInput
            id="inputRoomsFrom"
            label="Anzahl Räume (mindestens)"
            type={'number'}
            minValue={0}
            placeholder="Wert eingeben"
            formType={formType}
            value={currentSearchProfileForm.requestBody.rooms.from}
            error={currentFormValidators.roomsFromError}
          />

          <FormSelect
            label={'Außenflächen'}
            formType={formType}
            id={'inputExteriorSurface'}
            value={currentSearchProfileForm.exteriorSurfaceSelection}
            required={false}
            error={currentFormValidators.exteriorSurfaceError}
            options={exteriorSurfacesOptionsJustImmo}
            multiple={true}
          />
        </>
      )}

      {/* <FormSelect
        id={'inputFeatures'}
        label={'Ausstattung'}
        value={currentSearchProfileForm.requestBody.furnishing_ids}
        formType={formType}
        options={furnishingOptions}
        multiple={true}
      /> */}

      {/*<FormInput
        id="inputFullText"
        label="Sonstiges"
        placeholder="Wert eingeben"
        formType={formType}
        value={currentSearchProfileForm.fullText}
      />*/}

      <LoadingSpinner />
      <div className="button-panel">
        <ButtonForwardAction
          formType={formType}
          forwardAction={forwardAction}
          buttonText={currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_EDIT ? 'Speichern' : 'Weiter'}
          buttonStyle={'blue'}
        />
        {error && (
          <p id={formType + '-error'} className="form__fielderror">
            {error}
          </p>
        )}
      </div>
    </>
  );
}

export default SearchData;
