function IconSocialIN({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      data-name="Ebene 2"
      xmlns="http://www.w3.org/2000/svg"
      width="20.65"
      height="20.66"
      viewBox="0 0 20.65 20.66"
    >
      <g data-name="OBJECTS">
        <g>
          <g>
            <rect className="d" fill={color} x=".41" y="6.89" width="4.26" height="13.77" />
            <path
              className="d"
              fill={color}
              d="M2.52,5.08c1.39,0,2.52-1.14,2.52-2.54S3.91,0,2.52,0,0,1.14,0,2.54s1.13,2.54,2.52,2.54Z"
            />
          </g>
          <path
            className="d"
            fill={color}
            d="M11.49,13.43c0-1.94,.89-3.09,2.6-3.09,1.57,0,2.32,1.11,2.32,3.09v7.23h4.24V11.94c0-3.69-2.09-5.47-5.01-5.47s-4.15,2.28-4.15,2.28v-1.86H7.4v13.77h4.09v-7.23Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default IconSocialIN;
