import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPageDialogOpenSPList } from '../reducers/searchProfileForm';

function LinkToSearchProfiles({ linkText, cssClasses }) {
  const dispatch = useDispatch();
  return (
    <Link
      onClick={(e) => {
        dispatch(setPageDialogOpenSPList(true));
      }}
      className={'textlink ' + cssClasses}
      title={linkText}
    >
      {linkText}
    </Link>
  );
}

export default LinkToSearchProfiles;
