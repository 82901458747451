import '../../styles/progressbar.scss';

function ProgressBarSingle({ amount, total, cssClasses, percent = 0 }) {
  if ((!total || !amount) && percent === 0) {
    return null;
  } else {
    let bar = (amount * 100) / total;
    if(percent > 0) {
      bar = percent;
    }

    cssClasses += ' progressbar__bar';
    return (
      <div className={cssClasses}>
        <div
          className="progressbar__bar--filled"
          style={{ width: bar + '%' }}
        ></div>
      </div>
    );
  }
}

export default ProgressBarSingle;
