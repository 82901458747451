//*React
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//*Images
import headerImageMobile from '../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0003_s-REAL-Sujets-2023-Webhero-16zu9-1.13.jpg';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.3.jpg';
//*Components
import ProductHighlightsVerkaufImg1 from '../assets/images/producthighlights-img-verkauf-1-Statistik.png';
import ProductHighlightsVerkaufImg1Mobile from '../assets/images/producthighlights-img-verkauf-1-Statistik-mobile.png';
import ProductHighlightsVerkaufImg2 from '../assets/images/producthighlights-img-verkauf-2-Updates.png';
import ProductHighlightsVerkaufImg3 from '../assets/images/producthighlights-img-verkauf-3-News.png';
import ProductHighlightsVerkaufImg4 from '../assets/images/producthighlights-img-verkauf-4-Immodrive.png';
import ProductHighlightsVerkaufImg4Mobile from '../assets/images/LaptopMOBILE.png';
import ContactFormCard from '../components/Cards/ContactFormCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import WohnwertLightCard from '../components/Cards/WohnwertLightCard';
import WohnwertProCard from '../components/Cards/WohnwertProCard';
import ProductHighlights from '../components/ContentBlocks/ProductHighlights';
import Header from '../components/Header/Header';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import IconWithRegistration from '../components/Icons/IconWithRegistration';
import IconWithoutRegistration from '../components/Icons/IconWithoutRegistration';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import TableComparison from '../components/TableComparison';
import Quicklinks from '../components/Quicklinks';
import { setMenuType } from '../reducers/app';
import { MENU_VERKAUF } from '../constants';
//*Utils
import { anliegenOptions } from '../components/FormFields/util/formOptions';
//*Constants
import { CONTACTFORM_ANLIEGEN_VERKAUF } from '../constants';
//*Reducers

import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { setPageDialogOpenContactForm, setReasonContactForm } from '../reducers/contactInformation';
import { setPageDialogOpenRegisterForm } from '../reducers/registerForm';
import GutachtenCard from '../components/Cards/GutachtenCard';
import MarktberichtCard from '../components/Cards/MarktberichtCard';

function Verkaeufer() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const { isMobile } = useHandleScreenResize();

  const navigate = useNavigate();

  const goToDashboard = () => {
    dispatch(setMenuType(MENU_VERKAUF));
    navigate('../' + app.root + '/dashboard');
  };

  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      {!user.isLoggedIn ? (
        <button className="button button--orange ml-0" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
          Jetzt registrieren
        </button>
      ) : (
        <button className="button button--orange ml-0" onClick={() => goToDashboard()}>
          Dashboard anzeigen
        </button>
      )}
    </HeaderCtaButtons>,
  ];

  return (
    <>
      <MetaTags
        title={'Immobilie verkaufen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={'Sie wollen Ihre Immobilie verkaufen? my-sreal.at ist die Online-Plattform mit Mehrwert für Ihren Immobilienverkauf. Einfach, transparent und digital kaufen.'}
        imageName={'mysreal_hero_vk.jpg'}
      />
      {/* Slider Header - Blue BG */}
      <Header cssClasses="header--default header--withimage header--immosuche">
        {!isMobile ? (
          <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        ) : (
          <img src={headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        )}
        <div className="header__content-wrapper">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">
              Das moderne <br />
              Kundenportal für Ihren <br />
              Immobilienverkauf
            </h1>
            {headerCtabuttons}
          </div>
        </div>
      </Header>
      <Quicklinks
        list={[
          { label: 'Mehr Service für Ihren Verkauf', id: 'service' },
          { label: 'Marktanalyse', id: 'marktanalyse' },
          { label: 'Die Vorteile von my-sreal', id: 'vorteile' },
          { label: 'Kontakt', id: 'kontakt' },
        ]}
      />
      <MainContent>
        <div className="w-desktop--center">
          <div id="service">
            <ProductHighlights
              fullWidth={true}
              mainHeadline="Mehr Service für Ihren Immobilienverkauf"
              mainSubline="Verkaufen Sie Ihre Immobilie über s REAL und profitieren Sie vom interaktiven Kundenportal my-sreal: mit transparenter, einfacher und digitaler Immobiliendienstleistung! Österreichweit arbeiten unsere Makler:innen daran, dass Sie rund um die Uhr alle Statistiken zu Ihrer Vermarktung in Ihrem Dashboard abrufen können. So behalten Sie vom Auftrag bis zur erfolgreichen Übergabe den Überblick."
              isMobile={isMobile}
              imagePath1={isMobile ? ProductHighlightsVerkaufImg1Mobile : ProductHighlightsVerkaufImg1}
              imagePath2={ProductHighlightsVerkaufImg2}
              imagePath3={ProductHighlightsVerkaufImg3}
              imagePath4={ProductHighlightsVerkaufImg4}
              imagePath4Mobile={ProductHighlightsVerkaufImg4Mobile}
              color3="background-darkteal"
              headline1="Statistiken – Alles zu Ihrer Immobilie im Überblick behalten"
              headline2="Laufend Updates im Aktuelles-Board"
              headline3="News und Trends am Immobilienmarkt"
              headline4="immo-drive - Alle Dokumente an einem Ort"
              button1="Mehr anzeigen"
              button2="Mehr anzeigen"
              button3="Mehr anzeigen"
              button4="Mehr anzeigen"
              buttonlink1="/immobilie-verkaufen#info-statistiken"
              buttonlink2="/immobilie-verkaufen#aktuelles-board"
              buttonlink3="/news"
              buttonlink4="/immobilie-verkaufen#dokumente"
            />
          </div>
        </div>

        <div className="w-desktop--center" id="marktanalyse">
          <h2 className="text-center">Was ist Ihre Immobilie wert?</h2>
          <p className="mb-200rem text-center">
            Sie wollen eine erste Einschätzung zum Wert Ihrer Immobilie? Dann nutzen Sie den Wohnwert Light Rechner. Mit Wohnwert Pro bekommen Sie eine direkte Einschätzung des Werts durch eine
            unserer Makler:innen. Oder Sie beauftragen ein professionelles Immobiliengutachten.
          </p>
          <div className={'elements3cols'}>
            <WohnwertLightCard cssClasses={'elements3cols__col'} />
            <WohnwertProCard cssClasses={'elements3cols__col'} />
            <GutachtenCard cssClasses={'elements3cols__col'} />
          </div>

          {/* <div>
            <MarktberichtCard />
          </div> */}
        </div>

        <div className="gray-background" id="vorteile">
          <div className="w-desktop--center">
            <div id="vorteile">
              <h2 className="text-center">Die passende Vermarktung für Sie</h2>
            </div>
            <p className="mb-200rem text-center">
              Ein Immobilienverkauf über s REAL ist eine gute Entscheidung – dafür sprechen 45 Jahre Erfahrung und Tausende zufriedene Kund:innen. Als Nutzer:in von my-sreal profitieren Sie außerdem
              von vielen exklusiven Funktionen. Transparent, sicher und serviceorientiert. Das Beste: Die Registrierung ist völlig kostenlos.{' '}
            </p>
          </div>

          <TableComparison
            showMoreButton={false}
            cssClasses={`${isMobile ? 'table-mobile' : 'w-desktop--center'}`}
            headlines={[
              { headline: 'my-sreal.at ', headlineBold: 'ohne Registrierung', icon: <IconWithoutRegistration color={'#7598ab'} /> },
              { headline: 'my-sreal.at ', headlineBold: 'mit kostenloser Registrierung', icon: <IconWithRegistration color={'#2870ed'} /> },
            ]}
            listData={[
              { label: 'Ihre Immobilienvermarktung', isSubline: true, groupid: 1 },
              { label: 'Persönliche Maklerbetreuung', value1: true, value2: true, groupid: 1 },
              { label: 'Vermarktungsablauf im Dashboard', value1: false, value2: true, groupid: 1 },
              { label: 'Statistiken über Anfragesituation', value1: false, value2: true, groupid: 1 },
              // { label: '', isShowMoreButton: true, groupid: 1 },
              { label: 'Ihr Immobilienverkauf', isSubline: true, groupid: 2 },
              { label: 'Übergabe- und Kaufvertragsunterstützung', value1: true, value2: true, groupid: 2 },
              { label: 'Abgesichert durch Maklerhaftung', value1: true, value2: true, groupid: 2 },
              { label: 'Dokumentenbibliothek immo-drive', value1: false, value2: true, groupid: 2 },
              { label: 'Nachbetreuung mit Vorteilswelt', value1: false, value2: true, groupid: 2 },
              // { label: '', isShowMoreButton: true, groupid: 2 },
            ]}
          >
            <div className={`${!isMobile ? '' : 'pr-200rem pb-100rem'}`}>
              <p className={'mt-200rem mb-200rem'}>
                Kostenhinweis: Erst im Falle der erfolgreichen Vermittlung einer Immobilie fällt ein vorab im Vermittlungsauftrag vereinbartes Vermittlungshonorar entsprechend des österreichischen
                Maklergesetzes an. Die Nutzung des my-sreal Kundenportals führt zu keinen zusätzlichen Kosten für die Kund:in
              </p>
              {!user.isLoggedIn && (
                <>
                  {isMobile ? (
                    <button className={`button button--orange button--tablecomparison mt-100rem mb-200rem`} onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
                      Jetzt kostenlos registrieren
                    </button>
                  ) : (
                    <div className="tableRowContent tableRowContent--comparison">
                      <div className="tableColumn"></div>
                      <div className="tableColumn"></div>
                      <div className="tableColumn">
                        <button className="button button--orange button--tablecomparison mt-100rem mb-200rem" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
                          Jetzt kostenlos registrieren
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </TableComparison>
        </div>

        <div className="w-desktop--center mt-200rem">
          <MarktinfoLightCard cssClasses={'bg-white'} />
        </div>

        <div className="w-desktop--center" id="kontakt">
          <ContactFormCard />
        </div>
      </MainContent>
    </>
  );
}

export default Verkaeufer;
