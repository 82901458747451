import PageDialog from './PageDialog';
import BesichtigungenContent from '../ContentBlocks/BesichtigungenContent';

function PageDialogBesichtigungen({ open, handleClose }) {
  const pageDialogId = 'PageDialogBesichtigungen';
  return (
    <PageDialog
      id={pageDialogId}
      open={open}
      handleClose={handleClose}
      showBackArrow={false}
      headline={'Besichtigungen'}
    >
      <div className='p-100rem'>
        <BesichtigungenContent containerSelector={'#' + pageDialogId} />
      </div>
    </PageDialog>
  );
}

export default PageDialogBesichtigungen;
