import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  street: '',
  number: '',
  zip: '',
  city: '',
  isOwner: false,
  previousMarktBericht: null,
};

export const marktBerichtSlice = createSlice({
  name: 'marktBericht',
  initialState,
  reducers: {
    setMarktBerichtStreet: (state, action) => {
      state.street = action.payload;
    },
    setMarktBerichtNumber: (state, action) => {
      state.number = action.payload;
    },
    setMarktBerichtZip: (state, action) => {
      state.zip = action.payload;
    },
    setMarktBerichtCity: (state, action) => {
      state.city = action.payload;
    },
    setMarktBerichtIsOwner: (state, action) => {
      state.isOwner = action.payload;
    },
    setPreviousMarktBericht: (state, action) => {
      state.previousMarktBericht = action.payload;
    },
    clearMarktBericht: (state) => {
      state.street = '';
      state.number = '';
      state.zip = '';
      state.city = '';
      state.isOwner = false;
      state.previousMarktBericht = null;
    },
  },
});

export const { setMarktBerichtStreet, setMarktBerichtNumber, setMarktBerichtZip, setMarktBerichtCity, setMarktBerichtIsOwner, clearMarktBericht, setPreviousMarktBericht } = marktBerichtSlice.actions;

export default marktBerichtSlice.reducer;
