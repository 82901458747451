import { createSlice } from '@reduxjs/toolkit';
import { findAndParsePhoneNumber, removeHashFromUrl, setPhoneNumber } from '../util/generalUtils';
import { OBJECTREQUESTFORM_SITEINDEX_START } from '../constants';

const initialState = {
  siteIndex: OBJECTREQUESTFORM_SITEINDEX_START,
  pageDialogOpen: false,
  headline: 'Objekt anfragen',
  requestBody: {
    realtyId: -1,
    tenantId: -1,
    gender: '',
    titleBefore: '',
    titleAfter: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    message: '',
    newsletterConfirmed: false,
    cancellationNoticeConfirmed: false,
  },
	immo: {},
  parsedPhoneNumber: '',
  phoneNumberPrefix: {id: 43, text: '+43 (Österreich)'},
};

export const objectRequestSlice = createSlice({
  name: 'objectRequest',
  initialState,
  reducers: {
    setSiteIndexObjectRequest: (state, action) => {
      window.location.hash = action.payload;
      state.siteIndex = action.payload;
    },
    setPageDialogOpenObjectRequest: (state, action) => {
      // check hash
      if (action.payload === true) {
        window.location.hash = state.siteIndex;
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setRealtyId: (state, action) => {
      state.requestBody.realtyId = action.payload;
    },
    setTenantId: (state, action) => {
      state.requestBody.tenantId = action.payload;
    },
    setGenderObjectRequest: (state, action) => {
      state.requestBody.gender = action.payload;
    },
    setTitleBeforeObjectRequest: (state, action) => {
      state.requestBody.titleBefore = action.payload;
    },
    setTitleAfterObjectRequest: (state, action) => {
      state.requestBody.titleAfter = action.payload;
    },
    setFirstNameObjectRequest: (state, action) => {
      state.requestBody.firstname = action.payload;
    },
    setLastNameObjectRequest: (state, action) => {
      state.requestBody.lastname = action.payload;
    },
    setEmailObjectRequest: (state, action) => {
      state.requestBody.email = action.payload;
    },
    setPhoneObjectRequest: (state, action) => {
      setPhoneNumber(state.requestBody, 'phone', state, action.payload);
    },
    setPhoneToUserPhoneObjectRequest: (state, action) => {
      state.requestBody.phone = action.payload;
    },
    setPhoneNumberPrefixObjectRequest: (state, action) => {
      state.phoneNumberPrefix = action.payload;
    },
		setImmoObjectRequest: (state, action) => {
      state.immo = action.payload;
    },
    setParsedPhoneNumberObjectRequest: (state, action) => {
      findAndParsePhoneNumber(state, 'parsedPhoneNumber', action.payload);
    },
    setMessageObjectRequest: (state, action) => {
      state.requestBody.message = action.payload;
    },
    setNewsletterConfirmedObjectRequest: (state, action) => {
      state.requestBody.newsletterConfirmed = action.payload;
    },
    setCancellationNoticeConfirmedObjectRequest: (state, action) => {
      state.requestBody.cancellationNoticeConfirmed = action.payload;
    },
    setResetFieldsObjectRequest: (state) => {
      return initialState;
    },
  },
});

export const { setSiteIndexObjectRequest, setPageDialogOpenObjectRequest, setRealtyId, setTenantId, setGenderObjectRequest, setTitleBeforeObjectRequest, setTitleAfterObjectRequest, setFirstNameObjectRequest, setLastNameObjectRequest, setEmailObjectRequest, setPhoneObjectRequest, setMessageObjectRequest, setNewsletterConfirmedObjectRequest, setCancellationNoticeConfirmedObjectRequest, setResetFieldsObjectRequest, setParsedPhoneNumberObjectRequest, setPhoneNumberPrefixObjectRequest,
setPhoneToUserPhoneObjectRequest, setImmoObjectRequest } = objectRequestSlice.actions;

export default objectRequestSlice.reducer;
