import { useState, useEffect } from 'react';

export const useHandleScreenResize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 950 && window.innerWidth < 1200);

  const handleScreenResize = () => {
    setIsMobile(window.innerWidth < 950);
    setIsTablet(window.innerWidth > 950 && window.innerWidth < 1200);
  };

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  });

  return { isMobile, isTablet };
};
