function IconNeubauprojekt({ cssClasses, color }) {
  return (
		<svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="15.041" height="17.447" viewBox="0 0 15.041 17.447">
		<g data-name="Gruppe 16697" transform="translate(-516 -694)">
			<g data-name="Gruppe 15560" transform="translate(516 694)">
				<g data-name="Gruppe 15561" transform="translate(0)">
					<g data-name="Rechteck 5412" fill={color} stroke="#fff" strokeLinejoin="round" strokeWidth="1.2">
						<rect width="15.041" height="6.618" stroke="none"/>
						<rect x="0.6" y="0.6" width="13.841" height="5.418" fill="none"/>
					</g>
					<path data-name="Pfad 8962" d="M0,2.382V0" transform="translate(7.52 3.309)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.2"/>
				</g>
			</g>
			<g data-name="Gruppe 15562" transform="translate(516 699.415)">
				<g data-name="Gruppe 15561" transform="translate(0)">
					<g data-name="Rechteck 5412" fill={color} stroke="#fff" strokeLinejoin="round" strokeWidth="1.2">
						<rect width="15.041" height="6.618" stroke="none"/>
						<rect x="0.6" y="0.6" width="13.841" height="5.418" fill="none"/>
					</g>
					<path data-name="Pfad 8962" d="M0,2.382V0" transform="translate(7.52 3.309)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.2"/>
				</g>
			</g>
			<g data-name="Gruppe 15563" transform="translate(516 704.829)">
				<g data-name="Gruppe 15561" transform="translate(0)">
					<g data-name="Rechteck 5412" fill={color} stroke="#fff" strokeLinejoin="round" strokeWidth="1.2">
						<rect width="15.041" height="6.618" stroke="none"/>
						<rect x="0.6" y="0.6" width="13.841" height="5.418" fill="none"/>
					</g>
					<path data-name="Pfad 8962" d="M0,2.382V0" transform="translate(7.52 3.309)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.2"/>
				</g>
			</g>
		</g>
	</svg>
	
  );
}

export default IconNeubauprojekt;
