import Blocks from 'editorjs-blocks-react-renderer';
import Header from '../components/Header/Header';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsDetailSkeleton from '../components/Skeletons/NewsDetailSkeleton';
import { useFetchNewsDetails } from '../hooks/news/useFetchNewsDetails';
import { getDefaultNewsObject } from '../util/newsParser';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import '../styles/news.scss';
import { useEffect, useRef } from 'react';

function NewsDetail() {
  const app = useSelector((state) => state.app);
  const { currentNews, navigateBack } = useFetchNewsDetails();
  const { isMobile } = useHandleScreenResize();

  if (currentNews?.slug === '') {
    return <NewsDetailSkeleton />;
  } else {
    if (JSON.stringify(currentNews) !== JSON.stringify(getDefaultNewsObject())) {
      return (
        <>
          <MetaTags
            title={'my-sreal News | ' + currentNews.title}
            description={currentNews.summary}
            imageName={currentNews.header_picture ? currentNews.header_picture : 'mysreal_hero_news.jpg'}
            imageFullPath={currentNews.header_picture ? true : false}
          />

          <Header cssClasses="header--small" />
          <MainContent cssClasses="w-desktop--center newsdetail">
            <div className="header--news__links">
              <span onClick={navigateBack} className="textlink">
                Zurück
              </span>
              <Link to={app.root + '/news'} className="textlink" title="Zur News-Übersicht">
                Zur News-Übersicht
              </Link>
            </div>

            {((!currentNews.header_picture && isMobile) || currentNews.header_picture) && (
              <>
                <h1 className={`mb-0 ${isMobile && 'font-160'}`}>{currentNews.title}</h1>
                <h3 className={`mt-50rem mb-50rem ${isMobile && 'font-110'}`}>{currentNews.summary}</h3>
                <span className="font-80">
                  {currentNews.published_on}
                  {currentNews.author_name ? ` | ${currentNews.author_name}` : ''}
                  {currentNews.topics.length > 0 ? ` | ${currentNews.topics}` : ''}
                </span>
              </>
            )}

            <img src={currentNews.header_picture} alt={currentNews.header_picture_alt} className="w-100 mt-100rem" />
						{ currentNews.header_picture_copyright && <small>© {currentNews.header_picture_copyright}</small> }

            <div className="newsdetail__content">
              {currentNews.blocks && (
                <>
                  <Blocks
                    data={currentNews.blocks}
                    config={{
                      code: {
                        className: 'language-js',
                      },
                      paragraph: {
                        className: 'mt-200rem',
                      },
                      image: {
                        className: 'mt-200rem w-100',
                      },
                      embed: {
                        className: 'mt-200rem',
                        rel: 'noreferer nofollower external',
                      },
                    }}
                  />
                </>
              )}
            </div>
          </MainContent>
        </>
      );
    } else {
      return null;
    }
  }
}

export default NewsDetail;
