import Helmet from 'react-helmet';

function MetaTags({ title, description, imageName, imageFullPath = false }) {
  const url = window.location.href;

  /* Title is also set in public/index.html file. */
  if (!title) {
    title = 'my-sreal.at | Die Online-Plattform für Ihre Immobilie';
  }
  if (!description) {
    description =
      'Ob Sie Ihre Immobilie verkaufen möchten, eine Wohnung kaufen oder ein Haus kaufen wollen: my-sreal.at ist die Online-Plattform für Ihre Immobilie. Einfach, transparent und digital kaufen und verkaufen.';
  }

  const imageDefault = 'mysreal_hero_home.jpg';
  if (!imageName) {
    imageName = imageDefault;
  }

  let robots = 'noindex';
  if(window.location.host.indexOf('my-sreal.at') > -1) {
    robots = 'index, follow';
  }

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="robots" content={robots} />

      <meta
        property="og:image"
        content={
          imageFullPath && imageName !== imageDefault
            ? imageName
            : require('../assets/images/' + imageName)
        }
      />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content={
          imageFullPath && imageName !== imageDefault
            ? imageName
            : require('../assets/images/' + imageName)
        }
        data-react-helmet="true"
      />
    </Helmet>
  );
}

export default MetaTags;
