//*React
import { useDispatch, useSelector } from 'react-redux';
//*Components
import LoadingSpinner from '../../LoadingSpinner';
//*Constants
import {
  REGFORM_SITEINDEX_EMAIL_BESTAETIGEN,
  REGFORM_SITEINDEX_START
} from '../../../constants';
//*API
//*Reducers
import { setSiteIndexRegisterForm } from '../../../reducers/registerForm';

function RegisterFormConfirmError() {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);

  const handleBackToRegisterForm = () => {
    dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_START));
  };

  const handleResendConfirmationLink = () => {
    dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_EMAIL_BESTAETIGEN));
  };

  return (
    <>
      <h2 className="pb-200rem">
        Jetzt registrieren
        <br />
        bei my-sreal.at
      </h2>

      <p className="mt-200rem mb-100rem">
        Der Abschluss Ihrer Registrierung hat leider nicht funktioniert.
      </p>
      <p>
        Bitte wenden Sie sich an unsere Kundenbetreuung unter:
        <br />
        <br />
        Tel:{' '}
        <a
          className="textlink"
          href="tel:05010026200"
          title="s REAL anrufen"
          target="_blank"
          rel="noreferrer"
        >
          05 0100 - 26200
        </a>
        <br />
        Mail:{' '}
        <a
          className="textlink"
          href={"mailto:" + app.companyEmail}
          title={'E-Mail an ' + app.companyName}
          target="_blank"
          rel="noreferrer"
        >
          {app.companyEmail}
        </a>

      </p>
      <br />
      <p>
        Oder{' '}
        <span className="textlink" onClick={() => handleResendConfirmationLink()}>
          lassen Sie sich einen neuen Aktivierungslink zusenden
        </span>
        .
      </p>

      <LoadingSpinner />

      <div className="button-panel">
        <button
          className="button button--orange"
          onClick={handleBackToRegisterForm}
        >
          Zurück zur Registrierung
        </button>
      </div>
    </>
  );
}

export default RegisterFormConfirmError;
