import { createSlice } from '@reduxjs/toolkit';
import { KAUFANBOT_SITEINDEX_MAIN } from '../constants';
import { removeHashFromUrl } from '../util/generalUtils';

const initialState = {
  siteIndex: KAUFANBOT_SITEINDEX_MAIN,
  pageDialogOpen: false,
  headline: 'Anfrage Kaufanbot',
  kaufanboteList: [],
  sentOffers: [],
  wunschimmobilie: {}
};

export const kaufAnboteSlice = createSlice({
  name: 'kaufAnbote',
  initialState,
  reducers: {
    setSiteIndexKaufanbotForm: (state, action) => {
      window.location.hash = action.payload
      state.siteIndex = action.payload;
    },
    setPageDialogOpenKaufanbotForm: (state, action) => {
      if (action.payload === true) {
        window.location.hash = state.siteIndex;
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setKaufanboteList: (state, action) => {
      state.kaufanboteList = action.payload;
    },
    setSentOffers: (state, action) => {
      state.sentOffers = action.payload;
    },
    setWunschimmobilieKaufanbotForm: (state, action) => {
      state.wunschimmobilie = action.payload;
    },
    setResetFieldsKaufanbotForm: (state) => {
      return initialState;
    }
  }
});

export const { 
  setSiteIndexKaufanbotForm,
  setPageDialogOpenKaufanbotForm,
  setKaufanboteList,
  setSentOffers,
  setWunschimmobilieKaufanbotForm,
  setResetFieldsKaufanbotForm
} = kaufAnboteSlice.actions;

export default kaufAnboteSlice.reducer;
