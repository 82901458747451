function IconCheck({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <rect data-name="Rechteck 3311" width="26" height="26" rx="5" fill={color} />
      <path
        data-name="Pfad 5892"
        d="M205.628,1262.447l5.373,6.142,9.957-11.2"
        transform="translate(-200.291 -1249.986)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </svg>
  );
}

export default IconCheck;
