import Card from './Card';
import IconDocument from '../Icons/IconDocument';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ImmoDriveEmptyCard({ cssClasses = '', smallCard }) {
  if (!cssClasses) cssClasses = 'immodriveempty-card';
  else cssClasses += ' immodriveempty-card';
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);

  let cardStyle = 'customContent';
  return (
    <>
      {smallCard ? (
        <Card cssClasses={cssClasses + ' background-stone text-white'} cardStyle={'customContent'}>
          <div className="card__content card__content--background">
            <div className="mb-10 mt-10">
              <IconDocument color="#fff" />
            </div>
            <h4 className="mb-100rem text-white">Immo-drive</h4>
            <p className="font-90">Es wurden noch keine Dokumente bereitgestellt</p>

            <div className="d-flex d-flex--btnandicon mt-auto">
              <div>
                <button className="button button--white-outline mt-100rem" onClick={() => navigate(app.root + '/immo-drive')}>
                  Details ansehen
                </button>
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card cssClasses={cssClasses + ' text-center'} cardStyle={cardStyle}>
          <div className="card__icon">
            <IconDocument color="#fff" />
          </div>

          <div className="card__content pb-0 pt-0">
            <h2 className="mb-50rem text-white">Es wurden noch keine Dokumente bereitgestellt</h2>
          </div>

          <div>
            <button className="mb-200rem mt-50rem button button--white-outline" onClick={() => navigate(app.root + '/immo-drive')}>
              immo-drive aufrufen
            </button>
          </div>
        </Card>
      )}
    </>
  );
}

export default ImmoDriveEmptyCard;
