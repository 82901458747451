import { useSelector } from 'react-redux';

// STEPS
import SearchData from './SearchProfile/SearchData';
import SearchProfileCreated from './SearchProfile/SearchProfileCreated';
import UserData from './SearchProfile/UserData';

import
  {
    SEARCHPROFILE_SITEINDEX_EMAIL,
    SEARCHPROFILE_SITEINDEX_START,
    SEARCHPROFILE_SITEINDEX_USERDATA,
  } from '../../constants';

function SearchprofileForm({ id }) {
  let currentSearchProfileForm = useSelector((state) => state.searchProfileForm);

  const formType = 'searchProfileForm';

  return (
    <div className="searchProfileForm p-100rem">
      {currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_START ? (
        <SearchData formType={formType} id={id} />
      ) : currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_USERDATA ? (
        <UserData formType={formType} id={id} />
      ) : currentSearchProfileForm.siteIndex === SEARCHPROFILE_SITEINDEX_EMAIL ? (
        <SearchProfileCreated />
      ) : null}
    </div>
  )
}

export default SearchprofileForm;
