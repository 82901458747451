import '../../styles/immofeatures.scss';
import { getKaufpreis } from '../../util/immoObjectParser';

function ImmoFeatures({ immo, showKaufpreis = true, immodetail }) {
  return (
    <div className="immodetail__features">
      {/* show kaufpreis if one of the other featues is not available 
      {showKaufpreis && ((parseInt(immo.areas.living_area) === 0 && parseInt(immo.areas.floor_area) === 0) || parseInt(immo.rooms) === 0 || parseInt(immo.areas.site_area) === 0) && (
        <>
          <div className="immodetail__features-wrapper">
            <span className="immodetail__features--val no-break">{getKaufpreis(immo)}</span>
            <span className="immodetail__features--label">Kaufpreis</span>
          </div>
        </>
      )}*/}

      {parseInt(immo.areas.living_area) > 0 ? (
        <div className={immodetail ? 'immodetail__features-wrapper background-apple text-white' : 'immodetail__features-wrapper'}>
          <span className="immodetail__features--val">{immo.areas.living_area?.toLocaleString('de-DE')} m²</span>
          <span className="immodetail__features--label">Wohnfläche</span>
        </div>
      ) : parseInt(immo.areas.floor_area) > 0 ? (
        <div className={immodetail ? 'immodetail__features-wrapper background-apple text-white' : 'immodetail__features-wrapper'}>
          <span className="immodetail__features--val">
            {parseInt(immo.areas.floor_area_to) > 0 && 'ab ' }
            {immo.areas.floor_area.toLocaleString('de-DE')} m²
          </span>
          <span className="immodetail__features--label">Nutzfläche</span>
        </div>
      ) : null}

      {parseInt(immo.rooms) > 0 && (
        <div className={immodetail ? 'immodetail__features-wrapper background-teal text-white' : 'immodetail__features-wrapper'}>
          <span className="immodetail__features--val">
					{immo.rooms_to > 0 && 'ab ' }
					{immo.rooms?.toString().replace('.', ',')}</span>
          <span className="immodetail__features--label">Zimmer</span>
        </div>
      )}

      {parseInt(immo.areas.site_area) > 0 && (
        <div className={immodetail ? 'immodetail__features-wrapper background-darkteal text-white' : 'immodetail__features-wrapper'}>
          <span className="immodetail__features--val">{immo.areas.site_area.toLocaleString('de-DE')} m²</span>
          <span className="immodetail__features--label">Grundfläche</span>
        </div>
      )}
    </div>
  );
}

export default ImmoFeatures;
