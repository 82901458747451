function IconImmothek({ color, cssClasses = '' }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 17268" xmlns="http://www.w3.org/2000/svg" width="47.597" height="47.9" viewBox="0 0 47.597 47.9">
      <g data-name="Gruppe 17269">
        <path
          data-name="Pfad 10512"
          d="M47.5,41.7l-1.7-6.8v-.2L39.2,8.5a3.363,3.363,0,0,0-1.6-2.1A3.013,3.013,0,0,0,35,6L28.3,7.7a3.606,3.606,0,0,0-1.5.8,3.356,3.356,0,0,0-2.9-1.6H15.3V3.4A3.372,3.372,0,0,0,11.9,0H3.4A3.372,3.372,0,0,0,0,3.4V44.5a3.372,3.372,0,0,0,3.4,3.4H12a3.371,3.371,0,0,0,1.7-.5,3.371,3.371,0,0,0,1.7.5H24a3.372,3.372,0,0,0,3.4-3.4V17.9l6.8,27.2a3.4,3.4,0,0,0,3.3,2.6,1.949,1.949,0,0,0,.8-.1L45,45.9a3.521,3.521,0,0,0,2.5-4.2M24,29.2H15.4V10.3H24ZM3.4,3.4H12V32.6H3.4ZM12,44.6H3.4V36H12Zm3.4-12H24v12H15.4ZM29.2,11l6.7-1.7L42,34l-6.7,1.7Zm8.3,33.2L36.2,39l6.7-1.7,1.3,5.2Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
export default IconImmothek;
