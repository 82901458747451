function IconVideo({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.278"
      height="31.454"
      viewBox="0 0 26.278 31.454"
      className={cssClasses}
    >
      <g id="Gruppe_10253" data-name="Gruppe 10253" transform="translate(-9742.523 912.681)">
        <g id="Gruppe_10254" data-name="Gruppe 10254" transform="translate(9742.523 -912.681)">
          <g id="Gruppe_10255" data-name="Gruppe 10255" transform="translate(0 0)">
            <path
              id="Pfad_7608"
              data-name="Pfad 7608"
              d="M5.237,25.227A2.624,2.624,0,0,1,2.621,22.6L2.648,8.826a2.575,2.575,0,0,1,.768-1.842,2.616,2.616,0,0,1,1.853-.757h.005l8.928,0L12.72,8.445A1.31,1.31,0,1,0,14.9,9.9l2.627-3.926a1.29,1.29,0,0,0,.2-.864c.055-.387-2.595-4.2-2.811-4.528a1.312,1.312,0,0,0-2.185,1.452l1.044,1.571-8.5,0H5.268A5.226,5.226,0,0,0,.028,8.821L0,22.6a5.244,5.244,0,0,0,5.231,5.252h0a1.31,1.31,0,0,0,0-2.621"
              transform="translate(0 0.001)"
              fill={color}
            />
            <path
              id="Pfad_7609"
              data-name="Pfad 7609"
              d="M19.026,3h0a1.31,1.31,0,0,0,0,2.621,2.622,2.622,0,0,1,2.616,2.626L21.609,21.37a2.625,2.625,0,0,1-2.622,2.617h0l-8.431-.016.92-1.147a1.311,1.311,0,0,0-2.044-1.641L6.8,24.46l-.008.013a1.318,1.318,0,0,0-.062,1.533l2.613,3.931a1.311,1.311,0,0,0,2.183-1.452l-1.261-1.9,8.713.018h.009a5.251,5.251,0,0,0,5.243-5.233l.026-13.127A5.246,5.246,0,0,0,19.026,3"
              transform="translate(2.022 0.931)"
              fill={color}
            />
          </g>
        </g>
        <path
          id="Pfad_7610"
          data-name="Pfad 7610"
          d="M5,6.462a1.586,1.586,0,0,1-.383,1.074,1.983,1.983,0,0,1-1.074.609v.028a2.147,2.147,0,0,1,1.236.5A1.388,1.388,0,0,1,5.2,9.731a1.8,1.8,0,0,1-.705,1.515,3.252,3.252,0,0,1-2.015.542,4.892,4.892,0,0,1-1.946-.364V10.211a4.4,4.4,0,0,0,.862.323,3.628,3.628,0,0,0,.931.124,1.811,1.811,0,0,0,1.042-.24.88.88,0,0,0,.337-.77.7.7,0,0,0-.387-.673,2.865,2.865,0,0,0-1.236-.2H1.571V7.684h.521a2.423,2.423,0,0,0,1.146-.205.741.741,0,0,0,.362-.7q0-.765-.959-.765a2.195,2.195,0,0,0-.675.111A3.3,3.3,0,0,0,1.2,6.5L.542,5.522a3.663,3.663,0,0,1,2.2-.664,2.84,2.84,0,0,1,1.653.424A1.36,1.36,0,0,1,5,6.462ZM5.9,8.832a4.459,4.459,0,0,1,.846-2.978,3.169,3.169,0,0,1,2.533-.977,4.6,4.6,0,0,1,.9.069V6.084a3.692,3.692,0,0,0-.811-.092,2.786,2.786,0,0,0-1.2.221,1.488,1.488,0,0,0-.694.655A3.007,3.007,0,0,0,7.2,8.1h.06a1.565,1.565,0,0,1,1.462-.784,1.81,1.81,0,0,1,1.415.567,2.255,2.255,0,0,1,.512,1.568,2.353,2.353,0,0,1-.609,1.708,2.238,2.238,0,0,1-1.687.629,2.414,2.414,0,0,1-1.3-.346,2.246,2.246,0,0,1-.858-1.01A3.845,3.845,0,0,1,5.9,8.832ZM8.33,10.649a.844.844,0,0,0,.7-.307,1.372,1.372,0,0,0,.244-.874,1.206,1.206,0,0,0-.228-.777.832.832,0,0,0-.689-.284,1.056,1.056,0,0,0-.74.281.864.864,0,0,0-.307.655,1.484,1.484,0,0,0,.288.927A.883.883,0,0,0,8.33,10.649Zm7.676-2.324a4.7,4.7,0,0,1-.579,2.614,2.008,2.008,0,0,1-1.782.848,1.985,1.985,0,0,1-1.759-.876,4.632,4.632,0,0,1-.592-2.586,4.743,4.743,0,0,1,.576-2.63,2,2,0,0,1,1.775-.846,1.99,1.99,0,0,1,1.764.885A4.641,4.641,0,0,1,16.006,8.325Zm-3.3,0a5.244,5.244,0,0,0,.214,1.777.742.742,0,0,0,.722.537.75.75,0,0,0,.719-.544,5.1,5.1,0,0,0,.221-1.77,5.084,5.084,0,0,0-.224-1.782.747.747,0,0,0-1.436,0A5.235,5.235,0,0,0,12.709,8.325Z"
          transform="translate(9747.393 -905.272)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconVideo;
