function IconHeart({ color, cssClasses, filled, handleClick }) {
  let filledColor = 'none';
  if (!!filled) filledColor = color;

  return (
    <svg className={cssClasses} onClick={handleClick} xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21">
      <g data-name="Gruppe 15695" transform="translate(-18063 15618.011)">
        <g data-name="Gruppe 15693" transform="translate(18063 -15618.011)">
          <g data-name="Gruppe 15187" transform="translate(0 0)">
            <path data-name="Pfad 8721" d="M23.713,5.15A6.991,6.991,0,0,0,18.866.142c-1.4-.31-4-.31-6.846,2.582C9.172-.22,6.523-.168,5.174.142A6.981,6.981,0,0,0,.277,5.1a6.983,6.983,0,0,0,1.7,6.815l9.445,8.88a.78.78,0,0,0,.6.207.852.852,0,0,0,.6-.207l9.5-8.88a6.965,6.965,0,0,0,1.6-6.764m-2.8,5.473L12.02,18.935,3.175,10.674A5.128,5.128,0,0,1,1.926,5.666a5.206,5.206,0,0,1,3.6-3.717,5.325,5.325,0,0,1,.9-.1c1.649,0,3.348.981,4.9,2.788a.914.914,0,0,0,1.3,0c1.849-2.168,3.9-3.149,5.8-2.685a5.122,5.122,0,0,1,3.6,3.717,4.866,4.866,0,0,1-1.1,4.956" transform="translate(0 0)" />
          </g>
        </g>
        <g data-name="Gruppe 15694" transform="translate(17589 -16163)">
          <g data-name="Gruppe 15188" transform="translate(474 544.989)">
            <g data-name="Gruppe 15187" transform="translate(0 0)">
              <path data-name="Pfad 8721" d="M23.713,5.15A6.991,6.991,0,0,0,18.866.142c-1.4-.31-4-.31-6.846,2.582C9.172-.22,6.523-.168,5.174.142A6.981,6.981,0,0,0,.277,5.1a6.983,6.983,0,0,0,1.7,6.815l9.445,8.88a.78.78,0,0,0,.6.207.852.852,0,0,0,.6-.207l9.5-8.88a6.965,6.965,0,0,0,1.6-6.764m-2.8,5.473L12.02,18.935,3.175,10.674A5.128,5.128,0,0,1,1.926,5.666a5.206,5.206,0,0,1,3.6-3.717,5.325,5.325,0,0,1,.9-.1c1.649,0,3.348.981,4.9,2.788a.914.914,0,0,0,1.3,0c1.849-2.168,3.9-3.149,5.8-2.685a5.122,5.122,0,0,1,3.6,3.717,4.866,4.866,0,0,1-1.1,4.956" transform="translate(0 0)" fill="#2870ed" />
            </g>
          </g>
          <path data-name="Vereinigungsmenge 64" d="M-3670.584,3099.416A6,6,0,0,1-3674,3094a6,6,0,0,1,6-6,5.994,5.994,0,0,1,5,2.682,5.994,5.994,0,0,1,5-2.682,6,6,0,0,1,6,6,6,6,0,0,1-3.416,5.416L-3663,3107Z" transform="translate(4149 -2542)" fill={filledColor} />
        </g>
      </g>
    </svg>


  );
}

export default IconHeart;
