import { useSelector } from 'react-redux';

// STEPS
import LoginFormMain from './Onboarding/LoginFormMain';
import FormToken from './Onboarding/FormToken';
import LoginFormPWForget from './Onboarding/LoginFormPWForget';
import FormEmailSent from './Onboarding/FormEmailSent';
import LoginFormSuccess from './Onboarding/LoginFormSuccess';

import { LOGINFORM_SITEINDEX_START, LOGINFORM_SITEINDEX_SUCCESSTOKEN, LOGINFORM_SITEINDEX_PWFORGET, LOGINFORM_SITEINDEX_LOGINSUCCESS, LOGINFORM_SITEINDEX_PWFORGETEMAIL } from '../../constants';

function LoginForm({ handleClose }) {
  let loginForm = useSelector((state) => state.loginForm);

  return (
    <>
      <div className="loginForm p-100rem">
        {loginForm.siteIndex === LOGINFORM_SITEINDEX_START ? (
          <LoginFormMain handleClose={handleClose} />
        ) : loginForm.siteIndex === LOGINFORM_SITEINDEX_SUCCESSTOKEN ? (
          <FormToken handleClose={handleClose} formType="loginForm" />
        ) : loginForm.siteIndex === LOGINFORM_SITEINDEX_LOGINSUCCESS ? (
          <LoginFormSuccess handleClose={handleClose} />
        ) : loginForm.siteIndex === LOGINFORM_SITEINDEX_PWFORGET ? (
          <LoginFormPWForget />
        ) : loginForm.siteIndex === LOGINFORM_SITEINDEX_PWFORGETEMAIL ? (
          <FormEmailSent handleClose={handleClose} />
        ) : null}
      </div>
    </>
  );
}

export default LoginForm;
