function IconDownload({ color, cssClasses, scale }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40.286" height="40.202" viewBox="0 0 40.286 40.202">
      <g id="Gruppe_15690" data-name="Gruppe 15690" transform="translate(0 -8)">
        <g id="Gruppe_15691" data-name="Gruppe 15691" transform="translate(0 8)">
          <path
            id="Pfad_10305"
            data-name="Pfad 10305"
            d="M38.778,34.3a1.468,1.468,0,0,0-1.424,1.424v2.848a4.289,4.289,0,0,1-4.271,4.271H7.2a4.289,4.289,0,0,1-4.271-4.271V35.724A1.468,1.468,0,0,0,1.508,34.3,1.545,1.545,0,0,0,0,35.724v2.848a7.15,7.15,0,0,0,7.2,7.2h25.88a7.2,7.2,0,0,0,7.2-7.2V35.724A1.611,1.611,0,0,0,38.778,34.3"
            transform="translate(0 -5.572)"
            fill={color ? color : '#2870ed'}
          />
          <path
            id="Pfad_10306"
            data-name="Pfad 10306"
            d="M20.963,28.225l.168.168.084.084c.084,0,.084.084.168.084a.082.082,0,0,1,.084.084.292.292,0,0,1,.168.084h.838c.084,0,.168,0,.168-.084a.082.082,0,0,0,.084-.084c.084,0,.084-.084.168-.084l.084-.084c.084,0,.084-.084.168-.084l8.627-10.051a1.426,1.426,0,1,0-2.178-1.843l-6.114,7.119V1.424a1.424,1.424,0,0,0-2.848,0V23.367l-6.114-7.035a1.426,1.426,0,0,0-2.178,1.843Z"
            transform="translate(-1.95)"
            fill={color ? color : '#2870ed'}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconDownload;
