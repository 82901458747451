function IconCheckmark({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 13739" xmlns="http://www.w3.org/2000/svg" width="10.059" height="9.789" viewBox="0 0 10.059 9.789">
      <g data-name="Gruppe 13688">
        <path
          data-name="Pfad 8566"
          d="M21.837,12.059a.691.691,0,0,0-.969.253l-4.633,7.96-2.948-2.358A.72.72,0,1,0,12.4,19.05l2.948,2.358a1.448,1.448,0,0,0,.884.337.534.534,0,0,0,.253-.042,1.477,1.477,0,0,0,.969-.674l4.633-7.96a.792.792,0,0,0-.253-1.011"
          transform="translate(-12.114 -11.956)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconCheckmark;
