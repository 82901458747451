import { useDispatch, useSelector } from 'react-redux';
import { getZipsForLocations } from '../../api/JustImmo';
import { getRealtySearch } from '../../api/Sreal';
import findAdjacentPostcodes from '../../util/boundaryUtils';
import { setCachedSimilarImmos, setLastCachedDate } from '../../reducers/cachedSimilarImmos';
import {
  JUSTIMMO_TYPE_CITY_ID,
  JUSTIMMO_TYPE_CITY_LABEL,
  JUSTIMMO_TYPE_CITYZIP_ID,
  JUSTIMMO_TYPE_CITYZIP_LABEL,
  JUSTIMMO_TYPE_MUNICIPALITY_ID,
  JUSTIMMO_TYPE_MUNICIPALITY_LABEL,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL,
} from '../../constants';

export const useHandleSimilarImmos = () => {
  const dispatch = useDispatch();
  const cachedSimilarImmos = useSelector((state) => state.cachedSimilarImmos);

  const parseSuchprofilCriteria = async (suchprofil) => {
    const criteria = suchprofil.criteria;
    let requestBody = {
      addressObject: [],
      estateType: [],
      priceFrom: '',
      priceTo: '',
      areaFrom: '',
      areaTo: '',
      roomsFrom: '',
      roomsTo: '',
      features: [],
      sorting: {
        id: 'updated_desc',
        text: 'Aktualität',
      },
    };

    if (criteria.location && criteria.location.location_ids) {
      let searchedZips = {
        hasPoliticalDistrict: false,
        zips: [],
      };
      let justImmoSearchZips = {
        city_id: [],
        municipality_id: [],
        political_district_id: [],
      };

      criteria.location.location_ids.forEach((locationId) => {
        if (locationId.type === 'zip') {
          searchedZips.zips.push(locationId.text);
        } else if (locationId.type === JUSTIMMO_TYPE_CITY_LABEL || locationId.type === JUSTIMMO_TYPE_CITY_ID) {
          justImmoSearchZips.city_id.push(locationId.id);
        } else if (locationId.type === JUSTIMMO_TYPE_CITYZIP_LABEL || locationId.type === JUSTIMMO_TYPE_CITYZIP_ID) {
          searchedZips.zips.push(locationId.text.substring(0, 4));
        } else if (locationId.type === JUSTIMMO_TYPE_MUNICIPALITY_LABEL || locationId.type === JUSTIMMO_TYPE_MUNICIPALITY_ID) {
          justImmoSearchZips.municipality_id.push(locationId.id);
        } else if (locationId.type === JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL || locationId.type === JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID) {
          justImmoSearchZips.political_district_id.push(locationId.id);
        }
      });

      try {
        // Convert arrays to comma-separated strings
        const locationParams = {
          city_id: justImmoSearchZips.city_id.join(','),
          municipality_id: justImmoSearchZips.municipality_id.join(','),
          political_district_id: justImmoSearchZips.political_district_id.join(','),
        };

        if (locationParams.city_id || locationParams.municipality_id || locationParams.political_district_id) {
          const justImmoLocations = await getZipsForLocations(locationParams);
          if (locationParams.political_district_id) {
            searchedZips.hasPoliticalDistrict = true;
          }
          if (justImmoLocations.length > 0) {
            justImmoLocations.forEach((location) => {
              searchedZips.zips.push(location.code);
            });
          }
        }
      } catch (error) {
        console.error('Error retrieving location zips:', error);
        throw error;
      }

      if (!searchedZips.hasPoliticalDistrict && searchedZips.zips.length > 0) {
        searchedZips.zips = searchedZips.zips.map((zip) => findAdjacentPostcodes(zip)).flat();
      }

      const parsedZips = searchedZips.zips.map((zip) => `z_${zip}`);

      parsedZips.forEach((zip) => {
        if (!requestBody.addressObject.find((v) => v.value === zip)) {
          requestBody.addressObject.push({
            value: zip,
            text: zip,
          });
        }
      });
    }

    if (criteria.types && criteria.types.length > 0) {
      criteria.types.forEach((type) => {
        if (type.name === 'Wohnung') {
          requestBody.estateType.push({
            id: 'flat',
            text: 'Wohnung',
          });
        } else if (type.name === 'Haus') {
          requestBody.estateType.push({
            id: 'house',
            text: 'Haus',
          });
        }
      });
    }

    if (criteria.price) {
      requestBody.priceFrom = criteria.price.from;
      requestBody.priceTo = criteria.price.to ? criteria.price.to * 1.2 : '';
    }

    if (criteria.living_area) {
      requestBody.areaFrom = criteria.living_area.from ? criteria.living_area.from * 0.8 : '';
      requestBody.areaTo = criteria.living_area.to ? criteria.living_area.to * 1.5 : '';
    }

    if (criteria.rooms) {
      if (criteria.rooms.from === 5) {
        requestBody.roomsFrom = 4;
      } else {
        requestBody.roomsFrom = criteria.rooms.from;
      }
    }

    if (criteria.balcony?.enabled || criteria.terrace?.enabled || criteria.covered_balcony?.enabled) {
      requestBody.features.push({ id: 'balconyOrTerace', text: 'Balkon/Terrasse' });
    }

    if (criteria.garden?.enabled) {
      requestBody.features.push({ id: 'garden', text: 'Garten' });
    }

    return requestBody;
  };

  const getSimilarImmos = async (currentSuchprofile) => {
    if (!currentSuchprofile || currentSuchprofile.length === 0) return [];

    // get similar immos from cache if they are not older than 1 day
    if (!cachedSimilarImmos.lastCachedDate || cachedSimilarImmos.lastCachedDate < new Date().getDate() - 1) {
      try {
        // Warte bis alle Locations für die Suche vorbereitet sind
        let parsedCriteria = await Promise.all(currentSuchprofile.map((suchprofil) => parseSuchprofilCriteria(suchprofil)));
        parsedCriteria = parsedCriteria.filter((criteria) => criteria.addressObject.length > 0);
        // Rufe für jedes Suchprofil die ähnlichen Immobilien ab
        const similarImmos = await Promise.all(parsedCriteria.map((criteria) => getRealtySearch(criteria, 1)));
        const flattenedImmos = similarImmos.map((immo) => immo.realties).flat();
        const uniqueImmos = Array.from(new Set(flattenedImmos.map((a) => a.id))).map((id) => {
          return flattenedImmos.find((a) => a.id === id);
        });

        // Liste wird geshuffled, damit die Darstellung der ähnlichen Immobilien abwechslungsreicher ist
        for (var i = uniqueImmos.length - 1; i >= 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = uniqueImmos[i];
          uniqueImmos[i] = uniqueImmos[j];
          uniqueImmos[j] = temp;
        }

        dispatch(setCachedSimilarImmos(uniqueImmos.slice(0, 50)));
        dispatch(setLastCachedDate(new Date()));

        return uniqueImmos;
      } catch (error) {
        console.error('Error in getSimilarImmos:', error);
        return [];
      }
    } else {
      return cachedSimilarImmos.cachedSimilarImmos;
    }
  };

  return { getSimilarImmos };
};
