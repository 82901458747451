import Slider from '@mui/material/Slider';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setDistrictError } from '../../reducers/formValidators';
import useOutsideAlerter from '../useOutsideAlerter';

// ICONS
import IconClose from '../Icons/IconClose';

import {
  setAreaContactForm,
  setDisplayedDistrictNameContactForm,
  setEmailContactForm,
  setFirstNameContactForm,
  setLastNameContactForm,
  setParsedPhoneNumberContactForm,
  setPasswordConfirmContactForm,
  setPasswordContactForm,
  setPhoneNumberContactForm,
  setPostalCodeContactForm,
  setStateContactForm,
  setTitleAfterContactForm,
  setTitleBeforeContactForm,
} from '../../reducers/contactInformation';

import { setMarktBerichtStreet, setMarktBerichtNumber, setMarktBerichtZip, setMarktBerichtCity, setMarktBerichtIsOwner } from '../../reducers/marktBericht';

import {
  setEmailRegisterForm,
  setFirstNameRegisterForm,
  setLastNameRegisterForm,
  setParsedPhoneNumberRegisterForm,
  setPasswordConfirmRegisterForm,
  setPasswordRegisterForm,
  setPhoneNumberRegisterForm,
  setTitleAfterRegisterForm,
  setTitleBeforeRegisterForm,
  setTokenRegisterForm,
} from '../../reducers/registerForm';

import { getLocations } from '../../api/Sreal';

import {
  setAddressObjectSearchForm,
  setAddressSearchForm,
  setAreaFromSearchForm,
  setAreaToSearchForm,
  setFullTextSearchForm,
  setPriceFromSearchForm,
  setPriceToSearchForm,
  setRoomsFromSearchForm,
  setRoomsToSearchForm,
  setSurfaceAreaFromSearchForm,
  setSurfaceAreaToSearchForm,
} from '../../reducers/searchForm';

import { setEmailLoginForm, setPasswordLoginForm, setTokenLoginForm } from '../../reducers/loginForm';

import { setWohnwertLightDistrict, setWohnwertLightRealEstateSize, setWohnwertLightTypedPostCode } from '../../reducers/wohnwertLight';

import { setMarktinfoLightRealEstateSize, setMarktinfoLightStreet, setMarktinfoLightTypedZip, setMarktinfoLightZip } from '../../reducers/marktinfoLight';

import {
  setEmailSPForm,
  setFirstNameSPForm,
  setFullTextSPForm,
  setLastNameSPForm,
  setLivingAreaFromSPForm,
  setLivingAreaToSPForm,
  setNameSPForm,
  setParsedPhoneNumberSPForm,
  setPasswordConfirmSPForm,
  setPasswordSPForm,
  setPhoneSPForm,
  setPriceFromSPForm,
  setPriceToSPForm,
  setRoomsFromSPForm,
  setSiteAreaFromSPForm,
  setSiteAreaToSPForm,
  setTitlePostSPForm,
  setTitlePreSPForm,
} from '../../reducers/searchProfileForm';

import { setLoanTimeFinanzForm, setLoanValueFinanzForm, setInputLoanValueFinanzForm, setInputLoanTimeFinanzForm } from '../../reducers/finanzierungRechner';

import {
  setEmailObjectRequest,
  setFirstNameObjectRequest,
  setLastNameObjectRequest,
  setParsedPhoneNumberObjectRequest,
  setPhoneObjectRequest,
  setTitleAfterObjectRequest,
  setTitleBeforeObjectRequest,
} from '../../reducers/objectRequest';

import { useState } from 'react';
import { autocomplete } from '../../api/JustImmo';
import statesWithZips from './util/statesWithZips';
import { setChangedParsedPhoneNumber, setChangedParsedPhoneNumberCheck } from '../../reducers/registerFormChangePhoneNumber';

function FormInput({
  type,
  id,
  label,
  required,
  error,
  errorTextWhite,
  value,
  sliderValue,
  showFurtherInfoText,
  placeholder,
  formType,
  disabled,
  hasLockIcon,
  minValue,
  maxValue,
  stepValue,
  hasSlider = false,
  sliderLabels,
  hasMarks,
  hasList = false,
  cssClasses,
  patternText,
  callback,
}) {
  if (!cssClasses) cssClasses = '';
  if (hasList) cssClasses += 'form__field--haslist';
  if (type === 'password') cssClasses += ' position-relative';

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const app = useSelector((state) => state.app);
  const currentSearchForm = useSelector((state) => state.searchForm);
  const currentWohnwertLightForm = useSelector((state) => state.wohnwertLight);
  const currentMarktinfoLight = useSelector((state) => state.marktinfoLight);
  const currentSearchProfileForm = useSelector((state) => state.searchProfileForm);
  const currentContactInformation = useSelector((state) => state.contactInformation);
  const currentFinanzierungsRechner = useSelector((state) => state.finanzierungsRechner);

  const [typing, setTyping] = useState(false);
  const [callAutocompleteVal, setCallAutocompleteVal] = useState(false);
  const [list, setList] = useState([]);

  const [showResetIcon, setShowResetIcon] = useState(false);
  const [errorMin, setErrorMin] = useState(false);
  const [errorMax, setErrorMax] = useState(false);

  useEffect(() => {
    if (!hasLockIcon && !disabled && value && formType !== 'finanzierungsRechner') {
      setShowResetIcon(true);
    }
  }, []);

  useEffect(() => {
    if (formType !== 'finanzierungsRechner') {
      if (String(value) === String(minValue)) {
        setShowResetIcon(false);
      } else if (!hasLockIcon && !disabled && value) {
        setShowResetIcon(true);
      } else {
        setShowResetIcon(false);
      }
    }
  }, [value, disabled]);

  // validate min & max value
  useEffect(() => {
    if (formType !== 'finanzierungsRechner') {
      let minInt = parseInt(minValue);
      let maxInt = parseInt(maxValue);

      // validate min + max
      if (maxInt > 0 && parseInt(value) > maxInt) {
        setErrorMax(true);
        setErrorMin(false);
      } else {
        setErrorMax(false);
      }

      if (minInt > 0 && parseInt(value) < minInt) {
        setErrorMin(true);
        setErrorMax(false);
      } else {
        setErrorMin(false);
      }

      if (minInt === 0) {
        setErrorMin(false);
      }

      if (maxInt === 0) {
        setErrorMax(false);
      }
    }
  }, [value, minValue, maxValue]);

  useEffect(() => {
    setList([]);
    if (typing) {
      const typingTimeout = setTimeout(() => {
        setTyping(false);
      }, 500);

      return () => clearTimeout(typingTimeout);
    } else {
      if (callAutocompleteVal) {
        if (formType === 'searchForm') {
          getLocationsAndUpdateList(callAutocompleteVal);
        } else if (formType === 'searchProfileForm' || formType === 'contactInformation') {
          if (callAutocompleteVal.length === 1) {
            dispatch(setDistrictError('Bitte geben Sie mehr Zeichen ein.'));
          } else {
            dispatch(setDistrictError(''));

            let currentState = {};
            if (formType === 'searchProfileForm') {
              currentState = currentSearchProfileForm.requestBody.location.location_ids[0];
            } else if (formType === 'contactInformation') {
              currentState = currentContactInformation.stateObject;
            }

            getAndSetAutocompleteValue(callAutocompleteVal, currentState);
          }
        } else if (formType === 'wohnwertLight' || formType === 'marktinfoLight') {
          let currentState = {};
          if (formType === 'wohnwertLight') {
            currentState = currentWohnwertLightForm.federalState;
          }

          let foundPostCodes = [];
          let filteredPostCodes = [];

          if (formType === 'marktinfoLight' && !currentState?.id) {
            // marktinfoLight Käufer - has no state, show all zips
            foundPostCodes = statesWithZips
              .filter((state) => state.label !== 'TESTACCOUNT')
              .map((state) => state.zipCodes)
              .flat();
          } else {
            foundPostCodes = statesWithZips.filter((state) => state.label !== 'TESTACCOUNT').find((state) => state.priceHubbleId === currentState.id)?.zipCodes || [];
          }

          if (foundPostCodes.length > 0) {
            filteredPostCodes = foundPostCodes
              .filter((zip) => zip.includes(callAutocompleteVal))
              .map((postCode) => {
                return { label: postCode, value: postCode };
              });
          }

          if (filteredPostCodes.length === 0) {
            filteredPostCodes = [{ value: '', label: 'Keine Ergebnisse gefunden' }];
          }

          setList(filteredPostCodes);
          setCallAutocompleteVal(false);
        }
      }
    }
  }, [typing]);

  const getAndSetAutocompleteValue = async (val, currentState) => {
    try {
      let autoCompleteResults = await autocomplete(val);
      autoCompleteResults = autoCompleteResults.filter((r) => r.type === 5);
      const foundPostCodes = statesWithZips.filter((state) => state.label !== 'TESTACCOUNT').find((state) => state.justImmoId === currentState.id)?.zipCodes || [];

      var isNumberReg = /^\d+$/;
      if (isNumberReg.test(val)) {
        const filteredValues = foundPostCodes.filter((zip) => zip.includes(val));

        autoCompleteResults = autoCompleteResults.filter((r) => {
          let label = r.label;
          let zip = label.split(' ')[0];
          if (filteredValues.includes(zip)) {
            return r;
          }
        });
      } else {
        autoCompleteResults = autoCompleteResults.filter((a) => {
          let label = a.label;
          let zip = label.split(' ')[0];
          if (foundPostCodes.includes(zip)) {
            return a;
          }
        });
      }

      if (autoCompleteResults.length === 0) {
        autoCompleteResults = [{ value: '', label: 'Keine Ergebnisse gefunden' }];
      }

      setList(autoCompleteResults);
      setCallAutocompleteVal(false);
    } catch (e) {
      // handle autocomplete error
      console.error('e', e);
      setCallAutocompleteVal(false);
    }
  };

  const getLocationsAndUpdateList = async (val) => {
    try {
      let locations = await getLocations(val);
      locations = locations.filter((option) => {
        if (option.crm_id && option.crm_id !== '' && option.value.indexOf('n_') > -1) {
          if (option.value.indexOf('immobilie') > -1) {
            return option;
          }
        } else {
          return option;
        }
      });

      let resListWithoutOptgroup = locations.filter((option) => option.value.indexOf('optgroup') === -1);
      if (resListWithoutOptgroup.length === 0) {
        locations = [{ value: '', label: 'Keine Ergebnisse gefunden' }];
      }

      setList(locations);
      setCallAutocompleteVal(false);
    } catch (e) {
      // Handle error
      console.error('e', e);
      setCallAutocompleteVal(false);
    }
  };

  //disable scrolling in input fileds
  const blurActiveElement = (event) => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', blurActiveElement);
    return () => window.removeEventListener('wheel', blurActiveElement);
  }, []);

  const handleListChange = (option) => {
    if (option.value === '') {
      handleResetList();
    } else {
      if (formType === 'searchForm') {
        dispatch(setAddressObjectSearchForm(option));
        setList([]);
        if (option.label) {
          dispatch(setAddressSearchForm(option.label));
          if (option.crm_id && option.crm_id !== '' && option.value.indexOf('n_') > -1) {
            let immoUrl = option.value.replace('n_/de/', '');
            immoUrl += '/' + option.crm_id;
            navigate(app.root + '/' + immoUrl);
          }
        }
      } else if (formType === 'contactInformation') {
        let label = option.label;
        let zip = label.split(' ')[0];
        dispatch(setPostalCodeContactForm(zip));
        dispatch(setDisplayedDistrictNameContactForm(label));
        setList([]);
      } else if (formType === 'wohnwertLight') {
        option = {
          id: parseInt(option.value),
          text: option.label,
        };
        dispatch(setWohnwertLightDistrict(option));
        dispatch(setWohnwertLightTypedPostCode(option.text));
        setList([]);
      } else if (formType === 'marktinfoLight') {
        option = {
          id: parseInt(option.value),
          text: option.label,
        };
        dispatch(setMarktinfoLightZip(option));
        dispatch(setMarktinfoLightTypedZip(option.text));
        setList([]);
      }
    }
  };

  const handleResetList = () => {
    setList([]);
  };

  const listRef = useRef(null);
  useOutsideAlerter(listRef, handleResetList);

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleBlur = ({ id }) => {
    if (formType === 'searchForm') {
      const address = currentSearchForm.address;
      const addressObject = currentSearchForm.requestBody.addressObject;
      let addressObjectLabel = '';
      if (addressObject.label) {
        addressObjectLabel = addressObject.label;
      }

      if (addressObjectLabel === '') {
        dispatch(setAddressSearchForm(''));
      } else if (address !== '' && addressObjectLabel !== '') {
        // if address is different then addressObject in storage - reset to addressObjectlLabel
        dispatch(setAddressSearchForm(addressObjectLabel));
      } else if (address === '') {
        dispatch(setAddressObjectSearchForm({}));
      }
    } else if (formType === 'contactInformation') {
      let postalCode = currentContactInformation.requestBody.postalCode;
      let displayedDistrictName = currentContactInformation.displayedDistrictName;
      if (postalCode === '') {
        dispatch(setDisplayedDistrictNameContactForm(''));
      } else if (postalCode !== displayedDistrictName) {
        dispatch(setDisplayedDistrictNameContactForm(postalCode));
      }
    } else if (formType === 'wohnwertLight') {
      let district = currentWohnwertLightForm.district;
      let typedPostCode = currentWohnwertLightForm.typedPostCode;
      if (!district || !district.id) {
        dispatch(setWohnwertLightTypedPostCode(''));
      } else if (district.text !== typedPostCode) {
        dispatch(setWohnwertLightTypedPostCode(district.text));
      }
    } else if (formType === 'marktinfoLight') {
      let zip = currentMarktinfoLight.zip;
      let typedZip = currentWohnwertLightForm.typedZip;
      if (!zip || !zip.id) {
        dispatch(setMarktinfoLightTypedZip(''));
      } else if (zip.text !== typedZip) {
        dispatch(setMarktinfoLightTypedZip(zip.text));
      }
    } else if (formType === 'finanzierungsRechner') {
      let inputValue = '';

      switch (id) {
        case 'inputLoanValue':
          inputValue = currentFinanzierungsRechner.inputLoanValue;
          if (inputValue === '' || inputValue < minValue) {
            inputValue = minValue;
          } else if (inputValue > maxValue) {
            inputValue = maxValue;
          }
          dispatch(setInputLoanValueFinanzForm(new Intl.NumberFormat('de-DE').format(inputValue.toString())));
          dispatch(setLoanValueFinanzForm(inputValue));
          break;
        case 'inputLoanTime':
          inputValue = currentFinanzierungsRechner.inputLoanTime;
          if (inputValue === '' || inputValue < minValue) {
            inputValue = minValue;
          } else if (inputValue > maxValue) {
            inputValue = maxValue;
          } else {
            inputValue = roundToClosest(+inputValue);
          }
          dispatch(setInputLoanTimeFinanzForm(inputValue));
          dispatch(setLoanTimeFinanzForm(inputValue));
          break;
        default:
          break;
      }
    }
  };

  const roundToClosest = (num) => {
    var rest = num % 5;
    if (rest <= 2) {
      return num - rest;
    } else {
      return num + (5 - rest);
    }
  };

  const handleReset = () => {
    handleInputChange('');
    setErrorMin(false);
    setErrorMax(false);
  };

  const handleSliderInputChange = (e, id) => {
    if (formType === 'finanzierungsRechner') {
      let inputValue = e.target.value;

      if (!isNaN(inputValue)) {
        switch (id) {
          case 'inputLoanValue':
            dispatch(setInputLoanValueFinanzForm(inputValue));
            break;
          case 'inputLoanTime':
            dispatch(setInputLoanTimeFinanzForm(inputValue));
            break;
          default:
            break;
        }
      }
    }
  };

  const handleInputChange = (val) => {
    // if val under 0 - change val to positive number
    if (minValue === 0 && type === 'number' && parseInt(val) < 0) {
      val = val.replace(/[-]/g, '');
    }

    if (callback) {
      callback(val);
    } else {
      setTyping(true);
      if (formType === 'contactInformation') {
        switch (id) {
          case 'inputTitleBefore':
            dispatch(setTitleBeforeContactForm(val));
            break;
          case 'inputTitleAfter':
            dispatch(setTitleAfterContactForm(val));
            break;
          case 'inputFirstName':
            dispatch(setFirstNameContactForm(val));
            break;
          case 'inputLastName':
            dispatch(setLastNameContactForm(val));
            break;
          case 'inputPostalCode':
            dispatch(setDisplayedDistrictNameContactForm(val));
            setCallAutocompleteVal(val);

            if (!val) {
              dispatch(setPostalCodeContactForm(''));
            }
            break;
          case 'inputEmail':
            dispatch(setEmailContactForm(val));
            break;
          case 'inputPhoneNumber':
            dispatch(setParsedPhoneNumberContactForm(val.replace(/[\D]/g, '')));
            dispatch(setPhoneNumberContactForm(val.replace(/[\s\D]/g, '')));
            break;
          case 'inputArea':
            dispatch(setAreaContactForm(val));
            break;
          case 'inputFederalState':
            dispatch(setStateContactForm(val));
            break;
          case 'inputPassword':
            dispatch(setPasswordContactForm(val));
            break;
          case 'inputPasswordConfirm':
            dispatch(setPasswordConfirmContactForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'searchForm') {
        switch (id) {
          case 'inputAddress':
            dispatch(setAddressSearchForm(val));
            setCallAutocompleteVal(val);
            if (!val) {
              dispatch(setAddressObjectSearchForm({}));
            }
            break;
          case 'inputPriceFrom':
            dispatch(setPriceFromSearchForm(val));
            break;
          case 'inputPriceTo':
            dispatch(setPriceToSearchForm(val));
            break;
          case 'inputAreaFrom':
            dispatch(setAreaFromSearchForm(val));
            break;
          case 'inputAreaTo':
            dispatch(setAreaToSearchForm(val));
            break;
          case 'inputSurfaceAreaFrom':
            dispatch(setSurfaceAreaFromSearchForm(val));
            break;
          case 'inputSurfaceAreaTo':
            dispatch(setSurfaceAreaToSearchForm(val));
            break;
          case 'inputRoomsFrom':
            dispatch(setRoomsFromSearchForm(val));
            break;
          case 'inputRoomsTo':
            dispatch(setRoomsToSearchForm(val));
            break;
          case 'inputFullText':
            dispatch(setFullTextSearchForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'loginForm') {
        switch (id) {
          case 'inputEmail':
            dispatch(setEmailLoginForm(val));
            break;
          case 'inputPassword':
            dispatch(setPasswordLoginForm(val));
            break;
          case 'inputToken':
            dispatch(setTokenLoginForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'registerForm') {
        switch (id) {
          case 'inputTitleBefore':
            dispatch(setTitleBeforeRegisterForm(val));
            break;
          case 'inputTitleAfter':
            dispatch(setTitleAfterRegisterForm(val));
            break;
          case 'inputFirstName':
            dispatch(setFirstNameRegisterForm(val));
            break;
          case 'inputLastName':
            dispatch(setLastNameRegisterForm(val));
            break;
          case 'inputEmail':
            dispatch(setEmailRegisterForm(val));
            break;
          case 'inputPhoneNumber':
            dispatch(setParsedPhoneNumberRegisterForm(val.replace(/[\D]/g, '')));
            dispatch(setPhoneNumberRegisterForm(val.replace(/[\s\D]/g, '')));
            break;
          case 'inputPassword':
            dispatch(setPasswordRegisterForm(val));
            break;
          case 'inputPasswordConfirm':
            dispatch(setPasswordConfirmRegisterForm(val));
            break;
          case 'inputToken':
            dispatch(setTokenRegisterForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'registerFormChangePhoneNumber') {
        switch (id) {
          case 'inputPhoneNumber':
            dispatch(setChangedParsedPhoneNumber(val));
            break;
          case 'inputPhoneNumberCheck':
            dispatch(setChangedParsedPhoneNumberCheck(val));
            break;
          default:
            break;
        }
      } else if (formType === 'wohnwertLight') {
        switch (id) {
          case 'realEstateSize':
            dispatch(setWohnwertLightRealEstateSize(val));
            break;
          case 'inputDistrict':
            dispatch(setWohnwertLightTypedPostCode(val));
            setCallAutocompleteVal(val);

            if (!val) {
              dispatch(setWohnwertLightDistrict({}));
            }
            break;
          default:
            break;
        }
      } else if (formType === 'marktinfoLight') {
        switch (id) {
          case 'inputStreet':
            dispatch(setMarktinfoLightStreet(val));
            break;
          case 'inputZip':
            dispatch(setMarktinfoLightTypedZip(val));
            setCallAutocompleteVal(val);

            if (!val) {
              dispatch(setMarktinfoLightZip({}));
            }
            break;
          default:
            break;
        }
      } else if (formType === 'marktBericht') {
        switch (id) {
          case 'inputStreet':
            dispatch(setMarktBerichtStreet(val));
            break;
          case 'inputNumber':
            dispatch(setMarktBerichtNumber(val));
            break;
          case 'inputZip':
            dispatch(setMarktBerichtZip(val));
            break;
          case 'inputCity':
            dispatch(setMarktBerichtCity(val));
            break;
          default:
            break;
        }
      } else if (formType === 'searchProfileForm') {
        switch (id) {
          case 'inputName':
            dispatch(setNameSPForm(val));
            break;
          case 'inputPriceFrom':
            dispatch(setPriceFromSPForm(val));
            break;
          case 'inputPriceTo':
            dispatch(setPriceToSPForm(val));
            break;
          case 'inputLivingAreaFrom':
            dispatch(setLivingAreaFromSPForm(val));

            dispatch(setSiteAreaFromSPForm(''));
            dispatch(setSiteAreaToSPForm(''));
            break;
          case 'inputLivingAreaTo':
            dispatch(setLivingAreaToSPForm(val));

            dispatch(setSiteAreaFromSPForm(''));
            dispatch(setSiteAreaToSPForm(''));
            break;
          case 'inputSiteAreaFrom':
            dispatch(setSiteAreaFromSPForm(val));

            dispatch(setLivingAreaFromSPForm(''));
            dispatch(setLivingAreaToSPForm(''));
            break;
          case 'inputSiteAreaTo':
            dispatch(setSiteAreaToSPForm(val));

            dispatch(setLivingAreaFromSPForm(''));
            dispatch(setLivingAreaToSPForm(''));
            break;
          case 'inputRoomsFrom':
            dispatch(setRoomsFromSPForm(val));
            break;
          case 'inputFullText':
            dispatch(setFullTextSPForm(val));
            break;
          case 'inputTitlePre':
            dispatch(setTitlePreSPForm(val));
            break;
          case 'inputTitlePost':
            dispatch(setTitlePostSPForm(val));
            break;
          case 'inputFirstName':
            dispatch(setFirstNameSPForm(val));
            break;
          case 'inputLastName':
            dispatch(setLastNameSPForm(val));
            break;
          case 'inputEmail':
            dispatch(setEmailSPForm(val));
            break;
          case 'inputPhoneNumber':
            dispatch(setParsedPhoneNumberSPForm(val.replace(/[\D]/g, '')));
            dispatch(setPhoneSPForm(val.replace(/[\s\D]/g, '')));
            break;
          case 'inputPassword':
            dispatch(setPasswordSPForm(val));
            break;
          case 'inputPasswordConfirm':
            dispatch(setPasswordConfirmSPForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'finanzierungsRechner') {
        switch (id) {
          case 'inputLoanValue':
            val = Math.max(Number(minValue), Math.min(Number(maxValue), Number(val)));
            dispatch(setInputLoanValueFinanzForm(new Intl.NumberFormat('de-DE').format(val.toString())));
            dispatch(setLoanValueFinanzForm(Number(val).toString()));
            break;
          case 'inputLoanTime':
            val = Math.max(Number(minValue), Math.min(Number(maxValue), Number(val)));
            dispatch(setInputLoanTimeFinanzForm(val));
            dispatch(setLoanTimeFinanzForm(Number(val).toString()));
            break;
          default:
            break;
        }
      } else if (formType === 'objectRequestForm') {
        switch (id) {
          case 'inputTitlePre':
            dispatch(setTitleBeforeObjectRequest(val));
            break;
          case 'inputTitlePost':
            dispatch(setTitleAfterObjectRequest(val));
            break;
          case 'inputFirstName':
            dispatch(setFirstNameObjectRequest(val));
            break;
          case 'inputLastName':
            dispatch(setLastNameObjectRequest(val));
            break;
          case 'inputEmail':
            dispatch(setEmailObjectRequest(val));
            break;
          case 'inputPhoneNumber':
            dispatch(setParsedPhoneNumberObjectRequest(val.replace(/[\D]/g, '')));
            dispatch(setPhoneObjectRequest(val.replace(/[\s\D]/g, '')));
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className={`${cssClasses} form__field`}>
      <label htmlFor={id}>
        {label} {!!required ? '*' : ''}
      </label>
      <div className={`${hasSlider ? 'form__field--slider-container' : null}`}>
        {hasSlider && (
          <div className="slider-wrapper">
            <div>
              {sliderLabels.map((label, index) => {
                return (
                  <span key={index} className="slider-label">
                    {label}
                  </span>
                );
              })}
            </div>
            <Slider marks={hasMarks} value={sliderValue} min={minValue} max={maxValue} step={stepValue} onChange={(e) => handleInputChange(e.target.value)} />
          </div>
        )}
        <div
          className={`input-wrapper
              ${hasLockIcon && disabled ? 'locked' : ''} 
              ${callAutocompleteVal.length > 1 ? 'input--loading' : ''}
              ${showResetIcon ? 'formfield--withreseticon' : ''}
            `}
        >
          <input
            className={`input ${showFurtherInfoText === 'currency' ? 'input--currency' : ''} ${showFurtherInfoText === 'years' ? 'input--years' : ''}`}
            disabled={disabled}
            id={id}
            data-formtype={formType}
            name={id}
            min={minValue}
            max={maxValue}
            type={type}
            required={required}
            placeholder={placeholder}
            onFocus={handleFocus}
            value={value}
            onChange={
              !hasSlider
                ? (e) => handleInputChange(patternText === true ? e.target.value.replace(/[^A-Za-zöäüßÖÄÜ\ \-\/\.]/gi, '') : e.target.value)
                : (e) => {
                    handleSliderInputChange(e, id);
                  }
            }
            onBlur={(e) => handleBlur({ id: id })}
          />
          {showResetIcon ? (
            <span className="form__field--reset" onClick={() => handleReset()}>
              <IconClose color={'#313a45'} />
            </span>
          ) : null}
        </div>

        {hasList && list.length > 0 && (
          <div className="input-list" ref={listRef}>
            <ul>
              <>
                {list.map((option) => {
                  let value = option.value;
                  if (value.includes('optgroup_')) {
                    return (
                      <li key={value} className="input-list__headline">
                        {option.label}
                      </li>
                    );
                  } else {
                    return (
                      <li key={value} onClick={() => handleListChange(option)}>
                        {option.label}
                      </li>
                    );
                  }
                })}
              </>
            </ul>
          </div>
        )}
      </div>
      {error ? (
        <p className={!errorTextWhite ? 'form__fielderror' : 'text-white'}>{error}</p>
      ) : (
        <>
          {errorMin && <p className="form__fielderror">Der Wert muss größer als {minValue} sein.</p>}
          {errorMax && <p className="form__fielderror">Der Wert muss kleiner als {maxValue} sein.</p>}
        </>
      )}
    </div>
  );
}

export default FormInput;
