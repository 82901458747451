//*React

//*Components
import WeitereAktivitaetenContent from '../components/ContentBlocks/WeitereAktivitaetenContent';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

//*Icons
import IconAnnouncment from '../components/Icons/IconAnnouncment';

import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';

function WeitereAktivitaeten() {

  const { isMobile } = useHandleScreenResize();
  return (
    <>
      <MetaTags
        title={'Weitere Aktivitäten | Die Online-Plattform für Ihre Immobilie'}
      />

      <HeaderSmallWithIcon
        icon={<IconAnnouncment />}
        iconActive={true}
        headlineDesktop="Weitere Aktivitäten"
        overlappedContent={!isMobile}
      />

      <MainContent cssClasses="w-desktop--center">
        <h1 className='desktop-hidden'>Weitere Aktivitäten</h1>
        <WeitereAktivitaetenContent containerSelector={'#weitere-cards'} />
        
      </MainContent>
    </>
  );
}

export default WeitereAktivitaeten;
