import React, { useEffect, useState } from 'react';
import '../styles/stickybutton.scss';
import IconChat from './Icons/IconChat3';
import { useDispatch } from 'react-redux';
import { setPageDialogOpenContactForm, setReasonContactForm } from '../reducers/contactInformation';
import { anliegenOptions } from './FormFields/util/formOptions';
import { CONTACTFORM_ANLIEGEN_SUPPORT } from '../constants';

function StickyButton({ cssClasses }) {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('up');
  const dispatch = useDispatch();

  if (!cssClasses) cssClasses = '';

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const progress = (scrollTop / scrollHeight) * 100;
      setScrollProgress(progress);

      if (scrollTop > prevScrollTop) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      setPrevScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollTop]);

  const openForm = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_SUPPORT]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  return (
    <div className={`stickyButton ${cssClasses} ${scrollDirection === 'down' ? 'scrolled' : ''}`} onClick={openForm}>
      <IconChat />
      {scrollDirection === 'up' ? <span>Support</span> : null}
    </div>
  );
}

export default StickyButton;
