import { useNavigate, useLocation } from 'react-router-dom';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import IconPageNotFound from '../components/Icons/IconPageNotFound';
import MainContent from '../components/MainContent';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Linkitem from '../components/Linkitems/Linkitem';

import MetaTags from '../components/MetaTags';
import { useEffect } from 'react';
import IconHouse from '../components/Icons/IconHouse';

function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  let app = useSelector((state) => state.app);

  useEffect(() => {
    if (location.search.includes('redirect=true')) {
      setShouldRedirect(true);
    }
  }, [location]);

  return (
    <>
      <MetaTags title={'Seite nicht gefunden | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon icon={<IconPageNotFound color="#3679ec" cssClasses="p-10" />} cssClasses="background-stone" iconActive={false} headlineDesktop="Diese Immobilie ist nicht mehr verfügbar" />

      <MainContent cssClasses="w-desktop--center">
        <h1 className="desktop-hidden">Diese Immobilie ist nicht mehr verfügbar</h1>
        <Linkitem
          handleLink={() => navigate(app.root + '/immobiliensuche')}
          icon={<IconHouse />}
          cssClasses={`linkitem--blue linkitem--einzeilig-desktop`}
          headline="Finden Sie weitere Immobilien in unserer Immobiliensuche."
          text="Hier werden Sie fündig!"
        />
      </MainContent>
    </>
  );
}

export default PageNotFound;
