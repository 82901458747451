function IconSpeechBubble({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="81.655" height="71.526" viewBox="0 0 81.655 71.526">
      <g id="Gruppe_13227" data-name="Gruppe 13227" transform="translate(-539.759 -2764.646)">
        <path
          id="Pfad_8362"
          data-name="Pfad 8362"
          d="M619.414,2820.667H578.9l-13.505,13.505v-13.505H541.759v-54.021h77.655Z"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="4"
        />
        <line id="Linie_4645" data-name="Linie 4645" x2="30" transform="translate(558.759 2783.173)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" strokeWidth="4" />
        <line id="Linie_4646" data-name="Linie 4646" x2="44" transform="translate(558.759 2793.173)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" strokeWidth="4" />
        <line id="Linie_4647" data-name="Linie 4647" x2="44" transform="translate(558.759 2804.173)" fill="none" stroke={color} stroke-linecap="round" stroke-linejoin="round" strokeWidth="4" />
      </g>
    </svg>
  );
}

export default IconSpeechBubble;
