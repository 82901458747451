const fetchErrorText = 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es nochmal oder laden Sie die Seite neu.';
const tooManySearchProfilesErrorText = 'Sie haben bereits 3 Suchprofile angelegt. Bitte deaktivieren Sie ein bestehendes Suchprofil, um ein neues anzulegen.';
const searchProfileNameAlreadyExistsErrorText = 'Ein Suchprofil mit diesem Namen existiert bereits. Bitte wählen Sie einen anderen Namen.';
const immoSearchErrorText = 'Die Immobiliensuche ist derzeit leider nicht erreichbar. Bitte versuchen Sie es in Kürze erneut.';
const immoSearchNoResultsText = 'Keine Ergebnisse gefunden. Bitte erweitern Sie Ihre Filtereinstellungen für mehr Ergebnisse.';
const neubauprojekteSearchNoResultsText = 'Keine Neubauprojekte gefunden.';
const registerUsernameAlreadyExists = 'Diese E-Mail-Adresse ist bereits registriert.';
const directRegPending = 'Ihre E-Mail-Adresse wurde bereits zur Registrierung eingeladen. Sie haben soeben eine neue Bestätigungsmail bekommen. Bitte überprüfen Sie Ihr E-Mail-Postfach.';

export {
  fetchErrorText,
  immoSearchErrorText,
  tooManySearchProfilesErrorText,
  searchProfileNameAlreadyExistsErrorText,
  immoSearchNoResultsText,
  registerUsernameAlreadyExists,
  directRegPending,
	neubauprojekteSearchNoResultsText,
};
