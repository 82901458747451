import React from 'react';

function IconMagnifyingGlassWithHouse({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 16093" xmlns="http://www.w3.org/2000/svg" width="47.94" height="47.915" viewBox="0 0 47.94 47.915">
      <g data-name="Gruppe 15906">
        <g data-name="Gruppe 15340">
          <path
            data-name="Pfad 8778"
            d="M47.416,45.02,35.737,33.341a20.049,20.049,0,0,0,4.792-13.077,20.278,20.278,0,1,0-7.187,15.473L45.02,47.416a1.687,1.687,0,0,0,2.4,0,1.63,1.63,0,0,0,0-2.4M3.394,20.264a16.87,16.87,0,0,1,33.74,0,17.1,17.1,0,0,1-4.891,11.879,17.1,17.1,0,0,1-11.879,4.891,16.787,16.787,0,0,1-16.97-16.77"
            fill={color}
          />
        </g>
      </g>
      <g data-name="Gruppe 15907" transform="translate(8.779 9.454)">
        <g data-name="Gruppe 15889" transform="translate(0 0)">
          <path
            data-name="Pfad 10344"
            d="M23.236,11.16,19.862,7.786,12.333.257a.8.8,0,0,0-1.173,0L3.63,7.835.257,11.208a.8.8,0,0,0,0,1.173.9.9,0,0,0,.587.244.9.9,0,0,0,.587-.244l1.907-1.907v8.849a.857.857,0,0,0,.831.831H19.276a.857.857,0,0,0,.831-.831v-8.9l1.907,1.907a.8.8,0,0,0,1.173,0,.763.763,0,0,0,.049-1.173m-4.791,7.285H12.577v-4.2a.831.831,0,1,0-1.662,0v4.2H5.048V8.764l6.7-6.7,6.7,6.7Z"
            transform="translate(0 0)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconMagnifyingGlassWithHouse;
