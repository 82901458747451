import postcodeData from './postcodeBoundaries.json';

export default function findAdjacentPostcodes(targetPostcodes, tolerance = 0.0001) {
  const targetArray = Array.isArray(targetPostcodes) ? targetPostcodes : [targetPostcodes];
  const resultSet = new Set(targetArray);
  const targetAreas = targetArray.map((postcode) => postcodeData.find((area) => area.officialId === postcode)).filter((area) => area !== undefined);

  if (targetAreas.length === 0) return Array.from(resultSet);

  postcodeData.forEach((area) => {
    if (resultSet.has(area.officialId)) return;

    const isAdjacent = targetAreas.some((targetArea) => hasSharedBoundary(targetArea.coordinates, area.coordinates, tolerance));

    if (isAdjacent) {
      resultSet.add(area.officialId);
    }
  });

  return Array.from(resultSet).sort();
}

function hasSharedBoundary(coords1, coords2, tolerance) {
  for (let i = 0; i < coords1.length; i++) {
    const point1 = coords1[i];
    const next1 = coords1[(i + 1) % coords1.length];

    for (let j = 0; j < coords2.length; j++) {
      const point2 = coords2[j];
      const next2 = coords2[(j + 1) % coords2.length];

      if (isPointNearby(point1, point2, tolerance) && isPointNearby(next1, next2, tolerance)) {
        return true;
      }

      if (isPointNearby(point1, next2, tolerance) && isPointNearby(next1, point2, tolerance)) {
        return true;
      }

      if (doLinesIntersect(point1, next1, point2, next2)) {
        return true;
      }
    }
  }
  return false;
}

function isPointNearby(point1, point2, tolerance) {
  return Math.abs(point1.lat - point2.lat) < tolerance && Math.abs(point1.lng - point2.lng) < tolerance;
}

function doLinesIntersect(p1, p2, p3, p4) {
  const det = (p2.lng - p1.lng) * (p4.lat - p3.lat) - (p4.lng - p3.lng) * (p2.lat - p1.lat);

  if (det === 0) return false;

  const lambda = ((p4.lat - p3.lat) * (p4.lng - p1.lng) + (p3.lng - p4.lng) * (p4.lat - p1.lat)) / det;
  const gamma = ((p1.lat - p2.lat) * (p4.lng - p1.lng) + (p2.lng - p1.lng) * (p4.lat - p1.lat)) / det;

  return 0 < lambda && lambda < 1 && 0 < gamma && gamma < 1;
}
