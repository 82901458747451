function IconBuildings({ cssClasses, color }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="33"
      height="40"
      viewBox="0 0 33 40"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rechteck_3038"
            data-name="Rechteck 3038"
            width="33"
            height="40"
            transform="translate(0 0.482)"
            fill={color}
          />
        </clipPath>
      </defs>
      <g data-name="Gruppe 7276" transform="translate(0.272 -0.455)">
        <g data-name="Gruppe 6438" transform="translate(-0.272 -0.027)" clipPath="url(#clip-path)">
          <path
            data-name="Pfad 6362"
            d="M25.9,0H8.852A.852.852,0,0,0,8,.852V1.7H19.078a.852.852,0,0,1,0,1.7H8v1.7h7.669a.852.852,0,1,1,0,1.7H8v1.7h5.965a.852.852,0,1,1,0,1.7H8v1.7h5.965a.854.854,0,0,1,.852.852V40.9h1.7V34.938a.852.852,0,0,1,.852-.852h5.113a.854.854,0,0,1,.852.852V40.9H25.9a.854.854,0,0,0,.852-.852V.852A.854.854,0,0,0,25.9,0"
            transform="translate(5.943 0.031)"
            fill={color}
          />
          <path
            data-name="Pfad 6363"
            d="M.852,8A.852.852,0,0,0,0,8.852v2.556H7.669a.852.852,0,1,1,0,1.7H0v1.7H5.965a.852.852,0,1,1,0,1.7H0v1.7H4.261a.852.852,0,0,1,0,1.7H0V34.416a.852.852,0,0,0,.852.852H3.409V29.3a.852.852,0,0,1,.852-.852H9.374a.854.854,0,0,1,.852.852v5.965h8.521V8H.852Z"
            transform="translate(0.309 5.665)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconBuildings;
