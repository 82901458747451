function IconDevice({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 13955" xmlns="http://www.w3.org/2000/svg" width="53.809" height="53.809" viewBox="0 0 53.809 53.809">
      <path
        data-name="Pfad 8686"
        d="M51.9,35.917A1.964,1.964,0,0,0,50,37.823v1.906H3.924V9.012h19.17A1.964,1.964,0,0,0,25,7.106,1.964,1.964,0,0,0,23.093,5.2H3.812A3.712,3.712,0,0,0,0,9.012V39.728A3.78,3.78,0,0,0,3.812,43.54H20.179l-2.242,5.829H15.358a1.906,1.906,0,1,0,0,3.812H38.339a1.906,1.906,0,0,0,0-3.812H35.873L33.631,43.54H50a3.78,3.78,0,0,0,3.811-3.812V37.823A1.889,1.889,0,0,0,51.9,35.917M31.725,49.369H21.972l2.354-5.717h5.045Z"
        transform="translate(0 0.629)"
        fill={color}
      />
      <path
        data-name="Pfad 8687"
        d="M44.764,0H33.217A5.741,5.741,0,0,0,27.5,5.717v19.17A5.741,5.741,0,0,0,33.217,30.6H44.764a5.741,5.741,0,0,0,5.717-5.717V5.717A5.741,5.741,0,0,0,44.764,0M46.67,25A1.964,1.964,0,0,1,44.764,26.9H33.217A1.964,1.964,0,0,1,31.312,25V5.717a1.964,1.964,0,0,1,1.906-1.906H44.764A1.964,1.964,0,0,1,46.67,5.717Z"
        transform="translate(3.328)"
        fill={color}
      />
      <path data-name="Pfad 8688" d="M39.011,17.1H37.106a1.906,1.906,0,1,0,0,3.811h1.906a1.906,1.906,0,0,0,0-3.811" transform="translate(4.26 2.07)" fill={color} />
    </svg>
  );
}

export default IconDevice;
