function IconClock({ cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 16068" xmlns="http://www.w3.org/2000/svg" width="25.078" height="25.078" viewBox="0 0 25.078 25.078">
      <g data-name="Gruppe 16066">
        <path
          data-name="Pfad 10397"
          d="M12.539,0A12.539,12.539,0,1,0,25.078,12.539,12.576,12.576,0,0,0,12.539,0m0,23.3A10.763,10.763,0,1,1,23.3,12.539,10.815,10.815,0,0,1,12.539,23.3"
          fill="#2870ed"
        />
        <path
          data-name="Pfad 10398"
          d="M24.076,18.816V14.688a.888.888,0,0,0-1.776,0v4.441a.786.786,0,0,0,.209.575l4.545,5.277a.842.842,0,0,0,.679.313.786.786,0,0,0,.575-.209.9.9,0,0,0,.1-1.254Z"
          transform="translate(-10.649 -6.59)"
          fill="#2870ed"
        />
      </g>
    </svg>
  );
}

export default IconClock;
