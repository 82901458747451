import { createSlice } from '@reduxjs/toolkit';
import { removeHashFromUrl } from '../util/generalUtils';
import { SATISFACTION_SITEINDEX_Q1, SATISFACTION_OPTIONS } from '../constants';

const initialState = {
  siteIndex: SATISFACTION_SITEINDEX_Q1,
  headline: 'Zufrieden mit s REAL?',
  pageDialogOpen: false,
  requestBody: {
    q1: { q: 'Wie zufrieden sind Sie mit der generellen Erfahrung auf my-sreal.at?', text: '', index: -1 },
    q2: { q: 'War die Handhabung und das Interface der Plattform nachvollziehbar und einfach bedienbar?', text: '', index: -1 },
    q3: { q: 'Sind Sie mit dem Umfang des Services auf der Plattform zufrieden?', text: '', index: -1 },
    q4: { q: 'Würden Sie my-sreal.at weiterempfehlen?', text: '', index: -1 },
    q5: { q: 'Hat die zuständige Makler:in ausreichend auf Fragen und Anliegen reagiert?', text: '', index: -1 },
    message1: '',
    message2: '',
  },
};

export const satisfactionFormSlice = createSlice({
  name: 'satisfactionForm',
  initialState,
  reducers: {
    setSiteIndexSatisfactionForm: (state, action) => {
      window.location.hash = action.payload;
      state.siteIndex = action.payload;
    },
    setPageDialogOpenSatisfactionForm: (state, action) => {
      if (action.payload === true) {
        window.location.hash = state.siteIndex;
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setQ1SatisfactionForm: (state, action) => {
      state.requestBody.q1 = { ...state.requestBody.q1, text: action.payload.text, index: action.payload.index };
    },
    setQ2SatisfactionForm: (state, action) => {
      state.requestBody.q2 = { ...state.requestBody.q2, text: action.payload.text, index: action.payload.index };
    },
    setQ3SatisfactionForm: (state, action) => {
      state.requestBody.q3 = { ...state.requestBody.q3, text: action.payload.text, index: action.payload.index };
    },
    setQ4SatisfactionForm: (state, action) => {
      state.requestBody.q4 = { ...state.requestBody.q4, text: action.payload.text, index: action.payload.index };
    },
    setQ5SatisfactionForm: (state, action) => {
      state.requestBody.q5 = { ...state.requestBody.q5, text: action.payload.text, index: action.payload.index };
    },
    setMessage1SatisfactionForm: (state, action) => {
      state.requestBody.message1 = action.payload;
    },
    setMessage2SatisfactionForm: (state, action) => {
      state.requestBody.message2 = action.payload;
    },
    setResetFieldsSatisfactionForm: (state, action) => {
      return initialState;
    },
  },
});

export const { setSiteIndexSatisfactionForm, setPageDialogOpenSatisfactionForm, setResetFieldsSatisfactionForm, setQ1SatisfactionForm, setQ2SatisfactionForm, setQ3SatisfactionForm, setQ4SatisfactionForm, setQ5SatisfactionForm, setMessage1SatisfactionForm, setMessage2SatisfactionForm } = satisfactionFormSlice.actions;

export default satisfactionFormSlice.reducer;
