import { useSelector, useDispatch } from 'react-redux';
import '../../styles/headerhamburger.scss';
import { toggleMobileMenu } from '../../reducers/app';

function HeaderHamburger() {
  let activeMobileMenu = useSelector((state) => state.app.mobileMenuOpen);
  const dispatch = useDispatch();

  const handelToggleMobileMenu = () => {
    if (window.innerWidth <= 1199) {
      dispatch(toggleMobileMenu());
    }
  };

  return (
    <div className={'header__hamburger' + (activeMobileMenu ? ' active' : '')} onClick={handelToggleMobileMenu}>
      <span className="hamburger-line"></span>
      <span className="hamburger-line"></span>
      <span className="hamburger-line"></span>
    </div>
  );
}

export default HeaderHamburger;
