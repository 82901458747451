import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { newsParser } from '../../util/newsParser';
import { teaserTextParser } from '../../util/teaserTextParser';
import Card from './Card';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

// ICONS
import IconSpeechBubble from '../Icons/IconSpeechBubble';
import CardGridPlaceholderElement from './CardGridPlaceholderElement';
import CarouselNew from '../Carousel/CarouselNew';
import { useEffect, useRef, useState } from 'react';
import IconExternalLink from '../Icons/IconExternalLink';

function NewsCardsRenderer({ item, topic }) {
  const [parsedText, setParsedText] = useState('');
  const app = useSelector((state) => state.app);
  const titleRef = useRef(null);

  let cardCssClasses = 'news-card';

  useEffect(() => {
    if (item) trimContent();
  }, [item]);

  const trimContent = () => {
    const title = titleRef.current;
    if (title) {
      const titleHeight = title.offsetHeight;
      let newParsedText = '';
      if (titleHeight < 30) {
        newParsedText = teaserTextParser(item.summary, 180);
      } else if (titleHeight < 60) {
        newParsedText = teaserTextParser(item.summary, 150);
      } else if (titleHeight < 90) {
        newParsedText = teaserTextParser(item.summary, 100);
      } else {
        newParsedText = teaserTextParser(item.summary, 40);
      }

      // Only update if the parsedText is different to avoid infinite loop
      if (newParsedText !== parsedText) {
        setParsedText(newParsedText);
      }
    }
  };

  return item.external ? (
    <a href={item.source} onClick={(event) => app.dragging && event.preventDefault()} title={item.title} className={cardCssClasses} target="_blank" rel="noopener noreferrer">
      <Card
        cardStyle="topImage"
        cssClasses="bg-white with-shadow"
        imagePath={item.header_picture !== '' ? item.header_picture : null}
        icon={item.header_picture === '' ? <IconSpeechBubble color="#fff" /> : null}
      >
        <div className="news-card__label immo-card__label--active">
          <IconExternalLink color="#fff" />
          <span className="text-white">externer Artikel</span>
        </div>
        <div className="card__content__text">
          <p className="font-110 font-weight-600 lh-130" ref={titleRef}>
            {item.title}
          </p>
          <p className="cardMeta">
            {item.published_on ? item.published_on : item.updated_at}
            {topic && item.topics.length > 0 ? ` in ${item.topics}` : ''}
          </p>

          <div className="news-card__teaser">
            <p className="cardText">{parsedText}</p>
          </div>
        </div>
        <span className="card__readmorelink">Mehr lesen</span>
      </Card>
    </a>
  ) : (
    <Link onClick={(event) => app.dragging && event.preventDefault()} to={app.root + '/news/' + item.slug} title={item.title} className={cardCssClasses} target="_self">
      <Card
        cardStyle="topImage"
        cssClasses="bg-white with-shadow"
        imagePath={item.header_picture !== '' ? item.header_picture : null}
        icon={item.header_picture === '' ? <IconSpeechBubble color="#fff" /> : null}
      >
        <>
          <div className="card__content__text">
            <p className="font-110 font-weight-600 lh-130" ref={titleRef}>
              {item.title}
            </p>
            <p className="cardMeta">
              {item.published_on ? item.published_on : item.updated_at}
              {topic && item.topics.length > 0 ? ` in ${item.topics}` : ''}
            </p>

            <div className="news-card__teaser">
              <p className="cardText">{parsedText}</p>
            </div>
          </div>
          <span className="card__readmorelink">Mehr lesen</span>
        </>
      </Card>
    </Link>
  );
}

function NewsCards({ news, selectedFilter, filterOptions, carousel, cssClasses, topic }) {
  const { isMobile } = useHandleScreenResize();
  if (!news || news.length === 0) return null;
  if (!cssClasses) cssClasses = '';

  news = news.map((n) => newsParser(n));

  if (selectedFilter > 0) {
    news = news.filter((item) => {
      let filterSlug = filterOptions[selectedFilter].slug;
      let itemTopicsSlugs = item.topicsArray.map((t) => t.slug);
      if (itemTopicsSlugs.includes(filterSlug)) {
        return item;
      }
    });
  }

  if (!carousel) {
    return (
      <div className={`cardgrid ${cssClasses}`}>
        {news.map((n) => {
          return <NewsCardsRenderer topic={topic} item={n} selectedFilter={selectedFilter} filterOptions={filterOptions} key={n.slug} />;
        })}

        <CardGridPlaceholderElement array={news} />
      </div>
    );
  } else {
    if (news.length > 3) {
      return (
        <CarouselNew isMobile={isMobile} cssClasses={isMobile && 'carousel-wrapper--news-mobile'}>
          {news.map((n) => {
            return <NewsCardsRenderer item={n} selectedFilter={selectedFilter} filterOptions={filterOptions} key={n.slug} />;
          })}
        </CarouselNew>
      );
    } else {
      return (
        <div className="w-desktop--center">
          <div className={`cardgrid ${cssClasses}`}>
            {news.map((n) => {
              return <NewsCardsRenderer item={n} selectedFilter={selectedFilter} filterOptions={filterOptions} key={n.slug} />;
            })}

            <CardGridPlaceholderElement array={news} />
          </div>
        </div>
      );
    }
  }
}

export default NewsCards;
