import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import React from 'react';
import IconArrowLeft from '../Icons/IconArrowLeft';
import IconClose from '../Icons/IconClose';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import '../../styles/dialogs.scss';

/* DOCS
https://mui.com/material-ui/react-dialog/#transitions
*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} mountOnEnter unmountOnExit />;
});

function PageDialog({
  handleClose,
  handleBack,
  headline,
  customHeaderBlue,
  children,
  open,
  showBackArrow,
  cookieDialog,
  cssClasses,
  id
}) {

  const { isMobile } = useHandleScreenResize();
  let fullScreen = true;
  
  if (!isMobile) {
    fullScreen = false;
    if (cookieDialog === true) {
      fullScreen = true;
    }
  }

  let iconColor = '#1E3163';
  if (customHeaderBlue) {
    iconColor = '#fff';
  }
  cssClasses += ' pagedialog';

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={cssClasses}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      scroll="paper"
      id={id}
    >
      <DialogTitle
        className={
          cookieDialog === true
            ? 'desktop-hidden mobile-hidden'
            : customHeaderBlue
            ? 'pagedialog__title--blue'
            : ''
        }
      >
        {showBackArrow && (
          <IconArrowLeft
            color={iconColor}
            height={24}
            cssClasses="pagedialog__back"
            handleClick={handleBack}
          />
        )}
        {headline && headline}
        {customHeaderBlue && customHeaderBlue}
        <IconClose
          color={iconColor}
          width={22}
          cssClasses="pagedialog__close"
          handleClick={handleClose}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

export default PageDialog;
