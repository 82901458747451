function IconPageNotFound({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rechteck_5672" data-name="Rechteck 5672" width="67" height="67" transform="translate(-10951 -183)" fill={color} />
        </clipPath>
      </defs>
      <g id="Gruppe_maskieren_210" data-name="Gruppe maskieren 210" transform="translate(10951 183)" clipPath="url(#clip-path)">
        <path
          id="Pfad_10484"
          data-name="Pfad 10484"
          d="M75.71,36.362,64.718,25.37,40.185.836a2.6,2.6,0,0,0-3.823,0L11.829,25.529.836,36.521a2.6,2.6,0,0,0,0,3.823,2.939,2.939,0,0,0,1.912.8,2.939,2.939,0,0,0,1.912-.8l6.213-6.213V62.966a2.792,2.792,0,0,0,2.708,2.708H62.807a2.792,2.792,0,0,0,2.708-2.708V33.972l6.213,6.213a2.6,2.6,0,0,0,3.823,0,2.487,2.487,0,0,0,.159-3.823M60.1,60.1H40.982V46.4a2.708,2.708,0,1,0-5.416,0V60.1H16.448V28.556L38.273,6.731,60.1,28.556Z"
          transform="translate(-10922.387 -90.448) rotate(-135)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconPageNotFound;
