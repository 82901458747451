import React, { useState } from 'react';
import Card from './Card';
import { useFetchImmo } from '../../hooks/immo/useFetchImmo';
import FilterMenu from '../FilterMenu';
import IconHouse from '../Icons/IconHouse.js';
import IconTraffic from '../Icons/IconTraffic.js';
import IconGastronomie from '../Icons/IconGastronomie';
import IconGesundheit from '../Icons/IconGesundheit';
import IconBildung from '../Icons/IconBildung';
import IconEinkaufen from '../Icons/IconEinkaufen';
import IconSonstiges from '../Icons/IconSonstiges';
import IconLine from '../Icons/IconLine.js';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function InfrastrukturCard() {
  const { immo } = useFetchImmo();
  const stone = '#7598AB';
  const { isMobile } = useHandleScreenResize();

  const [selectedFilter, setFilterIndex] = useState(0);

  const handleSetActiveFilterIndex = (event) => {
    let index = event.target.dataset.index;
    setFilterIndex(index);
  };

  return (
    <>
      <div className="infrastructure-header mt-300rem mb-100rem d-flex justify-content-between">
        <h2 className="mt-0">Infrastruktur</h2>
        {immo.infrastructureList.groups && (
          <FilterMenu cutside={isMobile ? 'right' : 'none'} categories={immo.infrastructureList.groups} activeIndex={selectedFilter} handleClick={handleSetActiveFilterIndex} />
        )}
      </div>
      <Card cardStyle={'customContent'} cssClasses={'card--infrastructure background-lightstone p-200rem'}>
        {immo.infrastructureList.groups && <h3 className="text-white">{immo.infrastructureList.groups[selectedFilter].name}</h3>}
        <div className="infrastructure d-flex justify-content-between">
          <IconLine cssClasses="infrastructure__line infrastructure__line--desktop" />
          <div color="#fff" className="infrastructure__line infrastructure__line--mobile" />
          <div className="infrastructure__standort text-center flex-col align-items-center">
            <span></span>
            <IconHouse color="#fff" />
            <p className="text-white font-weight-600">Standort</p>
          </div>
          <div className="infrastructure__categories">
            {immo.infrastructureList.groups &&
              immo.infrastructureList.groups[selectedFilter].categories
                .sort((a, b) => a.distance - b.distance)
                .map((c) => (
                  <div className="infrastructure__categories__circle-wrapper" key={c.id}>
                    <span className="text-white font-weight-600">{c.distance} m</span>
                    <div className="infrastructure__categories__circle text-center background-white">
                      {immo.infrastructureList.groups[selectedFilter].name == 'Verkehr' ? (
                        <IconTraffic color={stone} />
                      ) : immo.infrastructureList.groups[selectedFilter].name == 'Gesundheit' ? (
                        <IconGesundheit color={stone} />
                      ) : immo.infrastructureList.groups[selectedFilter].name == 'Kinder & Schulen' ? (
                        <IconBildung color={stone} />
                      ) : immo.infrastructureList.groups[selectedFilter].name == 'Nahversorgung' ? (
                        <IconEinkaufen color={stone} />
                      ) : (
                        <IconSonstiges color={stone} />
                      )}
                    </div>
                    <p className="text-white font-weight-600">{c.name}</p>
                  </div>
                ))}
          </div>
        </div>
      </Card>
    </>
  );
}

export default InfrastrukturCard;
