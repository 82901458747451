import React from 'react';
import { useDispatch } from 'react-redux';
import { setPrivacyAggrementContactForm, setCreateUserContactForm } from '../../reducers/contactInformation';

import { setPrivacyPolicyReadSPForm, setWantsToRegisterSPForm } from '../../reducers/searchProfileForm';

import { setNewsletterConfirmedObjectRequest } from '../../reducers/objectRequest';

import { setPrivacyAggrementRegisterForm } from '../../reducers/registerForm';

import { setRememberMe } from '../../reducers/loginForm';
import { setWantsToReceiveMails } from '../../reducers/user';
import { setMarktBerichtIsOwner } from '../../reducers/marktBericht';

const FormCheckbox = ({ id, label, error, value, formType, callback, labelFirst = false, cssClasses, errorTextWhite }) => {
  if (!cssClasses) cssClasses = '';

  const dispatch = useDispatch();

  const handleCheckboxChange = () => {
    if (callback) {
      callback(!value);
    } else {
      if (formType === 'contactInformation') {
        switch (id) {
          case 'inputPrivacyAggrement':
            dispatch(setPrivacyAggrementContactForm(!value));
            break;
          case 'inputCreateUser':
            dispatch(setCreateUserContactForm(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'searchProfileForm') {
        switch (id) {
          case 'privacyPolicy':
            dispatch(setPrivacyPolicyReadSPForm(!value));
            break;
          case 'register':
            dispatch(setWantsToRegisterSPForm(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'loginForm') {
        switch (id) {
          case 'inputRememberMe':
            dispatch(setRememberMe(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'registerForm') {
        switch (id) {
          case 'inputPrivacyAggrement':
            dispatch(setPrivacyAggrementRegisterForm(!value));
            break;

          default:
            break;
        }
      } else if (formType === 'objectRequestForm') {
        switch (id) {
          case 'newsletter':
            dispatch(setNewsletterConfirmedObjectRequest(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'profileEmailSettingsForm') {
        switch (id) {
          case 'emailSettingsWantsToReceiveMails':
            dispatch(setWantsToReceiveMails(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'marktBericht') {
        switch (id) {
          case 'inputIsOwner':
            dispatch(setMarktBerichtIsOwner(!value));
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className={`${cssClasses} form__field form__field--checkbox`}>
      {labelFirst && (
        <label htmlFor={id}>
          {label}
          {error && <p className={!errorTextWhite ? 'form__fielderror' : 'text-white'}>{error}</p>}
        </label>
      )}

      <input
        id={id}
        className="input"
        type="checkbox"
        checked={value}
        onChange={(e) => {
          handleCheckboxChange();
        }}
      />

      {!labelFirst && (
        <label htmlFor={id}>
          {label}
          {error && <p className={!errorTextWhite ? 'form__fielderror' : 'text-white'}>{error}</p>}
        </label>
      )}
    </div>
  );
};

export default FormCheckbox;
