function IconMarktreport({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="42.563"
      height="30.148"
      viewBox="0 0 42.563 30.148"
    >
      <g data-name="Gruppe 10809" transform="translate(0 3.547)">
        <path
          data-name="Pfad 6553"
          d="M9.887,8a.887.887,0,0,0,0,1.773h8.867a.887.887,0,0,0,0-1.773Z"
          transform="translate(6.961 2.641)"
          fill={color}
        />
        <path
          data-name="Pfad 6554"
          d="M2.887,7.773h33.7a.887.887,0,0,0,0-1.773H2.887a.887.887,0,0,0,0,1.773"
          transform="translate(1.547 1.094)"
          fill={color}
        />
        <path
          data-name="Pfad 6555"
          d="M2.887,5.773h33.7a.887.887,0,0,0,0-1.773H2.887a.887.887,0,0,0,0,1.773"
          transform="translate(1.547 -0.453)"
          fill={color}
        />
        <path
          data-name="Pfad 6556"
          d="M2.887,3.773h33.7a.887.887,0,0,0,0-1.773H2.887a.887.887,0,0,0,0,1.773"
          transform="translate(1.547 -2)"
          fill={color}
        />
        <g data-name="Gruppe 9586">
          <path
            data-name="Pfad 6552"
            d="M41.676,8H29.262a.887.887,0,0,0-.887.887v2.66H14.188V8.887A.887.887,0,0,0,13.3,8H.887A.887.887,0,0,0,0,8.887V23.074a.887.887,0,0,0,.887.887H41.676a.887.887,0,0,0,.887-.887V8.887A.887.887,0,0,0,41.676,8"
            transform="translate(0 2.641)"
            fill={color}
          />
          <path
            data-name="Pfad 7497"
            d="M2.887,3.773h33.7a.887.887,0,0,0,0-1.773H2.887a.887.887,0,0,0,0,1.773"
            transform="translate(1.547 -5.547)"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconMarktreport;
