import EnergieSkala from '../EnergieSkala';
import Card from './Card';

/* is this component necessary? */
function EnergieCardMobile({ energy, label1, value1, label2, value2, value3 }) {
	if(!energy) return null;

  let backgroundColor1 = '';
  let backgroundColor2 = '';

  switch (value1) {
    case 'A':
		case 'A+':
		case 'A++':
      backgroundColor1 = 'skala__a';
      break;
    case 'B':
      backgroundColor1 = 'skala__b';
      break;
    case 'C':
      backgroundColor1 = 'skala__c';
      break;
    case 'D':
      backgroundColor1 = 'skala__d';
      break;
    case 'E':
      backgroundColor1 = 'skala__e';
      break;
    case 'F':
      backgroundColor1 = 'skala__f';
      break;
    case 'G':
      backgroundColor1 = 'skala__g';
      break;
		default: 
			backgroundColor2 = 'skala__a';
			break;
  }

  switch (value3) {
    case 'A':
		case 'A+':
		case 'A++':
      backgroundColor2 = 'skala__a';
      break;
    case 'B':
      backgroundColor2 = 'skala__b';
      break;
    case 'C':
      backgroundColor2 = 'skala__c';
      break;
    case 'D':
      backgroundColor2 = 'skala__d';
      break;
    case 'E':
      backgroundColor2 = 'skala__e';
      break;
    case 'F':
      backgroundColor2 = 'skala__f';
      break;
    case 'G':
      backgroundColor2 = 'skala__g';
			break;
		default: 
			backgroundColor2 = 'skala__a';
			break;
  }

  return (
    <Card cardStyle="customContent" cssClasses={'card--energy skala pb-200rem'}>
      <div className="d-flex d-flex--gap justify-content-center mb-100rem">
        <div className={'immodetail__features-wrapper text-white d-flex flex-col text-center ' + backgroundColor1}>
          <span className="immodetail__features--val">{value1}</span>
          <span className="immodetail__features--label">{label1}</span>
        </div>
        <div className={'immodetail__features-wrapper text-white d-flex flex-col text-center ' + backgroundColor2}>
          <span className="immodetail__features--val">{value2}</span>
          <span className="immodetail__features--label">{label2}</span>
        </div>
      </div>
      <EnergieSkala />
    </Card>
  );
}

export default EnergieCardMobile;
