import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Linkitem from '../Linkitems/Linkitem';
import ImmoCardAbgeber from '../Cards/ImmoCardAbgeber';

// ICONS
import IconHouse from '../Icons/IconHouse';

import { getThumbnail } from '../../util/immoObjectParser';
import { anliegenOptions } from '../FormFields/util/formOptions';

import { CONTACTFORM_ANLIEGEN_VERKAUF } from '../../constants';

// STORE
import { setPageDialogOpenContactForm, setReasonContactForm } from '../../reducers/contactInformation';

function ProfileImmobilien() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleWeitereImmos = () => {
    dispatch(setReasonContactForm(anliegenOptions[CONTACTFORM_ANLIEGEN_VERKAUF]));
    dispatch(setPageDialogOpenContactForm(true));
  };

  return (
    <>
      {user && user.userRealties && user.userRealties.length > 0 ? (
        <>
          {user.userRealties.length > 1 ? <h2 className="mt-300rem text-center--mobile">Meine Immobilien</h2> : <h2 className="mt-300rem text-center--mobile">Meine Immobilie</h2>}

          {user.userRealties.map((immo) => {
            let cover_image = getThumbnail(immo.imagesPublic[0], 'small');

            return <ImmoCardAbgeber retailObject={immo} key={immo.id} />;
          })}

          <Linkitem
            cssClasses={'linkitem--blue linkitem--einzeilig-desktop'}
            handleLink={handleWeitereImmos}
            icon={<IconHouse />}
            headline="Weitere Immobilie verkaufen"
            text="Unsere Immobilienexpert:innen beraten Sie gerne"
          />
        </>
      ) : (
        <>
          <h2 className="mt-300rem">Immobilie verkaufen?</h2>
          <Linkitem
            cssClasses={'linkitem--blue linkitem--einzeilig-desktop'}
            handleLink={handleWeitereImmos}
            icon={<IconHouse />}
            headline="Immobilie verkaufen"
            text="Unsere Immobilienexpert:innen beraten Sie gerne"
          />
        </>
      )}
    </>
  );
}

export default ProfileImmobilien;
