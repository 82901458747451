import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import '../styles/impressum-datenschutz.scss';
import { useNavigate } from 'react-router-dom';

// ICONS
import IconDocument from '../components/Icons/IconDocument';
import { useSelector } from 'react-redux';

function Impressum() {
  const navigate = useNavigate();
  const app = useSelector((state) => state.app);

  return (
    <>
      <MetaTags title={'Impressum | Die Online-Plattform für Ihre Immobilie'} description={'my-sreal.at ist eine Plattform der s REAL Immobilienvermittlung GmbH'} />
      <HeaderSmallWithIcon icon={<IconDocument />} headlineDesktop="Impressum und Offenlegung" iconActive={true} />

      <MainContent cssClasses="w-desktop--center impressum">
        <h1 className="headline--mobile">Impressum und Offenlegung</h1>
        <h2 className="mb-20 font-120">my-sreal.at ist eine Plattform der s REAL Immobilienvermittlung GmbH</h2>
        <div>
          <p>
            <span className="font-weight-700">Firma: </span>s REAL Immobilienvermittlung GmbH
          </p>
          <p>
            <span className="font-weight-700">Straße: </span>Landstraßer Hauptstraße 60
          </p>
          <p>
            <span className="font-weight-700">PLZ/Ort: </span>1030 Wien
          </p>
          <p>
            <span className="font-weight-700">Tel.: </span>05 0100 - 26200
          </p>
          <p>
            <span className="font-weight-700">Fax: </span>05 0100 9 - 26200
          </p>
          <p>
            <span className="font-weight-700">E-Mail: </span>
            <a href={'mailto:' + app.companyEmail} title={'E-Mail an ' + app.companyName} target="_blank" rel="noreferrer">
              {app.companyEmail}
            </a>
          </p>
          <p>
            <span className="font-weight-700">Firmenbuchnummer: </span>120121z
          </p>
          <p>
            <span className="font-weight-700">Firmenbuchgericht: </span>Handelsgericht Wien
          </p>
          <p>
            <span className="font-weight-700">Umsatzsteuer-Identifikationsnummer (UID): </span>
            ATU14494108
          </p>
          <p>
            <span className="font-weight-700">Berufsbezeichnung: </span>Beteiligungsverwaltung und Beratung
          </p>
        </div>
        <div className="mt-30">
          <p className="font-weight-700">Kammer/Berufsverband:</p>
          <p>Wirtschaftskammer Österreich, Sektion Information und Consulting Fachgruppe der Immobilien- und Vermögenstreuhänder</p>
          <p>1040 Wien, Wiedner Hauptstraße 63</p>
          <a href="http://www.wko.at" target="_blank">
            http://www.wko.at
          </a>
        </div>
        <div className="mt-30">
          <p>Anwendbare Rechtsvorschriften sind insbesondere das Maklergesetz (MaklerG) sowie die Immobilienmaklerverordnung (IMV) aus 1996</p>
          <a href="http://www.ris.bka.gv.at" target="_blank">
            http://www.ris.bka.gv.at
          </a>
        </div>
        <div className="mt-30">
          <p>Plattform der EU-Kommission zur Online-Streitbeilegung</p>
          <a href="www.ec.europa.eu/consumers/odr" target="_blank">
            www.ec.europa.eu/consumers/odr
          </a>
        </div>
        <div className="mt-30">
          <p>
            <span className="font-weight-700">Aufsichtsbehörde: </span>MA 63
          </p>
          <a href="#" target="_blank">
            Verhaltenskodex gemäß § 7 LobbyG [PDF]
          </a>
        </div>
        <h4 className="mt-40">Impressen der Landesgesellschaften</h4>
        <div>
          <span className="textlink" onClick={() => navigate(`../impressum-kaernten-und-osttirol`)}>
            Impressum Kärtnen/ Osttirol
          </span>
          <br></br>
          <span className="textlink" onClick={() => navigate(`../impressum-steiermark`)}>
            Impressum Steiermark
          </span>
          <br></br>
          <span className="textlink" onClick={() => navigate(`../impressum-tirol`)}>
            Impressum Tirol
          </span>
          <br></br>
          <span className="textlink" onClick={() => navigate(`../impressum-oberoesterreich`)}>
            Impressum Oberösterreich
          </span>
          <br></br>
          <span className="textlink" onClick={() => navigate(`../impressum-vorarlberg`)}>
            Impressum Vorarlberg
          </span>
        </div>
        <h4 className="mt-40">Grundlegende inhaltliche Richtung:</h4>
        <div>
          <p>− Informationen über die s Real Immobilienvermittlung GmbH</p>
          <p>− Informationen über die von der s Real angebotenen Produkte und Service</p>
          <p>− Möglichkeit zur Online-Immobiliensuche</p>
        </div>
        <h4 className="mt-40">Disclaimer</h4>
        <div>
          <p>
            Es wird keinerlei Haftung für die Richtigkeit, Vollständigkeit und Aktualität der Homepage und der Website übernommen, Irrtümer werden vorbehalten. Alle Angebote in der Datenbank sind
            freibleibend und vorbehaltlich Zwischenvermietung und -verkauf. Der Benutzer anerkennt den Gebrauch der Websites auf eigene Gefahr, sodass niemanden, der an der Erstellung der
            Informationen beteiligt war, eine (in-)direkte Haftung für (Folge-) Schäden trifft bzw. diese bis auf das gesetzlich äußerst zulässige Maß beschränkt wird.
          </p>
        </div>
        <h4 className="mt-40">Copyright</h4>
        <div className="mb-80">
          <p>
            Copyright © und für den Inhalt verantwortlich zeichnet Firma s REAL Immobilienvermittlung GesmbH, welche Informationen aus eigenen und fremden Quellen zur Verfügung stellt. Die
            entsprechenden Urheber- und sonstigen Schutzrechte liegen daher gemäß den inländischen und EU-rechtlichen Vorschriften bei der Firma s REAL Immobilienvermittlung GmbH oder den eigens
            genannten sonstigen Anbietern. Die Vervielfältigung von Texten und Daten einschließlich Speicherung und Nutzung auf optischen und elektronischen Datenträgern darf nur mit vorheriger
            schriftlicher Zustimmung erfolgen. Die Verwendung von Daten inklusive Einspeisung in Online-Dienste, Datenbanken oder Websites durch unberechtigte Dritte sowie die Veränderung oder
            Verfälschung von Informationen ist untersagt. Der Download sowie der Ausdruck ist jedoch zum persönlichen, nicht kommerziellen Gebrauch gestattet.
          </p>
        </div>
      </MainContent>
    </>
  );
}

export default Impressum;
