import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageDialogOpenContactForm, setResetFields } from '../../reducers/contactInformation';
import { setResetFormValidator } from '../../reducers/formValidators';
import Card from './Card';

//ICONS
import InfoDialogHotlineKontakt from '../Dialogs/InfoDialogHotlineKontakt';
import IconChat from '../Icons/IconChat3';

import { CONTACTFORM_SITEINDEX_INFO } from '../../constants';

import { removeHashFromUrl } from '../../util/generalUtils';

function ContactFormCard({ cssClasses = '', isFAQ }) {
  if (!cssClasses) cssClasses = 'contact-card';
  else cssClasses += ' contact-card';
  const dispatch = useDispatch();

  const [infoDialogShown, setInfoDialogShown] = useState(false);

  const handleInfoModalDesktop = () => {
    let val = !infoDialogShown;
    if (val) {
      window.location.hash = CONTACTFORM_SITEINDEX_INFO;
    } else {
      removeHashFromUrl();
    }

    setInfoDialogShown(val);
  };

  let currentContactInformation = useSelector((state) => state.contactInformation);
  let app = useSelector((state) => state.app);

  let cardStyle = 'customContent';

  useEffect(() => {
    if (!currentContactInformation.pageDialogOpen) {
      dispatch(setResetFields());
      dispatch(setResetFormValidator());
    }
  }, [currentContactInformation.pageDialogOpen]);

  const contactInformation = [
    <Fragment key={'contactInformation'}>
      <p>Wir beraten Sie gern individuell zu Ihrem Anliegen. Sie erreichen uns direkt vor Ort, über unsere Hotline oder via Mail:</p>
      <h4 className="mt-100rem">s REAL Immobilienvermittlung GmbH</h4>
      <span className="lh-160">
        Landstraßer Hauptstraße 60, 1030 Wien
        <br />
        Tel.:{' '}
        <a className="textlink" href="tel:05010026200" title={app.companyName + ' anrufen'} target="_blank" rel="noreferrer">
          05 0100 - 26200
        </a>
        <br />
        Mail:{' '}
        <a className="textlink" href={'mailto:' + app.companyEmail} title={'E-Mail an ' + app.companyName} target="_blank" rel="noreferrer">
          {app.companyEmail}
        </a>
      </span>
    </Fragment>,
  ];

  return (
    <Fragment>
      <Card
        cssClasses={cssClasses + ' text-center'}
        cardStyle={cardStyle}
        //icon={<IconChat cssClasses={cardStyle == 'topImage2Cols' ? 'scale-1-7' : ''} color="#fff" />}
        //dropdownHeadline="Hotline & Kontakt"
        //dropdownChildren={contactInformation}
        //handleInfoModalDesktop={handleInfoModalDesktop}
      >
        <div className="card__icon">
          <IconChat color="#fff" />
        </div>

        <div className="card__content">
          <div>
            {isFAQ ? <h2 className="mb-100rem text-white">Sie haben noch Fragen?</h2> : <h2 className="mb-100rem text-white">Kontaktaufnahme</h2>}
            <p className="pb-100rem pt-50rem">Wir beraten Sie gerne zu Ihrem Anliegen. Nutzen Sie das Kontaktformular – wir melden uns bei Ihnen!</p>
          </div>
          <div className="mb-100rem">
            <button className="button button--white-outline" onClick={() => dispatch(setPageDialogOpenContactForm(true))}>
              Kontaktieren Sie uns!
            </button>
          </div>
        </div>
      </Card>

      <InfoDialogHotlineKontakt handleClose={handleInfoModalDesktop} open={infoDialogShown} />
    </Fragment>
  );
}

export default ContactFormCard;
