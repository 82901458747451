import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  siteIndex: 0,
  federalState: {},
  district: {},
  inputLoanValue: '250.000',
  loanValue: 250000,
  inputLoanTime: '25',
  loanTime: 25,
  resultingRate: 0,
};

export const finanzierungsRechnerSlice = createSlice({
  name: 'finanzierungsRechner',
  initialState,
  reducers: {
    setSiteIndexFinanzForm: (state, action) => {
      state.siteIndex = action.payload;
    },
    setFederalStateFinanzForm: (state, action) => {
      state.federalState = action.payload;
    },
    setDistrictFinanzForm: (state, action) => {
      state.district = action.payload;
    },
    setInputLoanValueFinanzForm: (state, action) => {
      state.inputLoanValue = action.payload;
    },
    setLoanValueFinanzForm: (state, action) => {
      state.loanValue = action.payload;
    },
    setInputLoanTimeFinanzForm: (state, action) => {
      state.inputLoanTime = action.payload;
    },
    setLoanTimeFinanzForm: (state, action) => {
      state.loanTime = action.payload;
    },
    setResetFieldsFinanzForm: (state) => {
      return initialState;
    },
    setResultingRateFinanzForm: (state, action) => {
      state.resultingRate = action.payload;
    },
  },
});

export const {
  setSiteIndexFinanzForm,
  setFederalStateFinanzForm,
  setDistrictFinanzForm,
  setLoanValueFinanzForm,
  setInputLoanTimeFinanzForm,
  setInputLoanValueFinanzForm,
  setLoanTimeFinanzForm,
  setResetFieldsFinanzForm,
  setResultingRateFinanzForm,
} = finanzierungsRechnerSlice.actions;

export default finanzierungsRechnerSlice.reducer;
