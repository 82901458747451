function IconArrowUp2({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="16.632" height="9.392" viewBox="0 0 16.632 9.392">
      <g transform="translate(1.414 1)">
        <path data-name="Pfad 6544" d="M0,13.8,6.978,6.9,0,0" transform="translate(0 6.978) rotate(-90)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  );
}

export default IconArrowUp2;
