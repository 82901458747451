function IconCheckmark({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="41.355"
      height="31.096"
      viewBox="0 0 41.355 31.096"
    >
      <path
        data-name="Path 7853"
        d="M205.628,1268.71l12.022,13.74,22.276-25.066"
        transform="translate(-202.1 -1253.854)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
      />
    </svg>
  );
}

export default IconCheckmark;
