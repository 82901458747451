import React from 'react';

function IconNews({ color, cssClasses }) {
  return (
    <svg className={cssClasses} id="Gruppe_15978" data-name="Gruppe 15978" xmlns="http://www.w3.org/2000/svg" width="48.57" height="39.868" viewBox="0 0 48.57 39.868">
      <g id="Gruppe_15928" data-name="Gruppe 15928">
        <path
          id="Pfad_10351"
          data-name="Pfad 10351"
          d="M46.85,9.512a1.773,1.773,0,0,0-1.72,1.72V33.8a2.631,2.631,0,1,1-5.262,0V3.44A3.412,3.412,0,0,0,36.428,0H3.44A3.412,3.412,0,0,0,0,3.44V34.708a5.182,5.182,0,0,0,5.161,5.161H42.5A6.089,6.089,0,0,0,48.57,33.8V11.232a1.773,1.773,0,0,0-1.72-1.72M5.161,36.428a1.773,1.773,0,0,1-1.72-1.72V3.44H36.428V33.8a5.9,5.9,0,0,0,.607,2.631Z"
          fill={color}
        />
        <path
          id="Pfad_10352"
          data-name="Pfad 10352"
          d="M27.6,8.6H12.02a1.773,1.773,0,0,0-1.72,1.72v8.7a1.773,1.773,0,0,0,1.72,1.72H27.6a1.773,1.773,0,0,0,1.72-1.72v-8.7A1.647,1.647,0,0,0,27.6,8.6m-1.72,8.6H13.74V12.04H25.883ZM27.6,27.623H12.02a1.72,1.72,0,1,0,0,3.44H27.6a1.773,1.773,0,0,0,1.72-1.72,1.706,1.706,0,0,0-1.72-1.72"
          transform="translate(0.122 0.102)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconNews;
