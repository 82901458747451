import { useSelector } from 'react-redux';
import Home from './Home';
import DashboardVKPhase1 from './DashboardVKPhase1';
import DashboardKPhase2 from './DashboardKPhase2';
import DashboardKPhase2InBearbeitung from './DashboardKPhase2InBearbeitung';
import DashboardVKPhase2 from './DashboardVKPhase2';
import DashboardVKPhase2WithActivity from './DashboardVKPhase2WithActivity';
import DashboardPhase3 from './DashboardPhase3';
import DashboardPhase4 from './DashboardPhase4';

import {
  PHASE2_VERKAUF_MIT_AKTIVITAET,
  PHASE2_VERKAUF_OHNE_AKTIVITAET,
  PHASE2_KAUF_SP_AKTIV,
  PHASE3_VERKAUF,
  PHASE3_KAUF,
  PHASE4_VERKAUF,
  PHASE4_KAUF,
  PHASE2_KAUF_SP_BEARBEITUNG,
  PHASE1_VERKAUF,
} from '../constants';

function Dashboard() {
  const app = useSelector((state) => state.app);
  return (
    <>
      {app.menuId === PHASE4_VERKAUF || app.menuId === PHASE4_KAUF ? (
        <DashboardPhase4 />
      ) : app.menuId === PHASE3_VERKAUF || app.menuId === PHASE3_KAUF ? (
        <DashboardPhase3 />
      ) : app.menuId === PHASE2_VERKAUF_OHNE_AKTIVITAET ? (
        <DashboardVKPhase2 />
      ) : app.menuId === PHASE2_VERKAUF_MIT_AKTIVITAET ? (
        <DashboardVKPhase2WithActivity />
      ) : app.menuId === PHASE2_KAUF_SP_BEARBEITUNG || app.menuId === PHASE2_KAUF_SP_AKTIV ? (
        <DashboardKPhase2 />
      ) : app.menuId === PHASE1_VERKAUF ? (
        <DashboardVKPhase1 />
      ) : (
        <Home />
      )}
    </>
  );
}

export default Dashboard;
