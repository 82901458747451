import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lastCachedDate: null,
  cachedSimilarImmos: [],
};

export const cachedSimilarImmosSlice = createSlice({
  name: 'cachedSimilarImmos',
  initialState,
  reducers: {
    setLastCachedDate: (state, action) => {
      state.lastCachedDate = action.payload;
    },
    setCachedSimilarImmos: (state, action) => {
      state.cachedSimilarImmos = action.payload;
    },
  },
});

export const { setLastCachedDate, setCachedSimilarImmos } = cachedSimilarImmosSlice.actions;

export default cachedSimilarImmosSlice.reducer;
