function IconWithoutRegistration({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
      <g data-name="Gruppe 15348" transform="translate(11682.55 679)">
        <rect data-name="Rechteck 4534" width="64" height="64" rx="8" transform="translate(-11682.55 -679)" fill={color} />
        <g data-name="Gruppe 13697" transform="translate(-11669.55 -666)">
          <g data-name="Gruppe 13696">
            <path
              data-name="Pfad 8483"
              d="M21.908,24.915A8.208,8.208,0,1,0,13.7,16.708a8.2,8.2,0,0,0,8.208,8.208m0-13.626a5.5,5.5,0,1,1-5.5,5.5,5.509,5.509,0,0,1,5.5-5.5"
              transform="translate(-2.783 -1.727)"
              fill="#fff"
            />
            <path
              data-name="Pfad 8484"
              d="M38.249,19.125A19.125,19.125,0,1,0,6.455,33.468c.08.08.159.159.239.159a19.028,19.028,0,0,0,24.862,0c.08-.08.159-.08.159-.159a18.9,18.9,0,0,0,6.534-14.343m-35.54,0A16.415,16.415,0,1,1,31,30.44a13.467,13.467,0,0,0-4.064-3.586A15.128,15.128,0,0,0,7.251,30.44,16.451,16.451,0,0,1,2.709,19.125M9.323,32.193A12.2,12.2,0,0,1,25.5,29.085a12.779,12.779,0,0,1,3.426,3.108,16.109,16.109,0,0,1-9.8,3.267,15.618,15.618,0,0,1-9.8-3.267"
              fill="#fff"
            />
          </g>
        </g>
        <line data-name="Linie 4466" y1="38" x2="38" transform="translate(-11669.05 -665.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
      </g>
    </svg>
  );
}

export default IconWithoutRegistration;
