import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRealEstates, getStats } from '../../api/User';
import { STATS_FILTER_AKTUELLEWOCHE, STATS_FILTER_GESAMT, STATS_FILTER_LETZTE3WOCHEN } from '../../constants';
import { parseDate } from '../../util/datetimeParser';
import moment from 'moment';
import 'moment/locale/de';

export const useFetchStats = () => {
  const user = useSelector((state) => state.user);
  const [realEstates, setRealEstates] = useState(null);
  const [besichtigungenData, setBesichtigungenData] = useState(null);
  const [initialBesichtigungenData, setInitialBesichtigungenData] = useState(null);
  const [verschickteAngeboteData, setVerschickteAngeboteData] = useState(null);
  const [initialVerschickteAngeboteData, setInitialVerschickteAngeboteData] = useState(null);
  const [inseratKlicksData, setInseratKlicksData] = useState(null);
  const [initialInseratKlicksData, setInitialInseratKlicksData] = useState(null);
  const [clicksBySourceData, setClicksBySourceData] = useState(null);
  const [initialClicksBySourceData, setInitialClicksBySourceData] = useState(null);
  const [startDatum, setStartDatum] = useState(null);
  const [endDatum, setEndDatum] = useState(null);
  const [dataError, setDataError] = useState(null);

  const fetchRealEstates = async () => {
    try {
      const realEstates = await getRealEstates();
      setRealEstates(realEstates);
      return realEstates;
    } catch (e) {
      return [];
    }
  };

  const getData = async (dummyData = false) => {
    if (!dummyData) {
      try {
        const realEstates = await fetchRealEstates();

        let foundImmo = realEstates.find((immo) => {
          // Check if catalogue_number already contains tenant_id (xxx/xxxxxx), if not build string with tenant_id
          if (user?.userRealtyActive?.catalogue_number.includes('/')) {
            return immo?.externalId === user?.userRealtyActive?.catalogue_number;
          } else {
            return immo?.externalId === `${user?.userRealtyActive?.tenant_id}/${user?.userRealtyActive?.catalogue_number}`;
          }
        });

        if (foundImmo) {
          try {
            const stats = await getStats(foundImmo?.id);
            setupStats(stats);
          } catch (e) {
            setDataError('Fehler beim Laden der Daten');
          }
        } else {
          setDataError('Fehler beim Laden der Daten');
        }
      } catch (e) {
        setDataError('Fehler beim Laden der Daten');
      }
    } else {
      const stats = {
        Gesamtlaufzeit: {
          startDate: '2025-01-01T00:00:00+00:00',
          endDate: '2025-01-23T00:00:00+00:00',
          groups: [
            {
              displayName: 'Klicks auf Inserat',
              key: 'clicks',
              types: [
                {
                  displayName: 'sreal.at',
                  key: 'sreal',
                  values: [
                    { date: '2025-01-01T00:00:00+00:00', value: 3 },
                    { date: '2025-01-02T00:00:00+00:00', value: 3 },
                    { date: '2025-01-03T00:00:00+00:00', value: 3 },
                    { date: '2025-01-04T00:00:00+00:00', value: 3 },
                    { date: '2025-01-05T00:00:00+00:00', value: 3 },
                    { date: '2025-01-06T00:00:00+00:00', value: 3 },
                    { date: '2025-01-07T00:00:00+00:00', value: 2 },
                    { date: '2025-01-08T00:00:00+00:00', value: 2 },
                    { date: '2025-01-09T00:00:00+00:00', value: 2 },
                    { date: '2025-01-10T00:00:00+00:00', value: 2 },
                    { date: '2025-01-11T00:00:00+00:00', value: 2 },
                    { date: '2025-01-12T00:00:00+00:00', value: 2 },
                    { date: '2025-01-13T00:00:00+00:00', value: 4 },
                    { date: '2025-01-14T00:00:00+00:00', value: 4 },
                    { date: '2025-01-15T00:00:00+00:00', value: 4 },
                    { date: '2025-01-16T00:00:00+00:00', value: 4 },
                    { date: '2025-01-17T00:00:00+00:00', value: 4 },
                    { date: '2025-01-18T00:00:00+00:00', value: 3 },
                    { date: '2025-01-19T00:00:00+00:00', value: 3 },
                    { date: '2025-01-20T00:00:00+00:00', value: 3 },
                    { date: '2025-01-21T00:00:00+00:00', value: 3 },
                    { date: '2025-01-22T00:00:00+00:00', value: 3 },
                    { date: '2025-01-23T00:00:00+00:00', value: 3 },
                  ],
                  sum: 88,
                },
                {
                  displayName: 'my-sreal.at',
                  key: 'mysreal',
                  values: [
                    { date: '2025-01-01T00:00:00+00:00', value: 2 },
                    { date: '2025-01-02T00:00:00+00:00', value: 2 },
                    { date: '2025-01-03T00:00:00+00:00', value: 2 },
                    { date: '2025-01-04T00:00:00+00:00', value: 2 },
                    { date: '2025-01-05T00:00:00+00:00', value: 2 },
                    { date: '2025-01-06T00:00:00+00:00', value: 2 },
                    { date: '2025-01-07T00:00:00+00:00', value: 1 },
                    { date: '2025-01-08T00:00:00+00:00', value: 1 },
                    { date: '2025-01-09T00:00:00+00:00', value: 1 },
                    { date: '2025-01-10T00:00:00+00:00', value: 1 },
                    { date: '2025-01-11T00:00:00+00:00', value: 1 },
                    { date: '2025-01-12T00:00:00+00:00', value: 1 },
                    { date: '2025-01-13T00:00:00+00:00', value: 1 },
                    { date: '2025-01-14T00:00:00+00:00', value: 1 },
                    { date: '2025-01-15T00:00:00+00:00', value: 1 },
                    { date: '2025-01-16T00:00:00+00:00', value: 1 },
                    { date: '2025-01-17T00:00:00+00:00', value: 1 },
                    { date: '2025-01-18T00:00:00+00:00', value: 1 },
                    { date: '2025-01-19T00:00:00+00:00', value: 1 },
                    { date: '2025-01-20T00:00:00+00:00', value: 1 },
                    { date: '2025-01-21T00:00:00+00:00', value: 2 },
                    { date: '2025-01-22T00:00:00+00:00', value: 2 },
                    { date: '2025-01-23T00:00:00+00:00', value: 2 },
                  ],
                  sum: 52,
                },
                {
                  displayName: 'willhaben Niederösterreich',
                  key: 'wh-n',
                  values: [
                    { date: '2025-01-01T00:00:00+00:00', value: 60 },
                    { date: '2025-01-02T00:00:00+00:00', value: 60 },
                    { date: '2025-01-03T00:00:00+00:00', value: 60 },
                    { date: '2025-01-04T00:00:00+00:00', value: 60 },
                    { date: '2025-01-05T00:00:00+00:00', value: 60 },
                    { date: '2025-01-06T00:00:00+00:00', value: 55 },
                    { date: '2025-01-07T00:00:00+00:00', value: 55 },
                    { date: '2025-01-08T00:00:00+00:00', value: 30 },
                    { date: '2025-01-09T00:00:00+00:00', value: 30 },
                    { date: '2025-01-10T00:00:00+00:00', value: 30 },
                    { date: '2025-01-11T00:00:00+00:00', value: 30 },
                    { date: '2025-01-12T00:00:00+00:00', value: 30 },
                    { date: '2025-01-13T00:00:00+00:00', value: 55 },
                    { date: '2025-01-14T00:00:00+00:00', value: 55 },
                    { date: '2025-01-15T00:00:00+00:00', value: 55 },
                    { date: '2025-01-16T00:00:00+00:00', value: 50 },
                    { date: '2025-01-17T00:00:00+00:00', value: 50 },
                    { date: '2025-01-18T00:00:00+00:00', value: 50 },
                    { date: '2025-01-19T00:00:00+00:00', value: 50 },
                    { date: '2025-01-20T00:00:00+00:00', value: 32 },
                    { date: '2025-01-21T00:00:00+00:00', value: 20 },
                    { date: '2025-01-22T00:00:00+00:00', value: 20 },
                    { date: '2025-01-23T00:00:00+00:00', value: 60 },
                  ],
                  sum: 1710,
                },
              ],
              sum: 1850,
            },
            {
              displayName: 'Verschickte Angebote',
              key: 'offers',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [
                    { date: '2025-01-01T00:00:00+00:00', value: 1 },
                    { date: '2025-01-02T00:00:00+00:00', value: 1 },
                    { date: '2025-01-03T00:00:00+00:00', value: 1 },
                    { date: '2025-01-04T00:00:00+00:00', value: 1 },
                    { date: '2025-01-05T00:00:00+00:00', value: 1 },
                    { date: '2025-01-06T00:00:00+00:00', value: 1 },
                    { date: '2025-01-07T00:00:00+00:00', value: 1 },
                    { date: '2025-01-08T00:00:00+00:00', value: 1 },
                    { date: '2025-01-09T00:00:00+00:00', value: 1 },
                    { date: '2025-01-10T00:00:00+00:00', value: 1 },
                    { date: '2025-01-11T00:00:00+00:00', value: 1 },
                    { date: '2025-01-12T00:00:00+00:00', value: 1 },
                    { date: '2025-01-13T00:00:00+00:00', value: 2 },
                    { date: '2025-01-14T00:00:00+00:00', value: 2 },
                    { date: '2025-01-15T00:00:00+00:00', value: 2 },
                    { date: '2025-01-16T00:00:00+00:00', value: 2 },
                    { date: '2025-01-17T00:00:00+00:00', value: 2 },
                    { date: '2025-01-18T00:00:00+00:00', value: 2 },
                    { date: '2025-01-19T00:00:00+00:00', value: 2 },
                    { date: '2025-01-20T00:00:00+00:00', value: 2 },
                    { date: '2025-01-21T00:00:00+00:00', value: 2 },
                    { date: '2025-01-22T00:00:00+00:00', value: 2 },
                    { date: '2025-01-23T00:00:00+00:00', value: 1 },
                  ],
                  sum: 42,
                },
              ],
              sum: 42,
            },
            {
              displayName: 'Besichtigungen',
              key: 'visits',
              types: [
                {
                  displayName: 'JustImmo',
                  key: 'ji',
                  values: [
                    { date: '2025-01-01T00:00:00+00:00', value: 1 },
                    { date: '2025-01-02T00:00:00+00:00', value: 1 },
                    { date: '2025-01-03T00:00:00+00:00', value: 1 },
                    { date: '2025-01-04T00:00:00+00:00', value: 1 },
                    { date: '2025-01-05T00:00:00+00:00', value: 1 },
                    { date: '2025-01-06T00:00:00+00:00', value: 1 },
                    { date: '2025-01-07T00:00:00+00:00', value: 1 },
                    { date: '2025-01-08T00:00:00+00:00', value: 1 },
                    { date: '2025-01-09T00:00:00+00:00', value: 1 },
                    { date: '2025-01-10T00:00:00+00:00', value: 1 },
                    { date: '2025-01-11T00:00:00+00:00', value: 1 },
                    { date: '2025-01-12T00:00:00+00:00', value: 1 },
                    { date: '2025-01-13T00:00:00+00:00', value: 1 },
                    { date: '2025-01-14T00:00:00+00:00', value: 1 },
                    { date: '2025-01-15T00:00:00+00:00', value: 1 },
                    { date: '2025-01-16T00:00:00+00:00', value: 1 },
                    { date: '2025-01-17T00:00:00+00:00', value: 1 },
                    { date: '2025-01-18T00:00:00+00:00', value: 1 },
                    { date: '2025-01-19T00:00:00+00:00', value: 1 },
                    { date: '2025-01-20T00:00:00+00:00', value: 1 },
                    { date: '2025-01-21T00:00:00+00:00', value: 2 },
                    { date: '2025-01-22T00:00:00+00:00', value: 2 },
                    { date: '2025-01-23T00:00:00+00:00', value: 2 },
                  ],
                  sum: 56,
                },
              ],
              sum: 56,
            },
          ],
        },
      };

      setupStats(stats);
    }
  };

  const setupStats = (stats) => {
    const preparedData = prepareData(stats[STATS_FILTER_GESAMT.label].groups.find((group) => group.key === 'clicks'));
    setInitialInseratKlicksData(preparedData);
    setInseratKlicksData(preparedData);
    const parsedSources = parseSources(stats[STATS_FILTER_GESAMT.label].groups.find((group) => group.key === 'clicks'));
    setInitialClicksBySourceData(parsedSources);
    setClicksBySourceData(parsedSources);
    const besichtigungenData = parseBesichtigungen(stats[STATS_FILTER_GESAMT.label].groups.find((group) => group.key === 'visits'));
    setInitialBesichtigungenData(besichtigungenData);
    setBesichtigungenData(besichtigungenData);
    const verschickteAngeboteData = parseAngebote(stats[STATS_FILTER_GESAMT.label].groups.find((group) => group.key === 'offers'));
    setInitialVerschickteAngeboteData(verschickteAngeboteData);
    setVerschickteAngeboteData(verschickteAngeboteData);
    setStartDatum(stats[STATS_FILTER_GESAMT.label]?.startDate);
    setEndDatum(stats[STATS_FILTER_GESAMT.label]?.endDate);
  };

  const prepareData = (stats) => {
    let possibleDates = {};
    stats.types?.forEach((type) => {
      if (type.values?.length > 0) {
        type.values.forEach((value) => {
          if (!possibleDates[value.date]) {
            possibleDates[value.date] = value.value;
          } else {
            possibleDates[value.date] += value.value;
          }
        });
      }
    });

    const dates = Object.keys(possibleDates).sort();
    const startDate = new Date(dates[0]);
    const endDate = new Date();

    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const dateString = d.toISOString().split('T')[0] + 'T00:00:00+00:00';
      if (!possibleDates[dateString]) {
        possibleDates[dateString] = 0;
      }
    }

    const result = Object.entries(possibleDates)
      .sort(([a], [b]) => new Date(a) - new Date(b))
      .map(([date, clicks]) => {
        const d = new Date(date);
        const name = `${d.getDate().toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}`;
        return {
          name,
          fullDate: date,
          Klicks: clicks,
        };
      });

    return result;
  };

  const parseSources = (stats) => {
    let parsedSources = [];
    stats.types?.forEach((type) => {
      let source = {
        name: type.displayName,
        data: type.values,
        sum: type.sum,
      };
      parsedSources.push(source);
    });
    parsedSources.forEach((source) => {
      if (source.data.length === 0) {
        parsedSources = parsedSources.filter((s) => s.name !== source.name);
      }
    });
    return parsedSources;
  };

  const parseBesichtigungen = (stats) => {
    let data = stats.types
      .map((type) => {
        return type.values;
      })
      .flat();

    return data;
  };

  const parseAngebote = (stats) => {
    let data = stats.types
      .map((type) => {
        return type.values.map((value) => {
          return {
            ...value,
            type: type.displayName,
          };
        });
      })
      .flat();

    return data;
  };

  const filterData = (timeFrame) => {
    if (!timeFrame) {
      resetData();
      return;
    }

    const startDate = timeFrame[0];
    const endDate = timeFrame[1];

    const filteredInseratKlicksData = initialInseratKlicksData.filter((data) => {
      return new Date(data.fullDate) >= startDate && new Date(data.fullDate) <= endDate;
    });
    setInseratKlicksData(filteredInseratKlicksData);

    let filteredClicksBySourceData = initialClicksBySourceData.map((source) => {
      return {
        ...source,
        data: source.data.filter((data) => new Date(data.date) >= startDate && new Date(data.date) <= endDate),
      };
    });
    filteredClicksBySourceData = filteredClicksBySourceData.filter((source) => source.data.length > 0);
    setClicksBySourceData(filteredClicksBySourceData);

    const filteredBesichtigungenData = initialBesichtigungenData.filter((data) => {
      return new Date(data.date) >= startDate && new Date(data.date) <= endDate;
    });
    setBesichtigungenData(filteredBesichtigungenData);

    const filteredVerschickteAngeboteData = initialVerschickteAngeboteData.filter((data) => {
      return new Date(data.date) >= startDate && new Date(data.date) <= endDate;
    });
    setVerschickteAngeboteData(filteredVerschickteAngeboteData);
  };

  const resetData = () => {
    setBesichtigungenData(initialBesichtigungenData);
    setVerschickteAngeboteData(initialVerschickteAngeboteData);
    setInseratKlicksData(initialInseratKlicksData);
    setClicksBySourceData(initialClicksBySourceData);
    setStartDatum(null);
    setEndDatum(null);
  };

  const startDatumFormatiert = new Date(startDatum).toLocaleDateString('de-DE');
  const endDatumFormatiert = new Date(endDatum).toLocaleDateString('de-DE');

  return {
    getData,
    startDatumFormatiert,
    endDatumFormatiert,
    startDatum,
    endDatum,
    fetchRealEstates,
    inseratKlicksData,
    besichtigungenData,
    verschickteAngeboteData,
    clicksBySourceData,
    filterData,
    resetData,
    dataError,
  };
};
