import { useSelector } from 'react-redux';
import IconLoadingSpinner from './Icons/IconLoadingSpinner';

function LoadingSpinner({ iconColor = '#3679ec', textColor = '#3679ec' }) {
  const app = useSelector((state) => state.app);

  return (
    <p className={app.formIsLoading ? 'loading-info' : 'loading-info loading-info--inactive'}>
      <IconLoadingSpinner color={iconColor} />
      <span style={{ color: `${textColor}` }}>Bitte haben Sie einen Moment Geduld.</span>
    </p>
  );
}

export default LoadingSpinner;
