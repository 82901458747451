function IconSmiley({ color, detailsColor, cssClasses, level, active }) {
  if (color === 'blue') {
    color = '#3679ec';
  }

  if (detailsColor === 'blue') {
    detailsColor = '#3679ec';
  }

  if (!detailsColor) detailsColor = '#fff';

  switch (level) {
    case 0:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g data-name="Gruppe 16076" transform="translate(-445.828 -69.5)">
              <circle data-name="Ellipse 1670" cx="24" cy="24" r="24" transform="translate(445.828 69.5)" fill={color} />
              <path data-name="Pfad 10403" d="M469.828,69.5a24,24,0,1,0,24,24,24.071,24.071,0,0,0-24-24m0,44.6a20.6,20.6,0,1,1,20.6-20.6,20.7,20.7,0,0,1-20.6,20.6" fill={color} />
              <path
                data-name="Pfad 10404"
                d="M473.128,95.6c-6.6-1.5-13.4,1.9-15.2,7.7a1.676,1.676,0,1,0,3.2,1c1.3-4.1,6.3-6.5,11.2-5.4a8.988,8.988,0,0,1,6.2,5.5,1.714,1.714,0,0,0,1.6,1.1,1.268,1.268,0,0,0,.6-.1,1.7,1.7,0,0,0,1-2.2,12,12,0,0,0-8.6-7.6m-11-5.7h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.565,2.565,0,0,0,2.2,2.5m15.1,0h.6a2.52,2.52,0,0,0,0-5h-.6a2.52,2.52,0,0,0,0,5"
                fill={detailsColor}
              />
            </g>
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g data-name="Gruppe 16076" transform="translate(-445.828 -69.5)">
              <path data-name="Pfad 10403" d="M469.828,69.5a24,24,0,1,0,24,24,24.071,24.071,0,0,0-24-24m0,44.6a20.6,20.6,0,1,1,20.6-20.6,20.7,20.7,0,0,1-20.6,20.6" fill={color} />
              <path
                data-name="Pfad 10404"
                d="M473.128,95.6c-6.6-1.5-13.4,1.9-15.2,7.7a1.676,1.676,0,1,0,3.2,1c1.3-4.1,6.3-6.5,11.2-5.4a8.988,8.988,0,0,1,6.2,5.5,1.714,1.714,0,0,0,1.6,1.1,1.268,1.268,0,0,0,.6-.1,1.7,1.7,0,0,0,1-2.2,12,12,0,0,0-8.6-7.6m-11-5.7h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.565,2.565,0,0,0,2.2,2.5m15.1,0h.6a2.52,2.52,0,0,0,0-5h-.6a2.52,2.52,0,0,0,0,5"
                fill={color}
              />
            </g>
          </svg>
        );
      }
    case 1:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g data-name="Gruppe 16074" transform="translate(-445.828 -142)">
              <circle data-name="Ellipse 1670" cx="24" cy="24" r="24" transform="translate(445.828 142)" fill={color} />
              <path data-name="Pfad 10405" d="M478.428,172.9h-17.2a1.7,1.7,0,0,0,0,3.4h17.1a1.752,1.752,0,0,0,1.7-1.7,1.666,1.666,0,0,0-1.6-1.7" fill={detailsColor} />
              <path data-name="Pfad 10406" d="M469.828,142a24,24,0,1,0,24,24,24.071,24.071,0,0,0-24-24m0,44.6a20.6,20.6,0,1,1,20.6-20.6,20.7,20.7,0,0,1-20.6,20.6" fill={color} />
              <path
                data-name="Pfad 10407"
                d="M461.928,162.4h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.66,2.66,0,0,0,2.2,2.5m15.1,0h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.565,2.565,0,0,0,2.2,2.5"
                fill={detailsColor}
              />
            </g>
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g data-name="Gruppe 16074" transform="translate(-445.828 -142)">
              <path data-name="Pfad 10405" d="M478.428,172.9h-17.2a1.7,1.7,0,0,0,0,3.4h17.1a1.752,1.752,0,0,0,1.7-1.7,1.666,1.666,0,0,0-1.6-1.7" fill={color} />
              <path data-name="Pfad 10406" d="M469.828,142a24,24,0,1,0,24,24,24.071,24.071,0,0,0-24-24m0,44.6a20.6,20.6,0,1,1,20.6-20.6,20.7,20.7,0,0,1-20.6,20.6" fill={color} />
              <path
                data-name="Pfad 10407"
                d="M461.928,162.4h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.66,2.66,0,0,0,2.2,2.5m15.1,0h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.565,2.565,0,0,0,2.2,2.5"
                fill={color}
              />
            </g>
          </svg>
        );
      }
    case 2:
      if (!!active) {
        return (
          <svg className={cssClasses} data-name="Gruppe 16078" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <circle data-name="Ellipse 1670" cx="24" cy="24" r="24" fill={color} />
            <path data-name="Pfad 10399" d="M24,0A24,24,0,1,0,48,24,24.071,24.071,0,0,0,24,0m0,44.6A20.6,20.6,0,1,1,44.6,24,20.7,20.7,0,0,1,24,44.6" fill={color} />
            <path
              data-name="Pfad 10400"
              d="M35.8,25.8A1.741,1.741,0,0,0,33.7,27a10.761,10.761,0,0,1-6.8,7,10.583,10.583,0,0,1-12.6-7.1,1.791,1.791,0,0,0-2.1-1.2A1.906,1.906,0,0,0,11,27.9a13.83,13.83,0,0,0,13.3,9.9,14.873,14.873,0,0,0,3.6-.5H28a14.5,14.5,0,0,0,9-9.4,1.741,1.741,0,0,0-1.2-2.1M16.1,20.4h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.66,2.66,0,0,0,2.2,2.5m15.1,0h.6a2.52,2.52,0,0,0,0-5h-.6A2.49,2.49,0,0,0,29,17.9a2.565,2.565,0,0,0,2.2,2.5"
              fill={detailsColor}
            />
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} data-name="Gruppe 16078" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <path data-name="Pfad 10399" d="M24,0A24,24,0,1,0,48,24,24.071,24.071,0,0,0,24,0m0,44.6A20.6,20.6,0,1,1,44.6,24,20.7,20.7,0,0,1,24,44.6" fill={color} />
            <path
              data-name="Pfad 10400"
              d="M35.8,25.8A1.741,1.741,0,0,0,33.7,27a10.761,10.761,0,0,1-6.8,7,10.583,10.583,0,0,1-12.6-7.1,1.791,1.791,0,0,0-2.1-1.2A1.906,1.906,0,0,0,11,27.9a13.83,13.83,0,0,0,13.3,9.9,14.873,14.873,0,0,0,3.6-.5H28a14.5,14.5,0,0,0,9-9.4,1.741,1.741,0,0,0-1.2-2.1M16.1,20.4h.6a2.52,2.52,0,0,0,0-5h-.6a2.49,2.49,0,0,0-2.2,2.5,2.66,2.66,0,0,0,2.2,2.5m15.1,0h.6a2.52,2.52,0,0,0,0-5h-.6A2.49,2.49,0,0,0,29,17.9a2.565,2.565,0,0,0,2.2,2.5"
              fill={color}
            />
          </svg>
        );
      }
    case 3:
      if (!!active) {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <g data-name="Gruppe 16077" transform="translate(-179.83 -1.45)">
              <circle data-name="Ellipse 1670" cx="24" cy="24" r="24" transform="translate(179.83 1.45)" fill={color} />
              <path
                data-name="Pfad 10401"
                d="M203.778,1.45A24,24,0,1,0,227.725,25.5,24.006,24.006,0,0,0,203.778,1.45m0,44.382A20.328,20.328,0,1,1,224.106,25.5a20.395,20.395,0,0,1-20.328,20.328"
                fill={color}
              />
              <path
                data-name="Pfad 10402"
                d="M215.639,25.161h-24.8a1.865,1.865,0,0,0-1.809,1.809c0,4.364,4.151,12.452,14.155,12.452s14.155-8.089,14.155-12.452a1.65,1.65,0,0,0-1.7-1.809M203.186,35.7c-6.386,0-9.366-4.151-10.217-6.918H213.4c-.851,2.874-3.725,6.918-10.217,6.918m8.3-16.177h.319a2.235,2.235,0,1,0-2.235-2.235,2.2,2.2,0,0,0,1.916,2.235m-17.774.106a2.235,2.235,0,1,0-2.235-2.235,2.248,2.248,0,0,0,2.235,2.235"
                transform="translate(0.592 0.875)"
                fill={detailsColor}
              />
            </g>
          </svg>
        );
      } else {
        return (
          <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="47.895" height="48" viewBox="0 0 47.895 48">
            <g data-name="Gruppe 16077" transform="translate(-179.831 -1.45)">
              <path
                data-name="Pfad 10401"
                d="M203.778,1.45A24,24,0,1,0,227.725,25.5,24.006,24.006,0,0,0,203.778,1.45m0,44.382A20.328,20.328,0,1,1,224.106,25.5a20.395,20.395,0,0,1-20.328,20.328"
                fill={color}
              />
              <path
                data-name="Pfad 10402"
                d="M215.639,25.161h-24.8a1.865,1.865,0,0,0-1.809,1.809c0,4.364,4.151,12.452,14.155,12.452s14.155-8.089,14.155-12.452a1.65,1.65,0,0,0-1.7-1.809M203.186,35.7c-6.386,0-9.366-4.151-10.217-6.918H213.4c-.851,2.874-3.725,6.918-10.217,6.918m8.3-16.177h.319a2.235,2.235,0,1,0-2.235-2.235,2.2,2.2,0,0,0,1.916,2.235m-17.774.106a2.235,2.235,0,1,0-2.235-2.235,2.248,2.248,0,0,0,2.235,2.235"
                transform="translate(0.592 0.875)"
                fill={color}
              />
            </g>
          </svg>
        );
      }
    default:
      break;
  }
}

export default IconSmiley;
