function IconUnchecked({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
    >
      <rect data-name="Rechteck 3312" width="26" height="26" rx="5" fill={color} />
    </svg>
  );
}

export default IconUnchecked;
