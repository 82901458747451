function IconLogout({ color, cssClasses }) {
  return (
    <svg
      className={cssClasses}
      xmlns="http://www.w3.org/2000/svg"
      width="30.498"
      height="34.363"
      viewBox="0 0 30.498 34.363"
    >
      <g data-name="Gruppe 5242" transform="translate(1.5 1.5)">
        <line
          data-name="Linie 1282"
          y2="11.747"
          transform="translate(13.748)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          data-name="Pfad 1823"
          d="M469.3,2998.493a13.747,13.747,0,1,1-10.642.026"
          transform="translate(-450.268 -2993.57)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export default IconLogout;
