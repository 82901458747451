import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMarktinfoLastResults, setMarktinfoResultActive } from "../../../reducers/user";
import FormSelect from "../FormSelect"
import { bitteWaehlenOption } from "../util/formOptions";
import { useHandleMarktinfo } from "../../../hooks/user/useHandleMarktinfo";


function FormSelectMarktinfoLastResults({label = 'Bisherige Berechnungen'}) {
    let user = useSelector((state) => state.user);
    const [marktinfoCalcs, setMarktinfoCalcs] = useState([]);
    const dispatch = useDispatch();

    const { getMarktinfoLabel } = useHandleMarktinfo();

    useEffect(() => {
      if(Object.keys(user.marktinfoLastResults).length > 0) {
        let selectOptions = Object.keys(user.marktinfoLastResults).map((key, index) => {
          let label = getMarktinfoLabel(user.marktinfoLastResults, index, key);
          return { id: key, text: label };
        });
        
        setMarktinfoCalcs(selectOptions);

        if(!user.marktinfoResultActive.id || user.marktinfoResultActive.id === -1) {
          const firstOption = selectOptions[0];
          dispatch(setMarktinfoResultActive({id: firstOption.id, text: firstOption.text}))
        }
      }
      else {
        dispatch(setMarktinfoResultActive(bitteWaehlenOption));
      }
    }, [user.marktinfoLastResults])

    return (
        <FormSelect
          label={label}
          placeholder="Bitte wählen"
          value={user.marktinfoResultActive}
          id={'previous_calculations'}
          formType="marktinfoLight"
          options={marktinfoCalcs}
          disabled={!user.isLoggedIn || marktinfoCalcs.length === 0}
        />
    )
}

export default FormSelectMarktinfoLastResults;