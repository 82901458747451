import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormInput from '../FormFields/FormInput';
import { validateFields } from '../FormFields/util/validateFields';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import FormCheckbox from '../FormFields/FormCheckbox';
import { requestMarketReport, checkMarketReportAvailability, getMarketReportList } from '../../api/JustImmo';
import { motion } from 'framer-motion';
import { setPreviousMarktBericht } from '../../reducers/marktBericht';
import { setPageDialogOpenContactForm } from '../../reducers/contactInformation';
import IconAlert from '../Icons/IconAlert';

function MarktBerichtForm() {
  const dispatch = useDispatch();
  let currentMarktBericht = useSelector((state) => state.marktBericht);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const [formLoading, setFormLoading] = useState(false);
  const { isMobile } = useHandleScreenResize();
  const formType = 'marktBericht';
  const [hasFormBeenSent, setHasFormBeenSent] = useState(false);
  const [adressNotFoundErrorShown, setAdressNotFoundErrorShown] = useState(false);
  const [marketReportErrorShown, setMarketReportErrorShown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30);
  const [isRetryDisabled, setIsRetryDisabled] = useState(false);

  useEffect(() => {
    if (currentMarktBericht.previousMarktBericht) {
      setHasFormBeenSent(true);
    } else {
      setHasFormBeenSent(false);
    }
  }, [currentMarktBericht.previousMarktBericht]);

  useEffect(() => {
    let timer;
    if (marketReportErrorShown) {
      setIsRetryDisabled(true);
      setRemainingTime(30);

      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            setIsRetryDisabled(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [marketReportErrorShown]);

  const handleRequestMarktbericht = async () => {
    setMarketReportErrorShown(false);
    setAdressNotFoundErrorShown(false);
    let validationResult = false;
    validationResult = validateFields(
      {
        streetOnly: currentMarktBericht.street,
        houseNumberOnly: currentMarktBericht.number,
        town: currentMarktBericht.city,
        zip: currentMarktBericht.zip,
        privacyPolicy: currentMarktBericht.isOwner,
      },
      dispatch,
    );

    if (validationResult) {
      setFormLoading(true);
      try {
        const response = await requestMarketReport({
          country: '40', // Muss laut Justimmo immer "40" sein
          street: currentMarktBericht.street,
          number: currentMarktBericht.number,
          zip: currentMarktBericht.zip,
          city: currentMarktBericht.city,
        });

        dispatch(setPreviousMarktBericht(response.newReport));
      } catch (error) {
        if (error.response.status === 404) {
          setAdressNotFoundErrorShown(true);
        } else {
          setMarketReportErrorShown(true);
        }
      } finally {
        setFormLoading(false);
      }
    }
  };

  return (
    <div className="marktberichtForm">
      {marketReportErrorShown ? (
        <div className="marktbericht-error">
          <div className="marktbericht-errorbox">
            <IconAlert />
            <p className="text-red mb-0">
              Die Abfrage war nicht erfolgreich. Bitte versuchen Sie es in <span className="text-bold">{remainingTime} Sekunden</span> erneut
            </p>
          </div>
          <ButtonForwardAction buttonText="Erneut versuchen" forwardAction={() => setMarketReportErrorShown(false)} buttonStyle="white" disabled={isRetryDisabled || formLoading} />
        </div>
      ) : !hasFormBeenSent ? (
        <>
          <p className="text-white">
            Als Eigentümer:in erhalten Sie Ihren Marktbericht <span className="text-bold">kostenlos</span> direkt im Dashboard von my-sreal.
          </p>
          <div className={!isMobile ? 'form__row form__row--double' : null}>
            <FormInput
              label={'Straße'}
              placeholder={'Straße eingeben'}
              id={'inputStreet'}
              formType={formType}
              value={currentMarktBericht.street}
              error={currentFormValidators.streetOnlyError}
              errorTextWhite={true}
              cssClasses={'text-white w-60'}
            />
            <FormInput
              label={'Hausnummer'}
              placeholder={'Hausnummer'}
              id={'inputNumber'}
              type={'number'}
              formType={formType}
              value={currentMarktBericht.number}
              error={currentFormValidators.houseNumberOnlyError}
              cssClasses={'text-white w-40'}
              errorTextWhite={true}
            />
          </div>
          <div className={!isMobile ? 'form__row form__row--double' : null}>
            <FormInput
              label={'PLZ'}
              placeholder={'PLZ eingeben'}
              id={'inputZip'}
              formType={formType}
              type="number"
              error={currentFormValidators.zipError}
              errorTextWhite={true}
              value={currentMarktBericht.zip}
              cssClasses={'text-white'}
            />
            <FormInput
              label={'Ort'}
              placeholder={'Ort eingeben'}
              id={'inputCity'}
              formType={formType}
              error={currentFormValidators.townError}
              errorTextWhite={true}
              value={currentMarktBericht.city}
              cssClasses={'text-white'}
            />
          </div>
          <FormCheckbox
            id="inputIsOwner"
            cssClasses={'text-white checkbox--white'}
            label={'Ja, ich bin Eigentümer:in der angegebenen Immobilie'}
            required={true}
            value={currentMarktBericht.isOwner}
            error={currentFormValidators.privacyPolicyError}
            formType={formType}
            errorTextWhite={true}
          />

          <p class="font-80 text-white mt-0">Mit Anfordern des Marktberichts stimmen Sie einer möglichen Kontaktaufnahme durch s REAL zu.</p>

          <div>
            <ButtonForwardAction buttonText="Marktbericht anfordern" forwardAction={handleRequestMarktbericht} buttonStyle="white" disabled={formLoading} />
            {adressNotFoundErrorShown && (
              <p className="text-lightred mb-0 mt-100rem marktbericht-errortext-notfound">
                Adresse konnte nicht gefunden werden. <br />
                <span className="cursor-pointer text-underline" onClick={() => dispatch(setPageDialogOpenContactForm(true))}>
                  Für Unterstützung hier kontaktieren.
                </span>
              </p>
            )}
          </div>
        </>
      ) : (
        <div className="">
          <div className="d-flex w-100 justify-content-center pt-40 pb-40">
            <svg id="Gruppe_17258" data-name="Gruppe 17258" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
              <g id="Gruppe_17259" data-name="Gruppe 17259">
                <path id="Pfad_10500" data-name="Pfad 10500" d="M65,0a65,65,0,1,0,65,65A65.191,65.191,0,0,0,65,0m0,120.792A55.792,55.792,0,1,1,120.792,65,56.061,56.061,0,0,1,65,120.792" fill="#fff" />
                <motion.path
                  id="Pfad_10501"
                  data-name="Pfad 10501"
                  d="M13.84,50.92,33.118,66.333a4.258,4.258,0,0,0,6.34-1.184L69.41,13.672"
                  transform="translate(23.643 23.356)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="10.5"
                  variants={{
                    hidden: {
                      pathLength: 0,
                    },
                    visible: {
                      pathLength: 1,
                      transition: {
                        duration: 1.5,
                        ease: 'easeInOut',
                      },
                    },
                  }}
                  initial="hidden"
                  animate="visible"
                />
              </g>
            </svg>
          </div>
          <p className="text-white text-center">Ihr Marktbericht wurde angefordert und ist in Kürze hier verfügbar.</p>
        </div>
      )}
    </div>
  );
}

export default MarktBerichtForm;
