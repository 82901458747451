import InfoDialog from './InfoDialog';
function InfoDialogGeldfluss({ open, handleClose }) {
  return (
    <InfoDialog open={open} headline={'Geldfluss'} handleClose={handleClose}>
      <p>
        Mit Eingang des Geldes auf dem Treuhandkonto kann die Immobilienübergabe
        – wenn dies von beiden Parteien gewünscht wird – auch bereits
        stattfinden. Zeitgleich übermittelt der Treuhänder die
        Grunderwerbssteuer an das zuständige Finanzamt und dieses stellt im
        Gegenzug eine Unbedenklichkeitsbescheinigung für Sie aus. Erst mit
        dieser werden Sie als neue:r Eigentümer:in ins Grundbuch eingetragen.
        Und auf die Grundbucheintragung folgt die Übermittlung des Kaufbetrages
        vom Treuhandkonto auf das Konto der verkaufenden Partei.
      </p>
    </InfoDialog>
  );
}

export default InfoDialogGeldfluss;
