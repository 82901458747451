function IconDocument({ color, cssClasses }) {
  return (
    <svg className={cssClasses} id="Gruppe_13711" data-name="Gruppe 13711" xmlns="http://www.w3.org/2000/svg" width="41.126" height="47.9" viewBox="0 0 41.126 47.9">
      <g id="Gruppe_13713" data-name="Gruppe 13713">
        <path
          id="Pfad_8570"
          data-name="Pfad 8570"
          d="M40.7,17.6,23.5.5A1.656,1.656,0,0,0,22.3,0H5.2A5.143,5.143,0,0,0,0,5.1V42.8a5.121,5.121,0,0,0,5.1,5.1H36a5.121,5.121,0,0,0,5.1-5.1v-24a1.469,1.469,0,0,0-.4-1.2m-3,25.3A1.752,1.752,0,0,1,36,44.6H5.2a1.752,1.752,0,0,1-1.7-1.7V5.1A1.752,1.752,0,0,1,5.2,3.4H21.6L37.7,19.5Z"
          fill={color}
        />
        <path
          id="Pfad_8571"
          data-name="Pfad 8571"
          d="M12,17.1h6.9a1.7,1.7,0,1,0,0-3.4H12a1.7,1.7,0,0,0,0,3.4m-1.7,8.6A1.752,1.752,0,0,0,12,27.4H29.1a1.7,1.7,0,0,0,0-3.4H12a1.752,1.752,0,0,0-1.7,1.7m18.9,8.6H12a1.7,1.7,0,0,0,0,3.4H29.1A1.752,1.752,0,0,0,30.8,36a1.666,1.666,0,0,0-1.6-1.7"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconDocument;
