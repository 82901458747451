import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'wohnwertLight',
    'marktinfoLight',
    'contactInformation',
    'searchProfileForm',
    'objectRequest',
    'finanzierungsRechner',
    'satisfactionForm',
    'recommendationForm'
  ]
};

export default persistConfig;
