import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageDialog from '../Dialogs/PageDialog';
import LoginForm from '../Forms/LoginForm';

import { useSelector, useDispatch } from 'react-redux';
import { setSiteIndexLoginForm, setPageDialogOpenLoginForm, setResetFieldsLoginForm } from '../../reducers/loginForm';
import { setResetFormValidator } from '../../reducers/formValidators';
import { setFormIsLoading } from '../../reducers/app';

import { LOGINFORM_SITEINDEX_START, LOGINFORM_SITEINDEX_PWFORGET, LOGINFORM_SITEINDEX_SUCCESSTOKEN } from '../../constants';

function PageDialogLogin() {
  let loginForm = useSelector((state) => state.loginForm);
  let app = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    const OAuthVariablesSet = app.OAuthClientId && app.OAuthResponseType && app.OAuthRedirectUri ? true : false;
    //prevent dialog from closing when clicking outside or pressing ESC on certain dialog-pages
    if ((reason && (reason === 'backdropClick' || reason === 'escapeKeyDown') && loginForm.siteIndex === LOGINFORM_SITEINDEX_SUCCESSTOKEN) || OAuthVariablesSet) {
      return;
    }

    if (app.formIsLoading) {
      return;
    }

    dispatch(setPageDialogOpenLoginForm(false));
    dispatch(setResetFieldsLoginForm());
    dispatch(setResetFormValidator());

    if (location.pathname.indexOf('set_password') >= 0 || location.pathname.indexOf('/confirm') >= 0) {
      // go back to home
      navigate('../' + app.root + '/');
    }
  };

  const backAction = (e) => {
    dispatch(setFormIsLoading(false));
    e.preventDefault();
    if (loginForm.siteIndex === LOGINFORM_SITEINDEX_PWFORGET) {
      dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_START));
    } else {
      dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_START));
    }
  };

  useEffect(() => {
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash === LOGINFORM_SITEINDEX_START) {
          dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_START));
          dispatch(setPageDialogOpenLoginForm(true));
        }
      }
    }
  }, []);

  return (
    <PageDialog
      open={loginForm.pageDialogOpen}
      handleClose={handleClose}
      handleBack={backAction}
      headline={loginForm.headline}
      showBackArrow={loginForm.siteIndex === LOGINFORM_SITEINDEX_PWFORGET ? true : false}
    >
      <LoginForm handleClose={handleClose} />
    </PageDialog>
  );
}

export default PageDialogLogin;
