import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import KaufanbotForm from '../Forms/KaufanbotForm';
import PageDialog from './PageDialog';

import { KAUFANBOT_SITEINDEX_MAIN } from '../../constants';

// STORE
import {
  setPageDialogOpenKaufanbotForm,
  setSiteIndexKaufanbotForm,
} from '../../reducers/kaufAnbote';

function PageDialogKaufanbot() {
  let currentKaufanbote = useSelector((state) => state.kaufAnbote);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const location = useLocation();

  const handleClose = () => {
    if (app.formIsLoading) {
      return;
    }

    dispatch(setSiteIndexKaufanbotForm(KAUFANBOT_SITEINDEX_MAIN));
    dispatch(setPageDialogOpenKaufanbotForm(false));
  };

  useEffect(() => {
    if (location.hash && location.hash !== '') {
      let hash = location.hash.replace('#', '');

      // check hash
      if (hash !== '') {
        if (hash.indexOf(KAUFANBOT_SITEINDEX_MAIN) === 0) {
          dispatch(setSiteIndexKaufanbotForm(KAUFANBOT_SITEINDEX_MAIN));
          dispatch(setPageDialogOpenKaufanbotForm(true));
        }
      }
    }
  }, []);

  return (
    <PageDialog
      open={currentKaufanbote.pageDialogOpen}
      handleClose={handleClose}
      headline={currentKaufanbote.headline}
      showBackArrow={false}
    >
      <KaufanbotForm handleClose={handleClose} />
    </PageDialog>
  );
}

export default PageDialogKaufanbot;
