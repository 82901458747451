function SmallCookieBanner({ acceptHandler, declineHandler }) {
  return (
    <div className="cookie-banner__small">
      <div className="cookie-banner__small__cookie-icon">🍪</div>
      <div>
        <p>
          Um Ihnen eine benutzerfreundliche Webseite zu bieten, verwenden wir
          Cookies. Wenn Sie über 16 Jahre sind, klicken Sie auf „Einverstanden“,
          um allen Verarbeitungszwecken zuzustimmen. Einzelne Kategorien von
          Verarbeitungszwecken (Cookies) können Sie auch ablehnen. Ihre Cookie
          Einstellungen können Sie jederzeit ändern.
        </p>
        <br />
        <p>
          Einige unserer Partnerdienste befinden sich in den USA. Nach
          Rechtsprechung des Europäischen Gerichtshofs existiert derzeit in den
          USA kein angemessener Datenschutz. Es besteht das Risiko, dass Ihre
          Daten durch US-Behörden kontrolliert und überwacht werden. Dagegen
          können Sie keine wirksamen Rechtsmittel vorbringen. Weitere
          Informationen zum Datenschutz finden Sie hier.
        </p>
      </div>
      <div className="cookie-banner__small__buttons">
        <button
          className="button button--white-outline"
          onClick={() => {
            declineHandler();
          }}
        >
          Ablehnen
        </button>
        <button
          className="button button--orange"
          onClick={() => {
            acceptHandler();
          }}
        >
          Einverstanden
        </button>
      </div>
      {/* <p
        onClick={() => {
          dispatch(setOpenCookie(false));
        }}
      >
        Close Cookies
      </p> */}
    </div>
  );
}

export default SmallCookieBanner;
