import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferRealtyList } from '../../api/JustImmo';
import { setKaufanboteList } from '../../reducers/kaufAnbote';
import { parseImmo } from '../../util/immoObjectParser';

export const useFetchKaufanbote = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [sentOffersListHeadline, setSentOffersListHeadline] = useState('0 verschickte Kaufanbotsanfragen');
  const [showSentOffersList, setShowSentOffersList] = useState(false);
  const [kaufanboteListLoaded, setKaufanboteListLoaded] = useState(false);

  useEffect(() => {
    getSentOffersListHeadline();
  }, [])

  useEffect(() => {
    getSentOffersListHeadline();
  }, [user.userObject.results]);

  const getAndSetKaufanbote = async () => {
    try {
      let offerRealtyList = await getOfferRealtyList();

      if (offerRealtyList && offerRealtyList.length > 0) {
        let offerRealtyListParsed = offerRealtyList.map((o) => parseImmo(o));
        dispatch(setKaufanboteList(offerRealtyListParsed));
      } else {
        dispatch(setKaufanboteList([]));
      }

      setKaufanboteListLoaded(true);
    } catch (e) {
      // TODO handle error
      console.log('e', e);
      setKaufanboteListLoaded(true);
    }
  };

  const getSentOffersListHeadline = () => {
    if(user?.isLoggedIn && user.userObject?.results) {
      const sentOffers = user.userObject.results.filter((r) => r.type === 'SentOffer');
      if(sentOffers.length > 0) {
        setSentOffersListHeadline(sentOffers.length + ' verschickte Kaufanbotsanfragen');
        setShowSentOffersList(true); 
      }
    }
  }

  const getSentOffersList = (wunschImmosList) => {
    const sentOffers = user.userObject.results.filter((r) => r.type === 'SentOffer');
    wunschImmosList = wunschImmosList.filter((w) => w.immo).map((w) => w.immo);

    // find immo from kaufanboteListe in wunschImmosList (already parsed as full immoObject)
    let list = sentOffers
      .map((anbot) => {
        let anbotImmo = wunschImmosList.find((w) => w.id === anbot.metaJson?.RealtyId);
        return {
          ...anbotImmo,
          offerCreatedAt: anbot.created,
          offeredPrice: anbot.metaJson?.Price,
        };
      })
      .filter((a) => a !== undefined);

    return list;
  }

  return { getAndSetKaufanbote, sentOffersListHeadline, showSentOffersList, getSentOffersList, kaufanboteListLoaded };
};
