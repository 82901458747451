import { createSlice } from '@reduxjs/toolkit';
import { bitteWaehlenOption } from '../components/FormFields/util/formOptions';
import { findAndParsePhoneNumber, removeHashFromUrl, setPhoneNumber } from '../util/generalUtils';

import { SEARCHPROFILE_SITEINDEX_EDIT, SEARCHPROFILE_SITEINDEX_START, SEARCHPROFILE_SITEINDEX_USERDATA } from '../constants';

const initialState = {
  siteIndex: SEARCHPROFILE_SITEINDEX_START,
  headline: 'Suchprofil anlegen',
  pageDialogOpen: false,
  pageDialogOpenSPList: false,
  pageDialogOpenSPEdit: false,
  name: '',
  requestBody: {
    type_ids: [], // Immobilienart
    location: {
      location_ids: [{}], // Bundesland, Region
    },
    price: {
      from: '',
      to: '',
    },
    living_area: {
      from: '',
      to: '',
    },
    site_area: {
      from: '',
      to: '',
    },
    rooms: {
      from: '',
      to: '',
    },
    furnishing_ids: [],
    covered_balcony: {
      enabled: false,
      from: null,
    },
    balcony: {
      enabled: false,
      from: null,
    },
    terrace: {
      enabled: false,
      from: null,
    },
    garden: {
      enabled: false,
      from: null,
    },
  },
  exteriorSurfaceSelection: [],
  gender: '',
  titlePre: '',
  titlePost: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  parsedPhoneNumber: '',
  phoneNumberPrefix: {id: 43, text: '+43 (Österreich)'},
  privacyPolicyRead: false,
  wantsToRegister: false,
  password: '',
  passwordConfirm: '',
  state: '',
};

const handleHash = (val, siteIndex) => {
  if (val === true) {
    window.location.hash = siteIndex;
  } else {
    removeHashFromUrl();
  }
};

export const searchProfileFormSlice = createSlice({
  name: 'searchProfileForm',
  initialState,
  reducers: {
    setSiteIndexSPForm: (state, action) => {
      window.location.hash = action.payload;
      state.siteIndex = action.payload;
      switch (state.siteIndex) {
        case SEARCHPROFILE_SITEINDEX_EDIT:
          state.headline = 'Suchprofil bearbeiten';
          break;
        case SEARCHPROFILE_SITEINDEX_START:
        case SEARCHPROFILE_SITEINDEX_USERDATA:
        default:
          state.headline = initialState.headline;
          break;
      }
    },
    setPageDialogOpenSPForm: (state, action) => {
      handleHash(action.payload, state.siteIndex);
      state.pageDialogOpen = action.payload;
    },
    setPageDialogOpenSPEdit: (state, action) => {
      handleHash(action.payload, state.siteIndex);
      state.pageDialogOpenSPEdit = action.payload;
    },
    setPageDialogOpenSPList: (state, action) => {
      handleHash(action.payload, state.siteIndex);
      state.pageDialogOpenSPList = action.payload;
    },
    setNameSPForm: (state, action) => {
      state.name = action.payload;
    },
    setFederalStateSPForm: (state, action) => {
      state.requestBody.location.location_ids[0] = action.payload;
      if (action.payload.text) {
        state.state = action.payload.text;
      } else {
        state.state = '';
      }
    },
    setDistrictSPForm: (state, action) => {
      state.requestBody.location.location_ids = [state.requestBody.location.location_ids[0], ...action.payload];
    },
    addDistrictSPForm: (state, action) => {
      state.requestBody.location.location_ids = [state.requestBody.location.location_ids[0], ...action.payload];
    },
    removeDistrictSPForm: (state, action) => {
      const foundDistrict = state.requestBody.location.location_ids.find((district) => district.id === action.payload.id);
      if (foundDistrict) {
        state.requestBody.location.location_ids = state.requestBody.location.location_ids.filter((district) => district.id !== action.payload.id);
      }
    },
    setEstateTypeSPForm: (state, action) => {
      state.requestBody.type_ids = [action.payload];
    },
    setPriceFromSPForm: (state, action) => {
      state.requestBody.price.from = action.payload;
    },
    setPriceToSPForm: (state, action) => {
      state.requestBody.price.to = action.payload;
    },
    setLivingAreaFromSPForm: (state, action) => {
      state.requestBody.living_area.from = action.payload;
    },
    setLivingAreaToSPForm: (state, action) => {
      state.requestBody.living_area.to = action.payload;
    },
    setSiteAreaFromSPForm: (state, action) => {
      state.requestBody.site_area.from = action.payload;
    },
    setSiteAreaToSPForm: (state, action) => {
      state.requestBody.site_area.to = action.payload;
    },
    setRoomsFromSPForm: (state, action) => {
      state.requestBody.rooms.from = action.payload;
    },
    setFeaturesSPForm: (state, action) => {
      state.requestBody.furnishing_ids = action.payload;
    },
    setFullTextSPForm: (state, action) => {
      state.fullText = action.payload;
    },
    setGenderSPForm: (state, action) => {
      state.gender = action.payload;
    },
    setTitlePreSPForm: (state, action) => {
      state.titlePre = action.payload;
    },
    setTitlePostSPForm: (state, action) => {
      state.titlePost = action.payload;
    },
    setFirstNameSPForm: (state, action) => {
      state.firstName = action.payload;
    },
    setLastNameSPForm: (state, action) => {
      state.lastName = action.payload;
    },
    setEmailSPForm: (state, action) => {
      state.email = action.payload;
    },
    setPhoneNumberPrefixSPForm: (state, action) => {
      state.phoneNumberPrefix = action.payload;
    },
    setPhoneSPForm: (state, action) => {
      setPhoneNumber(state, 'phone', state, action.payload);
    },
    setPhoneToUserPhoneSPForm: (state, action) => {
      state.phone = action.payload;
    },
    setParsedPhoneNumberSPForm: (state, action) => {
      findAndParsePhoneNumber(state, 'parsedPhoneNumber', action.payload);
    },
    setPrivacyPolicyReadSPForm: (state, action) => {
      state.privacyPolicyRead = action.payload;
    },
    setWantsToRegisterSPForm: (state, action) => {
      state.wantsToRegister = action.payload;
    },
    setPasswordSPForm: (state, action) => {
      state.password = action.payload;
    },
    setPasswordConfirmSPForm: (state, action) => {
      state.passwordConfirm = action.payload;
    },
    setResetFieldsSPForm: (state, action) => {
      return initialState;
    },
    setExteriorSurfaceSPForm: (state, action) => {
      state.exteriorSurfaceSelection = [...action.payload];
      state.requestBody.terrace.enabled = false;
      state.requestBody.balcony.enabled = false;
      state.requestBody.garden.enabled = false;
      state.requestBody.covered_balcony.enabled = false;
      action.payload.forEach((element) => {
        switch (element.id) {
          case 'terrace':
            state.requestBody.terrace.enabled = true;
            break;
          case 'balcony':
            state.requestBody.balcony.enabled = true;
            break;
          case 'garden':
            state.requestBody.garden.enabled = true;
            break;
          case 'covered_balcony':
            state.requestBody.covered_balcony.enabled = true;
            break;
          default:
            break;
        }
      });
    },
    setBalconyEnabledSPForm: (state, action) => {
      state.requestBody.balcony.enabled = action.payload;
    },
    setTerraceEnabledSPForm: (state, action) => {
      state.requestBody.terrace.enabled = action.payload;
    },
    setCoveredBalconyEnabledSPForm: (state, action) => {
      state.requestBody.covered_balcony.enabled = action.payload;
    },
    setGardenEnabledSPForm: (state, action) => {
      state.requestBody.garden.enabled = action.payload;
    },
  },
});

export const {
  setSiteIndexSPForm,
  setPageDialogOpenSPForm,
  setPageDialogOpenSPEdit,
  setPageDialogOpenSPList,
  setDistrictSPForm,
  setNameSPForm,
  setFederalStateSPForm,
  addDistrictSPForm,
  removeDistrictSPForm,
  setEstateTypeSPForm,
  setPriceFromSPForm,
  setPriceToSPForm,
  setLivingAreaFromSPForm,
  setLivingAreaToSPForm,
  setSiteAreaFromSPForm,
  setSiteAreaToSPForm,
  setRoomsFromSPForm,
  setFeaturesSPForm,
  setFullTextSPForm,
  setGenderSPForm,
  setTitlePreSPForm,
  setTitlePostSPForm,
  setFirstNameSPForm,
  setLastNameSPForm,
  setEmailSPForm,
  setPhoneSPForm,
  setPrivacyPolicyReadSPForm,
  setWantsToRegisterSPForm,
  setPasswordSPForm,
  setPasswordConfirmSPForm,
  setResetFieldsSPForm,
  setExteriorSurfaceSPForm,
  setPhoneNumberPrefixSPForm,
  setParsedPhoneNumberSPForm,
  setPhoneToUserPhoneSPForm,
  setBalconyEnabledSPForm,
  setTerraceEnabledSPForm,
  setCoveredBalconyEnabledSPForm,
  setGardenEnabledSPForm,
} = searchProfileFormSlice.actions;

export default searchProfileFormSlice.reducer;
