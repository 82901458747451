import { useNavigate, useLocation } from 'react-router-dom';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import IconPageNotFound from '../components/Icons/IconPageNotFound';
import MainContent from '../components/MainContent';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import MetaTags from '../components/MetaTags';
import { useEffect } from 'react';

function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  let app = useSelector((state) => state.app);

  useEffect(() => {
    if (location.search.includes('redirect=true')) {
      setShouldRedirect(true);
    }
  }, [location]);

  return (
    <>
      <MetaTags title={'Seite nicht gefunden | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon iconDesktop={true} icon={<IconPageNotFound color="#3679ec" cssClasses="p-10" />} iconActive={false} headlineDesktop="Seite nicht gefunden" />

      <MainContent cssClasses="w-desktop--center">
        <h1 className="desktop-hidden">Seite nicht gefunden</h1>
        <p className="pb-20">Die gesuchte Seite konnte leider nicht gefunden werden.</p>

        <span
          className="textlink"
          onClick={() => {
            if (!shouldRedirect) {
              navigate(-1);
            } else {
              navigate('../' + app.root + '/');
            }
          }}
        >
          {shouldRedirect ? 'Zurück zur Übersicht' : 'Vorherige Seite aufrufen'}
        </span>
      </MainContent>
    </>
  );
}

export default PageNotFound;
