import React from 'react';

function IconEinkaufen({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15835" xmlns="http://www.w3.org/2000/svg" width="27.346" height="27.311" viewBox="0 0 27.346 27.311">
      <g data-name="Gruppe 15836">
        <path
          data-name="Pfad 10328"
          d="M26.436,0H21.543a.984.984,0,0,0-.967.853l-.569,5.974H3.052A2.9,2.9,0,0,0,.15,10.64l2.617,7.795A2.849,2.849,0,0,0,5.5,20.483H18.016a2.984,2.984,0,0,0,2.9-2.56l.91-9.957V7.795l.569-5.86h3.983a1,1,0,0,0,.967-.967A.948.948,0,0,0,26.436,0M18.869,17.695a.983.983,0,0,1-.967.853H5.556a.876.876,0,0,1-.968-.683L1.971,10.071a1.048,1.048,0,0,1,.113-.91.9.9,0,0,1,.853-.4H19.78ZM5.954,23.385a1.919,1.919,0,0,0-1.935,1.934,1.967,1.967,0,0,0,1.935,1.991,1.919,1.919,0,0,0,1.934-1.934,1.967,1.967,0,0,0-1.934-1.991m12.688,0a1.934,1.934,0,1,0,1.934,1.934,1.919,1.919,0,0,0-1.934-1.934"
          transform="translate(0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconEinkaufen;
