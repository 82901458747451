import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  unreadMessages: 0,
  unreadBesichtigungen: 0,
  unreadWeitere: 0,
  unseenImmodriveCount: 0,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload;
    },
    setUnreadBesichtigungen: (state, action) => {
      state.unreadBesichtigungen = action.payload;
    },
    setUnreadWeitere: (state, action) => {
      state.unreadWeitere = action.payload;
    },
    setUnseenImmodriveCount: (state, action) => {
      state.unseenImmodriveCount = action.payload;
    },
  },
});

export const { setUnreadMessages, setUnreadBesichtigungen, setUnreadWeitere, setUnseenImmodriveCount } = notificationsSlice.actions;

export default notificationsSlice.reducer;
