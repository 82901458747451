import { useDispatch, useSelector } from 'react-redux';

import MarktinfoLightResult from '../Forms/MarktinfoLightResult';
import PageDialog from './PageDialog';

// STORE
import {
  setPageDialogOpenMarktinfoForm,
  setResetFieldsMarktinfoLightForm
} from '../../reducers/marktinfoLight';


function PageDialogMarkinfoLight() {
  let currentMarktinfoLight = useSelector((state) => state.marktinfoLight);
  let app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const handleClose = () => {
    if (app.formIsLoading) {
      return;
    }
    dispatch(setPageDialogOpenMarktinfoForm());
    dispatch(setResetFieldsMarktinfoLightForm());
  };

  // useEffect(() => {
  //     if(location.hash && location.hash !== '') {
  //         let hash = location.hash.replace("#", '');

  //         // check hash
  //         if(hash !== '') {
  //             if(hash === MARKTINFO_SITEINDEX_FORM) {
  //                 dispatch(setSiteIndexMarktinfoForm(MARKTINFO_SITEINDEX_FORM));
  //                 dispatch(setPageDialogOpenMarktinfoForm(true));
  //             }
  //         }
  //     }
  // }, [])

  return (
    <PageDialog
      open={currentMarktinfoLight.pageDialogOpen}
      handleClose={handleClose}
      headline="Marktanalyse"
    >
      <MarktinfoLightResult />
    </PageDialog>
  );
}

export default PageDialogMarkinfoLight;
