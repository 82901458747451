import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';

// FORM FIELDS
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import Textarea from '../../FormFields/Textarea';
import LoadingSpinner from '../../LoadingSpinner';
import FormSelect from '../../FormFields/FormSelect';

import { CONTACTFORM_ANLIEGEN_ALLG, CONTACTFORM_SITEINDEX_EMAILSENT, CONTACTFORM_SITEINDEX_IHREIMMOBILIE, CONTACTFORM_ANLIEGEN_SUPPORT, CONTACTFORM_SITEINDEX_MAIN } from '../../../constants';
import { fetchErrorText } from '../../FormFields/util/formTexts';
import { confirmPassword, validateFields } from '../../FormFields/util/validateFields';

import statesWithZips from '../../FormFields/util/statesWithZips';

// STORE
import { setFormIsLoading } from '../../../reducers/app';
import { setCreateJustImmoContactContactForm, setSiteIndexContactForm, setFileContactForm } from '../../../reducers/contactInformation';

// API
import { send } from '../../../api/Contact';
import { anliegenOptions } from '../../FormFields/util/formOptions';
import FileUpload from '../../FormFields/FileUpload';
import { handleScrollToErrorPageDialog } from '../../../util/generalUtils';

function ContactIhreNachricht() {
  let currentContactInformation = useSelector((state) => state.contactInformation);
  let currentFormValidators = useSelector((state) => state.formValidators);
  let user = useSelector((state) => state.user);
  let app = useSelector((state) => state.app);
  const [currentFile, setCurrentFile] = useState(null);

  const dispatch = useDispatch();

  const formType = 'contactInformation';
  const [error, setError] = useState('');

  const forwardAction = async () => {
    setError('');

    if (app.formIsLoading) {
      return;
    }

    let fieldsToValidate = {};
    fieldsToValidate.privacyPolicy = currentContactInformation.requestBody.register.privacyAggrement;

    let validatePWResult = true;
    if (currentContactInformation.requestBody.createUser) {
      fieldsToValidate.password = currentContactInformation.requestBody.register.password;
      fieldsToValidate.passwordConfirm = currentContactInformation.passwordConfirm;

      validatePWResult = confirmPassword(currentContactInformation.requestBody.register.password, currentContactInformation.passwordConfirm, dispatch);
    }

    if (currentContactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
      fieldsToValidate.federalState = currentContactInformation.stateObject.id;
      fieldsToValidate.message = currentContactInformation.requestBody.message;
    }

    let validationResult = validateFields(fieldsToValidate, dispatch);

    if (currentContactInformation.requestBody.createUser) {
      validationResult = confirmPassword(currentContactInformation.requestBody.register.password, currentContactInformation.passwordConfirm, dispatch);
    }

    if (validationResult && validatePWResult) {
      const newObjectRequest = { ...currentContactInformation.requestBody };

      if (currentContactInformation.requestBody.reason !== anliegenOptions[CONTACTFORM_ANLIEGEN_ALLG].text) {
        dispatch(setCreateJustImmoContactContactForm(true));
        newObjectRequest.createJustImmoContact = true;
      }

      dispatch(setFormIsLoading(true));
      try {
        await send(newObjectRequest, currentFile);
        dispatch(setFormIsLoading(false));
        dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_EMAILSENT));
      } catch (e) {
        dispatch(setFormIsLoading(false));
        if (e.response?.data?.detail?.includes('already taken')) {
          setError('Diese E-Mail-Adresse ist bereits registriert.');
        } else {
          setError(fetchErrorText);
        }
      }
    } else {
      handleScrollToErrorPageDialog(formType, '', '#inputState');
    }
  };

  const handleSetFile = (file) => {
    setCurrentFile(file);
  };

  const backAction = (e) => {
    e.preventDefault();

    if (currentContactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
      dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_MAIN));
    } else {
      dispatch(setSiteIndexContactForm(CONTACTFORM_SITEINDEX_IHREIMMOBILIE));
    }
  };

  const federalStateOptions = statesWithZips.map((state) => {
    return {
      id: state.justImmoId,
      text: state.label,
    };
  });

  return (
    <>
      {currentContactInformation.reasonObject.id === CONTACTFORM_ANLIEGEN_SUPPORT ? (
        <>
          <h3 className="form__title">Wo suchen oder verkaufen Sie Ihre Immobilie?</h3>
          <FormSelect
            label={'Bundesland'}
            placeholder="Bitte wählen"
            value={currentContactInformation.stateObject}
            error={currentFormValidators.federalStateError}
            formType={formType}
            required={true}
            id={'inputState'}
            options={federalStateOptions}
          />

          <h3 className="form__title">Ihre Nachricht</h3>
          <Textarea formType={formType} error={currentFormValidators.messageError} required={true} />
          <h3 className="form__title">Anhang hinzufügen (optional)</h3>
          <FileUpload setFile={handleSetFile} currentFile={currentFile} />
        </>
      ) : (
        <>
          <h3 className="form__title">Ihre Nachricht (optional)</h3>
          <Textarea formType={formType} />
        </>
      )}

      <h3 className="form__title">Zustimmungen</h3>
      <FormCheckbox
        id="inputPrivacyAggrement"
        label={<FormTextDsgvo />}
        required={true}
        value={currentContactInformation.requestBody.register.privacyAggrement}
        error={currentFormValidators.privacyPolicyError}
        formType={formType}
      />

      {!user.isLoggedIn && (
        <>
          <FormCheckbox
            id="inputCreateUser"
            label={'Jetzt direkt für das sREAL Kundenportal registrieren und von den vielen Vorteilen profitieren.'}
            value={currentContactInformation.requestBody.createUser}
            formType={formType}
          />

          {currentContactInformation.requestBody.createUser && (
            <>
              <FormInput
                type={'password'}
                id={'inputPassword'}
                label={'Passwort'}
                value={currentContactInformation.requestBody.register.password}
                error={currentFormValidators.passwordError}
                required={true}
                formType={formType}
              />
              <span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

              <FormInput
                type={'password'}
                id={'inputPasswordConfirm'}
                label={'Passwort wiederholen'}
                value={currentContactInformation.passwordConfirm}
                error={currentFormValidators.passwordConfirmError}
                required={true}
                formType={formType}
              />
            </>
          )}
        </>
      )}

      <LoadingSpinner />

      <div className="button-panel pt-150rem">
        <button className="button button--gray-outline" onClick={backAction}>
          Zurück
        </button>
        <ButtonForwardAction formType={formType} buttonText="Nachricht senden" forwardAction={forwardAction} buttonStyle="blue" />
      </div>
      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default ContactIhreNachricht;
