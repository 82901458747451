import React from 'react';
import { useDispatch } from 'react-redux';
import { setMessageContactForm } from '../../reducers/contactInformation';
import { setMessageObjectRequest } from '../../reducers/objectRequest';

function Textarea({ id, label, required, error, formType, value, callback }) {
  const dispatch = useDispatch();

  const handleInputChange = (value) => {
    if(callback) {
      callback(value);
    }
    else {
      if (formType === 'contactInformation') {
        dispatch(setMessageContactForm(value));
      } else if (formType === 'objectRequestForm') {
        dispatch(setMessageObjectRequest(value));
      }
    }
  };
  return (
    <div className="form__field">
      {label && (
        <label htmlFor={id}>
          {label} {!!required ? '*' : ''}
        </label>
      )}

      <textarea id={id} onChange={(e) => handleInputChange(e.target.value)} defaultValue={value} />

      {error !== '' && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default Textarea;
