function IconHouseTree({ color, cssClasses }) {
	return (
		<svg  className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="348.373" height="160.622" viewBox="0 0 348.373 160.622">
			<g data-name="Gruppe 15376" transform="translate(-341.985 -1485.261)">
				<g data-name="Gruppe 15367" transform="translate(529.339 1485.261)">
				<g data-name="Gruppe 15372" transform="translate(0 0)">
					<path data-name="Pfad 8783" d="M124.454,34.491A47.54,47.54,0,0,0,107.346,9,44.884,44.884,0,0,0,73.13.61,45.194,45.194,0,0,0,35.558,34.491a40.223,40.223,0,0,0,4.7,80.174,37.69,37.69,0,0,0,10.735-1.342A40.646,40.646,0,0,0,73.8,125.735v29.185a5.7,5.7,0,0,0,11.406,0V125.735a38.01,38.01,0,0,0,19.121-7.715,40.808,40.808,0,0,0,5.7-5.032,28.665,28.665,0,0,0,10.735,1.342,40.373,40.373,0,0,0,40.255-40.255c0-20.127-16.1-37.571-36.565-39.584m-3.69,69.1a27.336,27.336,0,0,1-10.4-2.013,5.326,5.326,0,0,0-6.709,2.013,25.8,25.8,0,0,1-6.038,6.038A34.093,34.093,0,0,1,85.206,115V94.873l15.431-15.431a5.693,5.693,0,0,0-8.051-8.051L79.5,83.8,66.42,70.72a5.693,5.693,0,0,0-8.051,8.051L73.8,94.2v19.792a29.563,29.563,0,0,1-16.437-10.735,5.869,5.869,0,0,0-4.7-2.348,6.549,6.549,0,0,0-2.348.335,27.336,27.336,0,0,1-10.4,2.013,28.849,28.849,0,1,1,0-57.7,5.764,5.764,0,0,0,5.7-5.032A34.158,34.158,0,0,1,74.471,11.68a34.584,34.584,0,0,1,25.495,6.374A34.032,34.032,0,0,1,113.72,40.529a5.8,5.8,0,0,0,5.7,5.032h1.006A28.847,28.847,0,0,1,149.278,74.41c0,16.1-12.747,29.185-28.514,29.185" transform="translate(0 0)" fill={color} />
				</g>
				</g>
				<g data-name="Gruppe 15375" transform="translate(341.985 1492.368)">
				<path data-name="Pfad 8715" d="M174.149,83.639,148.865,58.355,92.434,1.924a5.984,5.984,0,0,0-8.794,0l-56.431,56.8L1.924,84.006a5.984,5.984,0,0,0,0,8.795,6.761,6.761,0,0,0,4.4,1.832,6.761,6.761,0,0,0,4.4-1.832L25.009,78.509v66.325a6.421,6.421,0,0,0,6.229,6.229H144.468a6.421,6.421,0,0,0,6.229-6.229V78.143l14.291,14.291a5.984,5.984,0,0,0,8.794,0,5.722,5.722,0,0,0,.366-8.794m-35.911,54.6H94.266V106.725a6.229,6.229,0,0,0-12.459,0v31.514H37.835V65.684l50.2-50.2,50.2,50.2Z" fill={color}/>
				</g>
			</g>
		</svg>
	)
}

export default IconHouseTree;