import { useState, useEffect } from 'react';
import { useHandleSimilarImmos } from '../../hooks/immo/useHandleSimilarImmos';
import ImmoCard from '../Cards/ImmoCard';
import { parseImmoSreal } from '../../util/immoObjectParser';
import ImmoCardSmall from '../Cards/ImmoCardSmall';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

export const SimilarImmosSection = ({ searchProfiles }) => {
  const [similarImmos, setSimilarImmos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getSimilarImmos } = useHandleSimilarImmos();
  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    const fetchSimilarImmos = async () => {
      setIsLoading(true);
      try {
        const activeProfiles = searchProfiles.filter((sp) => sp.state === 'active');
        const immos = await getSimilarImmos(activeProfiles);
        setSimilarImmos(immos.slice(0, 16) || []);
      } catch (error) {
        console.error('Error fetching similar immos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchProfiles?.length > 0) {
      fetchSimilarImmos();
    }
  }, [searchProfiles]);

  if (!searchProfiles?.length) return null;

  return (
    similarImmos.length > 0 && (
      <div className={`gray-background ${!isMobile ? 'mb--700rem' : ''}`}>
        <div className="w-desktop--center">
          <h2 className="mt-0">Weitere Empfehlungen für Sie</h2>
          <span>Wir haben Ihre Suchkriterien erweitert. Diese Immobilien könnten Ihnen auch gefallen.</span>
          {isLoading ? (
            <div>
              <span>Ähnliche Immobilien werden geladen...</span>
            </div>
          ) : similarImmos?.length > 0 ? (
            <div className="cardgrid--4cols">
              {similarImmos.map((immo) => {
                return <ImmoCardSmall retailObject={parseImmoSreal(immo)} key={immo.id} cssClasses={''} />;
              })}
            </div>
          ) : (
            <p>Keine ähnlichen Immobilien gefunden.</p>
          )}
        </div>
      </div>
    )
  );
};
