function IconHerunterladen({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15474" xmlns="http://www.w3.org/2000/svg" width="19.605" height="19.565" viewBox="0 0 19.605 19.565">
      <path
        data-name="Pfad 8900"
        d="M18.872,34.3a.714.714,0,0,0-.693.693v1.386A2.088,2.088,0,0,1,16.1,38.458H3.505a2.088,2.088,0,0,1-2.079-2.079V34.993A.714.714,0,0,0,.734,34.3.752.752,0,0,0,0,34.993v1.386a3.479,3.479,0,0,0,3.505,3.505H16.1a3.505,3.505,0,0,0,3.505-3.505V34.993a.784.784,0,0,0-.734-.693"
        transform="translate(0 -20.319)"
        fill={color}
      />
      <path
        data-name="Pfad 8901"
        d="M16.365,13.736l.082.082.041.041c.041,0,.041.041.082.041a.04.04,0,0,1,.041.041.142.142,0,0,1,.082.041H17.1c.041,0,.082,0,.082-.041a.04.04,0,0,0,.041-.041c.041,0,.041-.041.082-.041l.041-.041c.041,0,.041-.041.082-.041l4.2-4.891a.694.694,0,1,0-1.06-.9l-2.975,3.465V.693a.693.693,0,0,0-1.386,0V11.372L13.226,7.948a.694.694,0,0,0-1.06.9Z"
        transform="translate(-7.112)"
        fill={color}
      />
    </svg>
  );
}

export default IconHerunterladen;
