const combineReduxPhoneParts = (prefix, number) => {
  const countryCodeMatch = prefix.text.match(/\+(\d+)/);
  const countryCode = countryCodeMatch ? countryCodeMatch[1] : '';

  const cleanedPart2 = number.replace(/\D/g, '');

  const combinedString = `+${countryCode}${cleanedPart2}`;
  return combinedString;
};

export { combineReduxPhoneParts };
