import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import ImmoCard from '../components/Cards/ImmoCard';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

// ICONS
import IconStar from '../components/Icons/IconStar';

import { useHandleFavorite } from '../hooks/immo/useHandleFavorite';

function Favoriten() {
  const user = useSelector((state) => state.user);
  const { reloadFavsWithRealtyDetails } = useHandleFavorite();

  useEffect(() => {
    reloadFavsWithRealtyDetails();
  }, []);

  return (
    <>
      <MetaTags title={'Favoriten | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon icon={<IconStar />} iconActive={true} headlineDesktop="Favoriten" cssClasses="header--greenbg" />

      <MainContent cssClasses="w-desktop--center p-100rem">
        <h1 className="headline--mobile">Favoriten</h1>

        <>
          {(user.favourites).length > 0 ? (
            <div className="cardgrid mt-200rem">
              {(user.favourites).map((f, index) => (
                <ImmoCard retailObject={f} key={f.id + '-fav' + index} />
              ))}

              <CardGridPlaceholderElement array={user.favourites} />
            </div>
          ) : (
            <p className="page__emptymessage">
              Sie haben noch keine Favoriten. Klicken Sie einfach auf das Herz bei einer Immobilie, die Sie in der Suche oder als Angebot erhalten, um sie als Favorit zu markieren.
            </p>
          )}
        </>
      </MainContent>
    </>
  );
}

export default Favoriten;
