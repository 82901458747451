import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LOGINFORM_SITEINDEX_PWFORGET } from '../../../constants';
import {
  setPageDialogOpenLoginForm,
  setResetFieldsLoginForm,
  setSiteIndexLoginForm
} from '../../../reducers/loginForm';

import IconSuccess from '../../Icons/IconSuccess';

function RegisterFormUserExists({ handleClose }) {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const app = useSelector((state) => state.app);

  const handleSwitchToLogin = () => {
    handleClose();
    navigate('../' + app.root + '/');
    dispatch(setResetFieldsLoginForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  const handleSwitchToPasswordForm = () => {
    handleClose();
    navigate('../' + app.root + '/');
    dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_PWFORGET));
    dispatch(setPageDialogOpenLoginForm(true));
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconSuccess color="#1E3163" />
      </div>

      <h2 className="pt-200rem">Registrierung bereits abgeschlossen</h2>
      <p className="pt-200rem pb-200rem lh-130">
        Ihre Registrierung wurde erfolgreich abgeschlossen. Herzlich willkommen und viel Freude bei
        der Nutzung von my-sreal.at
      </p>

      <p className="lh-130">
        Loggen Sie sich ein:{' '}
        <span onClick={handleSwitchToLogin} className="textlink">
          Login aufrufen
        </span>{' '}
        <br />
        Oder setzen Sie Ihr Passwort zurück:{' '}
        <span onClick={handleSwitchToPasswordForm} className="textlink">
          Passwort vergessen
        </span>
      </p>
    </>
  );
}

export default RegisterFormUserExists;
