import httpClient from './index';

const END_POINT = '/connect';

const tryAuthorizeUser = async (client_id: string, response_type: string, redirect_uri: string) => {
  try {
    const response = await httpClient.get(`${END_POINT}/authorize`, {
      params: {
        client_id,
        response_type,
        redirect_uri,
      },
      maxRedirects: 0,
      validateStatus: (status) => status === 200 || status === 401,
      withCredentials: true,
    });

    if (response.status === 200) {
      const location = response.headers['location'];
      return location;
    }

    return null;
  } catch (error) {
    if (error.response && error.response.status === 302) {
      const location = error.response.headers['location'];
      return location;
    }

    console.error('Error while trying to authorize user:', error);
    return null;
  }
};

export { tryAuthorizeUser };
