import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormInput from '../FormFields/FormInput';
import FormSelect from '../FormFields/FormSelect';

import { setSearchFormExpanded } from '../../reducers/searchForm';
import { featuresOptions, immobilienartOptionsSearchForm } from '../FormFields/util/formOptions';

import '../../styles/searchform.scss';
import FormInputMultiselect from '../FormFields/FormInputMultiselect';
//HOOKS
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useFetchImmoSearch } from '../../hooks/immo/useFetchImmoSearch';

function SearchForm({ forwardAction }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { clearSearch } = useFetchImmoSearch();
  let currentSearchForm = useSelector((state) => state.searchForm);
  const app = useSelector((state) => state.app);

  const { isMobile } = useHandleScreenResize();

  if (isMobile && location.pathname === '/immobiliensuche') {
    dispatch(setSearchFormExpanded(true));
  }

  const handleExpanded = () => {
    if (isMobile) {
      dispatch(setSearchFormExpanded(true));
      navigate(app.root + '/immobiliensuche');
    } else {
      dispatch(setSearchFormExpanded(!currentSearchForm.searchFormExpanded));
    }
  };

  if (currentSearchForm.searchFormExpanded) {
    return (
      <div id="searchForm" className="searchForm searchForm--longversion">
        <div className="immosearch-form">
          <div className="immosearch-form__col">
            <FormInputMultiselect label="Ort, PLZ oder Objektnummer" placeholder="Ort, PLZ oder Objektnummer" formType="searchForm" value={currentSearchForm.address} id="inputAddress" />
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <FormInput
                label="Fläche min"
                placeholder="Fläche min"
                formType="searchForm"
                value={currentSearchForm.requestBody.areaFrom}
                id="inputAreaFrom"
                type={'number'}
                minValue={0}
                maxValue={currentSearchForm.requestBody.areaTo}
              />
              <FormInput
                label="Fläche max"
                placeholder="Fläche max"
                formType="searchForm"
                value={currentSearchForm.requestBody.areaTo}
                id="inputAreaTo"
                type={'number'}
                minValue={currentSearchForm.requestBody.areaFrom > 0 ? currentSearchForm.requestBody.areaFrom : 0}
              />
            </div>
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <FormInput
                label="Preis min"
                placeholder="Preis min"
                formType="searchForm"
                value={currentSearchForm.requestBody.priceFrom}
                id="inputPriceFrom"
                type={'number'}
                minValue={0}
                maxValue={currentSearchForm.requestBody.priceTo}
              />
              <FormInput
                label="Preis max"
                placeholder="Preis max"
                formType="searchForm"
                value={currentSearchForm.requestBody.priceTo}
                id="inputPriceTo"
                type={'number'}
                minValue={currentSearchForm.requestBody.priceFrom > 0 ? currentSearchForm.requestBody.priceFrom : 0}
              />
            </div>
          </div>
        </div>
        <div className="immosearch-form">
          <div className="immosearch-form__col">
            <FormSelect
              label={'Objektart'}
              placeholder="Objektart wählen"
              showBitteWaehlen={false}
              value={currentSearchForm.requestBody.estateType}
              formType={'searchForm'}
              id={'inputEstateType'}
              options={immobilienartOptionsSearchForm}
              multiple={true}
            />
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <FormInput
                label="Grundfläche von"
                placeholder="Grundfläche von"
                formType="searchForm"
                value={currentSearchForm.requestBody.surfaceAreaFrom}
                id="inputSurfaceAreaFrom"
                type={'number'}
                minValue={0}
                maxValue={currentSearchForm.requestBody.surfaceAreaTo}
              />
              <FormInput
                label="Grundfläche bis"
                placeholder="Grundfläche bis"
                formType="searchForm"
                value={currentSearchForm.requestBody.surfaceAreaTo}
                id="inputSurfaceAreaTo"
                type={'number'}
                minValue={currentSearchForm.requestBody.surfaceAreaFrom > 0 ? currentSearchForm.requestBody.surfaceAreaFrom : 0}
              />
            </div>
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <FormInput
                label="Zimmer von"
                placeholder="Zimmer von"
                formType="searchForm"
                value={currentSearchForm.requestBody.roomsFrom}
                id="inputRoomsFrom"
                type={'number'}
                minValue={0}
                maxValue={currentSearchForm.requestBody.roomsTo}
              />
              <FormInput
                label="Zimmer bis"
                placeholder="Zimmer bis"
                formType="searchForm"
                value={currentSearchForm.requestBody.roomsTo}
                id="inputRoomsTo"
                type={'number'}
                minValue={currentSearchForm.requestBody.roomsFrom > 0 ? currentSearchForm.requestBody.roomsFrom : 0}
              />
            </div>
          </div>
        </div>

        <div className="immosearch-form">
          <div className="immosearch-form__col">
            <FormSelect
              label={'Unterobjektart'}
              showBitteWaehlen={false}
              placeholder={currentSearchForm.requestBody.estateType.length === 0 ? 'Bitte erst Objektart auswählen' : 'Unterobjektart wählen'}
              disabled={currentSearchForm.requestBody.estateType.length === 0 ? true : false}
              hasLockIcon={currentSearchForm.requestBody.estateType.length === 0 ? true : false}
              value={currentSearchForm.requestBody.subEstateType}
              formType={'searchForm'}
              id={'inputSubEstateType'}
              options={currentSearchForm.realtySubtypeOptions}
              multiple={true}
            />
          </div>
          <div className="immosearch-form__col">
            <FormSelect
              label={'Ausstattungsmerkmale'}
              placeholder="Ausstattungsmerkmale wählen"
              showBitteWaehlen={false}
              value={currentSearchForm.requestBody.features}
              formType={'searchForm'}
              id={'inputFeatures'}
              options={featuresOptions}
              multiple={true}
            />
          </div>
          <div className="immosearch-form__col">
            <FormInput label="Volltextsuche" placeholder="Volltextsuche" formType="searchForm" value={currentSearchForm.requestBody.fullText} id="inputFullText" />
          </div>
        </div>

        <div className="searchForm__buttons searchForm__buttons--expanded">
          <div className="searchForm__buttons--expanded__left">
            <ButtonForwardAction formType="searchForm" forwardAction={() => forwardAction(true, true)} buttonText={'Immobilie suchen'} />

            <span className="textlink text-white d-flex flex-col align-items-center pt-100rem pb-50rem mobile-hidden" onClick={handleExpanded}>
              Einfache Suche aufrufen
            </span>
          </div>

          <button className="button button--white-outline mb-0" onClick={() => clearSearch()}>
            Suche löschen
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div id="searchForm" className="searchForm">
        <FormInputMultiselect label="Ort, PLZ oder Objektnummer" placeholder="Ort, PLZ oder Objektnummer" formType="searchForm" value={currentSearchForm.address} id="inputAddress" />

        <FormSelect
          label={'Objektart'}
          placeholder="Objektart wählen"
          multiple={true}
          showBitteWaehlen={false}
          value={currentSearchForm.requestBody.estateType}
          formType={'searchForm'}
          id={'inputEstateType'}
          options={immobilienartOptionsSearchForm}
        />

        <div className="form__row form__row--double">
          <FormInput label="Fläche min" placeholder="Fläche min" formType={'searchForm'} value={currentSearchForm.requestBody.areaFrom} id="inputAreaFrom" type={'number'} minValue={0} />

          <FormInput label="Preis max" placeholder="Preis max" formType="searchForm" value={currentSearchForm.requestBody.priceTo} id="inputPriceTo" type={'number'} minValue={0} />
        </div>

        <div className="searchForm__buttons">
          <ButtonForwardAction formType="searchForm" forwardAction={() => forwardAction(true, true)} buttonText={'Immobilie suchen'} />

          <span className="textlink text-white w-100 d-flex flex-col align-items-center pt-100rem pb-50rem" onClick={handleExpanded}>
            Erweiterte Suche aufrufen
          </span>
        </div>
      </div>
    );
  }
}

export default SearchForm;
