import { useState, useEffect } from 'react';

export const useHandleCTAScroll = () => {
  const [opacity, setOpacity] = useState(1);
  const [display, setDisplay] = useState('block');

  const handleCTAScroll = () => {
    const footerElement = document.getElementsByClassName('sreal-footer')[0];
    if (footerElement) {
      const footerTop = footerElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (footerTop >= windowHeight + 150) {
        setOpacity(1);
        setDisplay('block');
      } else if (footerTop < windowHeight + 150 && footerTop > windowHeight) {
        if (display !== 'block') setDisplay('block');
        const diff = footerTop - windowHeight;
        const calculatedOpacity = diff / 150;
        setOpacity(calculatedOpacity);
      } else {
        setOpacity(0);
        setDisplay('none');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleCTAScroll);
    return () => {
      window.removeEventListener('scroll', handleCTAScroll);
    };
  }, []);

  const scrollToFinanzierung = () => {
    const el = document.querySelector('#finanzierung');

    if (el) {
      window.scrollTo({
        top: el.offsetTop - 20,
        behavior: 'smooth',
      });
    }
  };

  return { opacity, display, scrollToFinanzierung };
};
