function IconAlert({ color, cssClasses }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" style={{ transform: 'scale(0.8)' }}>
      <path
        id="Pfad_10544"
        data-name="Pfad 10544"
        d="M16,.008a3.43,3.43,0,0,0-3.053,1.866L.371,27.035v0a3.43,3.43,0,0,0,3.054,4.972H28.584a3.43,3.43,0,0,0,3.054-4.971v0L19.062,1.885l-.005-.01A3.43,3.43,0,0,0,16,.008Zm-.6,2.455a1.143,1.143,0,0,1,1.612.45L29.593,28.061l0,0a1.143,1.143,0,0,1-1.016,1.658H3.429a1.143,1.143,0,0,1-1.016-1.658l0,0L14.989,2.913A1.143,1.143,0,0,1,15.408,2.463Zm.6,7.822a1.143,1.143,0,0,1,1.143,1.143v6.86a1.143,1.143,0,0,1-2.287,0v-6.86A1.143,1.143,0,0,1,16,10.285ZM13.718,25.148A2.287,2.287,0,1,1,16,27.435,2.287,2.287,0,0,1,13.718,25.148Z"
        transform="translate(-0.004 -0.009)"
        fill="#e72222"
        fill-rule="evenodd"
      />
    </svg>
  );
}

export default IconAlert;
