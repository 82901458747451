function IconCheckmarkRoundOutlined({ color, cssClasses }) {
  return (
    <svg className={cssClasses} xmlns="http://www.w3.org/2000/svg" width="76.177" height="76.177" viewBox="0 0 76.177 76.177">
      <g id="Gruppe_16030" data-name="Gruppe 16030" transform="translate(0 -67)">
        <g id="Gruppe_16032" data-name="Gruppe 16032" transform="translate(0 67)">
          <path
            id="Pfad_10385"
            data-name="Pfad 10385"
            d="M52.24,12.394a2.8,2.8,0,0,0-4,1.077L29.123,47.388,16.956,37.338a2.816,2.816,0,0,0-4.171.538,3.03,3.03,0,0,0,.521,4.307L25.473,52.233a5.87,5.87,0,0,0,3.65,1.436,2.143,2.143,0,0,0,1.043-.179,6.1,6.1,0,0,0,4-2.871L53.283,16.7a3.443,3.443,0,0,0-1.043-4.307"
            transform="translate(5.298 4.688)"
            fill={color}
          />
          <path
            id="Pfad_10386"
            data-name="Pfad 10386"
            d="M38.088,0A38.088,38.088,0,1,0,76.177,38.088,38.2,38.2,0,0,0,38.088,0m0,70.781A32.693,32.693,0,1,1,70.781,38.088,32.85,32.85,0,0,1,38.088,70.781"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export default IconCheckmarkRoundOutlined;
