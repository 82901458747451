import httpClient from './index';

const END_POINT = '/Contact';

const send = async (requestBody, file) => {
  try {
    const formData = new FormData();
    Object.entries(requestBody).forEach(([key, value]) => {
      if (key === 'register') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    if (file) {
      formData.append('file', file, file.name);
    } else {
      formData.append('file', null);
    }

    const resp = await httpClient.post(END_POINT + '/send', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const createInquiry = async (requestBody) => {
  try {
    const resp = await httpClient.post(END_POINT + '/createInquiry', requestBody);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getMessageStats = async () => {
  try {
    const resp = await httpClient.get(END_POINT + '/getMessageStats');
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const getMessages = async (startToken) => {
  if (!startToken) {
    startToken = 'start';
  }
  try {
    const resp = await httpClient.get(END_POINT + '/getMessages?startToken=' + startToken);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getSentMessages = async (startToken) => {
  if (!startToken) {
    startToken = 'start';
  }
  try {
    const resp = await httpClient.get(END_POINT + '/getSentMessages?startToken=' + startToken);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const putReadMessage = async (id) => {
  try {
    const resp = await httpClient.put(END_POINT + '/putReadMessage?id=' + id);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const sendMessage = async (relatedLeadId = '', subject, body, replyToMessageId = '') => {
  try {
    const resp = await httpClient.post(END_POINT + '/sendMessage', {
      relatedLeadId: relatedLeadId,
      subject: subject,
      body: body,
      replyToMessageId: replyToMessageId,
    });
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const createSurveyEmpfehlung = async (requestBody) => {
  if (!requestBody) return false;

  try {
    const resp = await httpClient.post(END_POINT + '/createSurvey/Weiterempfehlung', requestBody);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const createSurveyZufriedenheit = async (requestBody) => {
  if (!requestBody) return false;
  try {
    const resp = await httpClient.post(END_POINT + '/createSurvey/Zufriedenheit', requestBody);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export { send, createInquiry, getMessageStats, getMessages, getSentMessages, putReadMessage, sendMessage, createSurveyEmpfehlung, createSurveyZufriedenheit };
