import React from 'react';
import IconArrowRight from '../Icons/IconArrowRight';
import DotUnread from '../DotUnread';
import '../../styles/linkitem.scss';

function Linkitem({ handleLink, headline, text, dateOrState, cssClasses = '', icon, imageSrc, imageAlt, unread = false, showArrow = true, ebene = '' }) {
  let arrowColor = '#2870ED';
  let iconColor = '#fff';
  let textColor = null;
  if (cssClasses && cssClasses.includes('linkitem--blue')) {
    arrowColor = '#ffffff';
    iconColor = '#3679ec';
  } else if (cssClasses && cssClasses.includes('linkitem--black')) {
    arrowColor = '#000000';
    textColor = '#000000';
  }

  let clonedIcon = '';
  if (icon) {
    clonedIcon = React.cloneElement(icon, { color: iconColor, active: true });
  }

  let linkitemContentCssClasses = '';
  if (dateOrState) {
    linkitemContentCssClasses = 'linkitem__content--smaller';
  }

  return (
    <div className={'linkitem ' + cssClasses + ' ' + ebene} onClick={handleLink}>
      <div className="linkitem__icon">
        {clonedIcon && clonedIcon}
        {imageSrc && <img src={imageSrc} alt={imageAlt} />}
      </div>

      <div className={`linkitem__content ${linkitemContentCssClasses}`}>
        <span className="linkitem__headline" style={{ color: textColor ?? null }}>
          {headline}
        </span>
        <span className="linkitem__text" style={{ color: textColor ?? null }}>
          {text}
        </span>
      </div>

      <div className="linkitem__right" style={{ minHeight: showArrow ? '4.5rem' : '' }}>
        <span className="linkitem__info">{dateOrState}</span>

        {showArrow && (
          <div className="linkitem__arrow-wrapper" style={{ minHeight: dateOrState ? '4rem' : '' }}>
            <div className="linkitem__arrow">
              <IconArrowRight width="1.5rem" color={arrowColor} cssClasses="linkitem__arrow" />
            </div>
          </div>
        )}

        <div className="linkitem__dotunread">{unread && <DotUnread unreadAmount={unread} />}</div>
      </div>
    </div>
  );
}

export default Linkitem;
