function IconArrowUp2({ color, cssClasses }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="8" viewBox="0 0 17 8">
      <g id="Polygon_9" data-name="Polygon 9" fill="#ccc">
        <path d="M 15.73921394348145 7.5 L 1.260786294937134 7.5 L 8.5 0.6866223812103271 L 15.73921394348145 7.5 Z" stroke="none" />
        <path d="M 8.5 1.373244762420654 L 2.521572113037109 7 L 14.47842788696289 7 L 8.5 1.373244762420654 M 8.5 0 L 17 8 L 0 8 L 8.5 0 Z" stroke="none" fill="#ccc" />
      </g>
    </svg>
  );
}

export default IconArrowUp2;
