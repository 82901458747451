import { useDispatch } from 'react-redux';
import IconThumbUpDown from '../Icons/IconThumbUpDown';
import Card from './Card';

import { setPageDialogOpenSatisfactionForm, setResetFieldsSatisfactionForm } from '../../reducers/satisfactionForm';

function SatisfactionCard({ cssClasses }) {
  const dispatch = useDispatch();

  const handleOpenSatisfactionForm = () => {
    dispatch(setResetFieldsSatisfactionForm());
    dispatch(setPageDialogOpenSatisfactionForm(true));
  };

  return (
    <>
      <Card cssClasses={cssClasses + ' background-pink text-white'} cardStyle={'customContent'}>
        <div className="card__content card__content--background">
          <div className="mb-10 mt-10">
            <IconThumbUpDown color="#fff" scale={2} />
          </div>
          <h4 className="mb-100rem text-white">Zufrieden mit s REAL?</h4>
          <p className="font-90">Wie war Ihre Erfahrung mit my-sreal at? Wir freuen uns über Ihr Feedback.</p>

          <div className="d-flex d-flex--btnandicon mt-auto">
            <div>
              <button className="button button--white-outline mt-100rem" onClick={handleOpenSatisfactionForm}>
                Feedback geben
              </button>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
export default SatisfactionCard;
