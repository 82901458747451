import InfoDialog from './InfoDialog';

function InfoDialogBerechnungsbasis({ handleClose, open }) {
  return (
    <InfoDialog handleClose={handleClose} headline="Berechnungsbasis" open={open}>
      <p className="mb-200rem">
        Diese Wertermittlung beruht auf Erfahrungswerten und Verkaufspreisen ähnlicher Immobilien in
        der Region, der ermittelte Wert basiert daher auf einer Durchschnittskalkulation und ersetzt
        keine Immobilienbewertung.{' '}
      </p>
      <p>
        Bei der konkreten Verkehrswertermittlung Ihrer Immobilien können sich auf Grund
        individueller Eigenschaften und Gegebenheiten der Immobilie deutliche Abweichungen ergeben.
      </p>
    </InfoDialog>
  );
}

export default InfoDialogBerechnungsbasis;
