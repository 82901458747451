function IconPlusCircle2({ color, cssClasses }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15919" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath>
          <rect data-name="Rechteck 4378" width="10" height="10" transform="translate(7 7)" fill={color} />
        </clipPath>
      </defs>
      <rect data-name="Rechteck 4377" width="23" height="23" rx="11.5" transform="translate(0.5 0.5)" fill="none" />
      <g data-name="Gruppe 13544" clip-path="url(#clip-path)">
        <path
          data-name="Pfad 8514"
          d="M12.667,7.693a.667.667,0,0,0-1.333,0v3.64H7.667a.667.667,0,0,0,0,1.333h3.667V16.36a.667.667,0,0,0,1.333,0V12.667h3.667a.667.667,0,0,0,0-1.333H12.667Z"
          fill={color}
          fill-rule="evenodd"
        />
      </g>
      <rect data-name="Rechteck 4379" width="23" height="23" rx="11.5" transform="translate(0.5 0.5)" fill="none" stroke="#e4eaf0" strokeWidth="1" />
    </svg>
  );
}

export default IconPlusCircle2;
