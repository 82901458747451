function IconSuccess({ color, cssClasses }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112.604"
      height="112.604"
      viewBox="0 0 112.604 112.604"
      className={cssClasses}
    >
      <path
        data-name="Pfad 8365"
        d="M67.3,11a56.3,56.3,0,1,0,56.3,56.3A56.356,56.356,0,0,0,67.3,11M98.848,56.041,62.72,95.635,35.894,68.818A4.324,4.324,0,0,1,42.009,62.7L62.434,83.1,92.447,50.2a4.332,4.332,0,0,1,6.4,5.838"
        transform="translate(-11 -11)"
        fill={color}
      />
    </svg>
  );
}

export default IconSuccess;
