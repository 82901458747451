function IconPerson({ cssClasses, color }) {
  return (
    <svg className={cssClasses} data-name="Gruppe 15894" xmlns="http://www.w3.org/2000/svg" width="81.128" height="85.01" viewBox="0 0 81.128 85.01">
      <defs>
        <clipPath>
          <rect data-name="Rechteck 5427" width="81.128" height="85.01" fill={color} />
        </clipPath>
      </defs>
      <g data-name="Gruppe 15894" clipPath="url(#clip-path)">
        <path
          data-name="Pfad 10347"
          d="M40.564,45.516A22.758,22.758,0,1,0,40.564,0a22.758,22.758,0,1,0,0,45.516m0-39.494A16.648,16.648,0,1,1,23.916,22.669,16.629,16.629,0,0,1,40.564,6.022m40.38,75.092A42.478,42.478,0,0,0,27.635,53.663,41.724,41.724,0,0,0,.184,81.114,2.825,2.825,0,0,0,.538,83.77a3.383,3.383,0,0,0,2.479,1.24H78.11a3.383,3.383,0,0,0,2.479-1.24,2.825,2.825,0,0,0,.354-2.657m-73.5-2.125A36.245,36.245,0,0,1,29.406,59.507,36.62,36.62,0,0,1,73.683,78.989Z"
          transform="translate(0)"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default IconPerson;
