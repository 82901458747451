import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { OBJECTREQUESTFORM_SITEINDEX_START, OBJECTREQUESTFORM_SITEINDEX_WIDERRUF, OBJECTREQUESTFORM_SITEINDEX_SUCCESS } from '../../constants';

import ObjectRequestMain from './ObjectRequest/ObjectRequestMain';
import ObjectRequestWiderruf from './ObjectRequest/ObjectRequestWiderruf';
import ObjectRequestSent from './ObjectRequest/ObjectRequestSent';

import { setSiteIndexObjectRequest, setCancellationNoticeConfirmedObjectRequest } from '../../reducers/objectRequest';
import { validateFields } from '../FormFields/util/validateFields';
import { fetchErrorText } from '../FormFields/util/formTexts';

// API
import { createInquiry } from '../../api/Contact';
import { setFavorite, getFavorites } from '../../api/JustImmo';

// STORE
import { setFormIsLoading } from '../../reducers/app';
import { setUserFavourites } from '../../reducers/user';
import { combineReduxPhoneParts } from '../../util/phoneNumberParser';
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';

function ObjectRequestForm({ handleClose }) {
  const objectRequest = useSelector((state) => state.objectRequest);
  const user = useSelector((state) => state.user);

	const { addToFavourites } = useHandleFavorite(objectRequest.immo);

  const [error, setError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setError('');
  }, [objectRequest.siteIndex]);
  
  const forwardAction = async () => {
    setError('');

    if (objectRequest.siteIndex === OBJECTREQUESTFORM_SITEINDEX_START) {
      let validationResult = validateFields(
        {
          gender: objectRequest.requestBody.gender,
          firstName: objectRequest.requestBody.firstname,
          lastName: objectRequest.requestBody.lastname,
          email: objectRequest.requestBody.email,
          phone: combineReduxPhoneParts(objectRequest.phoneNumberPrefix, objectRequest.parsedPhoneNumber),
        },
        dispatch,
      );

      if (validationResult) {
        dispatch(setSiteIndexObjectRequest(OBJECTREQUESTFORM_SITEINDEX_WIDERRUF));
      }
    } else if (objectRequest.siteIndex === OBJECTREQUESTFORM_SITEINDEX_WIDERRUF) {
      dispatch(setCancellationNoticeConfirmedObjectRequest(true));

      const newObjectRequest = {
        ...objectRequest.requestBody,
        cancellationNoticeConfirmed: true,
      };

      dispatch(setFormIsLoading(true));
      try {
        await createInquiry(newObjectRequest);
        dispatch(setFormIsLoading(false));

				let isAlreadyFav = false;
				if(objectRequest.immo && user.favourites.find((f) => f.id === (objectRequest.immo.id).toString())) {
					isAlreadyFav = true;
				}

        if (user.isLoggedIn && isAlreadyFav === false) {
          addToFavourites(objectRequest.immo);
        }

        dispatch(setSiteIndexObjectRequest(OBJECTREQUESTFORM_SITEINDEX_SUCCESS));
      } catch (e) {
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
      }
    }
  };

  return (
    <div className="objectRequestForm p-100rem">
      {objectRequest.siteIndex === OBJECTREQUESTFORM_SITEINDEX_START ? (
        <ObjectRequestMain forwardAction={forwardAction} error={error} />
      ) : objectRequest.siteIndex === OBJECTREQUESTFORM_SITEINDEX_WIDERRUF ? (
        <ObjectRequestWiderruf forwardAction={forwardAction} error={error} />
      ) : objectRequest.siteIndex === OBJECTREQUESTFORM_SITEINDEX_SUCCESS ? (
        <ObjectRequestSent handleClose={handleClose} />
      ) : null}
    </div>
  );
}

export default ObjectRequestForm;
