import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFavorite, getFavorites, getRealtyDetails, setFavorite } from '../../api/JustImmo';
import { fetchErrorText } from '../../components/FormFields/util/formTexts';
import { MENU_KAUF } from '../../constants';
import { setFormIsLoading } from '../../reducers/app';
import { setUserFavourites } from '../../reducers/user';
import { parseImmo } from '../../util/immoObjectParser';

export const useHandleFavorite = (immo) => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [favsLoading, setFavsLoading] = useState(true);
  const [deleteFavError, setDeleteFavError] = useState(false);
  const [hideUnfavDialog, setHideUnfavDialog] = useState(false);

  /**
   * add immo to favourites
   * @param {Object} immo
   * @returns
   */
  const addToFavourites = async (immo) => {
    if (app.formIsLoading) return;

    // id in favourites always as String
    const immoId = immo.id?.toString();

    let favFound = user.favourites.find((f) => f.realtyId?.toString() === immoId);

    if (!favFound) {
      try {
        dispatch(setFormIsLoading(true));
        await setFavorite(immo.id, immo.tenant_id);
        dispatch(setUserFavourites([...user.favourites, { ...immo, id: immoId }]));
        dispatch(setFormIsLoading(false));
      } catch (e) {
        dispatch(setFormIsLoading(false));
        // handle favorite error
        console.error('e', e);
      }
    }
  };

  /**
   * delete immo to favourites
   * @param {Object} immo
   */
  const unfavorite = async (immo) => {
    setDeleteFavError('');
    dispatch(setFormIsLoading(true));

    // id in favourites always as String
    const immoId = immo.id?.toString();

    try {
      const favorites = await getFavorites();
      const favouriteImmoObject = favorites.find((f) => f.realtyId?.toString() === immoId);

      if (favouriteImmoObject && favouriteImmoObject.id) {
        let id = favouriteImmoObject.id;

        try {
          await deleteFavorite(id);

          let newUserFavs = user.favourites.filter((f) => f.id !== immoId);
          dispatch(setUserFavourites(newUserFavs));
          dispatch(setFormIsLoading(false));
          setHideUnfavDialog(true);
        } catch (error) {
          dispatch(setFormIsLoading(false));
          setDeleteFavError(fetchErrorText);
        }
      } else {
        dispatch(setFormIsLoading(false));
        setDeleteFavError(fetchErrorText);
      }
    } catch (e) {
      dispatch(setFormIsLoading(false));
      setDeleteFavError(fetchErrorText);
      console.error('e', e);
    }
  };

  /**
   * load favorites and save to store (if not already saved)
   * @returns
   */
  const reloadFavsWithRealtyDetails = async () => {
    if (!user.isLoggedIn || app.menuType !== MENU_KAUF) return;

    setFavsLoading(true);
    try {
      const favorites = await getFavorites();

      const favoritePromisesToAdd = favorites
        .map((fav) => {
          let realtyId = fav.realtyId?.toString();
          let tenantId = fav.tenantId?.toString();
          if (realtyId && tenantId && user.favourites) {
            const userFavsIds = user.favourites.map((immo) => immo.id);
            const favFound = userFavsIds.includes(realtyId);
            if (!favFound) {
              return getRealtyDetails(realtyId, tenantId);
            }
          }
        })
        .filter((promise) => promise !== undefined);
      setFavsLoading(false);

      if (!favoritePromisesToAdd) return;

      const completePromises = await Promise.all(favoritePromisesToAdd.map((p) => p.catch((e) => e)));
      const validResults = completePromises.filter((result) => !(result instanceof Error)).map((immo) => parseImmo(immo));

      if (validResults) {
        let allFavs = [...user.favourites, ...validResults];

        // remove invalid immos and remove duplicates
        allFavs = allFavs
          .filter((f) => f.url !== undefined)
          .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id))
          .map((f) => {
            return { ...f, id: f.id.toString() };
          });

        dispatch(setUserFavourites(allFavs));
      }
    } catch (e) {
      console.log('e', e);
      setFavsLoading(false);
    }
  };

  return {
    addToFavourites,
    reloadFavsWithRealtyDetails,
    favsLoading,
    unfavorite,
    deleteFavError,
    hideUnfavDialog,
  };
};
